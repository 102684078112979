import axios from 'axios';
import Literals from '../../helpers/Literals'
import { authenticateRequest } from '../../helpers/utils';

export const fetchAvailableTime = () => async dispatch => {
  dispatch({ type: 'FETCH_AVAILABLE_TIME' });
  try {
    const { data } = await axios.get(`https://ghhis.herokuapp.com/api/v1/records/available_time/`);

    dispatch({ type: 'FETCH_AVAILABLE_TIME_SUCCESS', payload: data.results, next_page:data.next, count:data.count});
  } catch (e) {
    authenticateRequest(e);
    dispatch({ type: 'FETCH_AVAILABLE_TIME_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, error:null});
  }
};

export const fetchSpeciality = () => async dispatch => {
  dispatch({ type: 'FETCH_SPECIALITY', success:null, error:null });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}web/backend/config/?page=1&page_size=50`);

    dispatch({ type: 'FETCH_SPECIALITY_SUCCESS', payload: data.results, next_page:data.next, count:data.count});
  } catch (e) {
    authenticateRequest(e);
    dispatch({ type: 'FETCH_SPECIALITY_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, error:null});
  }
};


export const fetchLanguage = () => async dispatch => {
  dispatch({ type: 'FETCH_LANGUAGE', success:null, error:null });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}web/backend/custom_types/?page=1&page_size=50&category=language`);

    dispatch({ type: 'FETCH_LANGUAGE_SUCCESS', payload: data.results, next_page:data.next, count:data.count});
  } catch (e) {
    authenticateRequest(e);
    dispatch({ type: 'FETCH_LANGUAGE_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, error:null});
  }
};