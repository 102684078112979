import axios from 'axios';
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
  loading: false,
  facilities: [],
  error: null,
  success:null,
  create_success:null,
  create_error:null,
};

function facilities(state = INITIAL_STATE, action) {
  const { type, payload, next_page, count} = action;
  switch (type) {
    case 'FETCH_FACILITY':
      return { ...state, loading: true };
    case 'FETCH_FACILITY_SUCCESS':
      return { ...state, loading: false, facilities: payload, success:null, error:null, next_page:next_page, count:count};
    case 'FETCH_FACILITY_FAILURE':
      return { ...state, loading: false, error: payload, success:null};
    case 'CREATE_FACILITY_SUCCESS':
      return { ...state, loading: false, facilities: payload, create_success:true, create_error:null};
    case 'CREATE_FACILITY_FAILED':
        return { ...state, loading: false, create_error: payload, create_success:null};
    
    case 'DELETE_FACILITY_SUCCESS':
      return { ...state, loading: false, facility: payload, success:true};
    case 'DELETE_FACILITY_FAILED':
      return { ...state, loading: false, error: payload, success:null};
    case 'SEARCH_FACILITY_SUCCESS':
      return { ...state, loading: false, facilities: payload};
    case 'SEARCH_FACILITY_FAILED':
      return { ...state, loading: false, error: payload};
    case 'LOAD_MORE_FACILITY_SUCCESS':
        var facilities = state.facilities.concat(payload)
      return { ...state, loading: false, facilities: facilities, error: null, success: null, next_page:next_page};
    case 'LOAD_MORE_FACILITY_FAILURE':
      return { ...state, loading: false};

    default:
      return state;
  }
}

export const create_facility = data => async dispatch => {
  dispatch({ type: 'FETCH_FACILITY' });
  try {
     
    var facility_data = data
    
   
    if(facility_data.image){

      if (facility_data.image.files.length > 0){

        console.log('Processing image...')
      const formData = new FormData()
      formData.append('logo', facility_data.image.files[0], facility_data.image.files[0].name)
      formData.append('first_name', facility_data.first_name || '');
      formData.append('last_name', facility_data.last_name || '');
      formData.append('name', facility_data.name || '');
      formData.append('email', facility_data.email || '');
      formData.append('password', facility_data.password || '');
      formData.append('phone_number', facility_data.phone_number || '');
      
      var facility_data = formData

      }
      delete facility_data.image
    }
     
     await axios.post(`${Literals.BASE_API}users/backend/add_organization/`, facility_data);

     var facility = await axios.get(`${Literals.BASE_API}users/backend/organizations/?page=1&page_size=50`);

    dispatch({ type: 'CREATE_FACILITY_SUCCESS', payload: facility.data.results, create_success:true});
    document.getElementById("facility-form").reset();

    document.getElementById("myModal").style.display = "none";
  } catch (e) {
    console.log('error is ', e)
    dispatch({
      type: 'CREATE_FACILITY_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};




export const delete_facility = id => async dispatch => {
  try {
    let admin = await axios.delete(`${Literals.BASE_API}professional/web/${id}/`);
    dispatch({ type: 'DELETE_FACILITY_SUCCESS', payload: admin.data, success:true});
  } catch (e){
    dispatch({
      type:'DELETE_FACILITY_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};




export const search_keyword = data => async dispatch => {

  try {
    var keyword = data.keyword?data.keyword:'';
    let response = await axios.get(`${Literals.BASE_API}users/backend/organizations/?page=1&page_size=50&keyword=${keyword}`);
    document.getElementById("loader").classList.add("hide");
    dispatch({ type: 'SEARCH_FACILITY_SUCCESS', payload: response.data.results});
  } catch (e) {
    document.getElementById("loader").classList.add("hide");
    dispatch({
      type: 'SEARCH_FACILITY_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const loadMoreFacility = api => async dispatch => {
  dispatch({ type: 'FETCH_FACILITY' });
  try {
    const { data } = await axios.get(api);

    dispatch({ type: 'LOAD_MORE_FACILITY_SUCCESS', payload: data.results, next_page:data.next});
  } catch (e) {
    dispatch({ type: 'LOAD_MORE_FACILITY_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};

export default facilities;
