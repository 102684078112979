import React, {useEffect, useState, Fragment} from 'react';
import Menu from '../Menu/MenuComponent'
import Topnav from '../Menu/TopnavComponent'
import {Link} from 'react-router-dom'
import { formatDatetime } from '../helpers/utils';
// import Select from 'react-select'
import {connect} from 'react-redux'
import { serve_medication } from "./duck/visit_reducer";



function PatientProfileComponent({ getPatient, patient_profile = [], fetchVisits, visits = [], fetchPharmacy, pharmacy=[], error, success, loading, pharmacy_loading, pharmacy_success, pharmacy_error, serve_medication}) {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const admin = JSON.parse(window.localStorage.getItem('user'))


    useEffect(() => {
        getPatient(id);
        fetchVisits(id);
        fetchPharmacy(id)

    }, [getPatient, fetchVisits, fetchPharmacy]);

    
      //for diagnosis and medication table
  var diagnosis_table =false
  var medication_table =false
  var diagnosis_list =[]
  var medication_list =[]
  for(var key in visits){
    var treatments = visits[key].treatments
  for(var map in treatments){
    if(treatments[map].treatment_type =='diagnosis'){
        diagnosis_table =true
        diagnosis_list.push(treatments[map])

    }
    if(treatments[map].treatment_type =='medication'){
        medication_table =true
        medication_list.push(treatments[map])
    
    }
   
     }
}


    function formartText(string){
        return string.replace(/;/g,' ').replace(/\n/g,',  ');
    }
     
    
    
    function handleServe(id, index) {
        console.log('served')
        serve_medication(id, index);
      }

    const admin_access = JSON.parse(localStorage.getItem('permissions'))?JSON.parse(localStorage.getItem('permissions')):{permissions:null};
    if(admin_access.permissions){
        
        var pharmacy_write = admin_access.permissions.pharmacy?.write
        var pharmacy_read = admin_access.permissions.pharmacy?.read
        var patient_write = admin_access.permissions.patients?.write
        var lab_write = admin_access.permissions.lab?.write
        var lab_read = admin_access.permissions.lab?.read
    }else{
        
        var patient_write = true
        var pharmacy_write =true
        var lab_write =true
    }

   

  return (
    <div className="c-app c-legacy-theme c-no-layout-transition" >
    <Menu/>
    <div className="c-wrapper">
        <Topnav/>
        <div className="c-body">
            <main className="c-main">
                <div className="container-fluid">
                    <div className="fade-in">
                    
                <div className="row">
                    <div className="col-sm-7 col-6">
                        <h4 className="page-title">Patient Profile</h4>
                        {error &&
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={handleClose}>
									<span aria-hidden="true">×</span>
								</button> */}
								<a className="text-center">{error}</a>
						    </div>
                        }
                        {success &&
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close" >
									<span aria-hidden="true">×</span>
								</button> */}
								<a className="text-center">{success}</a>
						    </div>
                        }

                    </div>
                   
                </div>
                <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile-view">
                                <div className="profile-img-wrap">
                                    <div className="profile-img">
                                    {!patient_profile.avatar &&
                                     <img className="avatar" src="assets/images/user.jpg" alt=""/>
                                    }
                                    {patient_profile.avatar &&
                                     <img className="avatar" src={patient_profile.avatar} alt=""/>
                                    }
                                    </div>
                                </div>
                                <div className="profile-basic">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="profile-info-left">
                                                <h3 className="user-name m-t-0 mb-0">{patient_profile.first_name} {patient_profile.last_name}</h3>
                                                <small className="text-muted">&nbsp;</small>
                                                <div class="staff-id">ID number: {patient_profile.id}&nbsp;</div>
                                                <div className="staff-id">username: {patient_profile.username}&nbsp;</div>
                                                <div className="staff-id">National ID number: {patient_profile.national_id_number}&nbsp;</div>
                                                <div className="staff-id">&nbsp;</div>
                                                <div className="staff-msg">&nbsp;</div>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <ul className="personal-info">
                                                <li>
                                                    <span className="title">Phone number:</span>
                                                    <span className="text"><a href="#">{patient_profile.phone_number}&nbsp;&nbsp;</a></span>
                                                </li>
                                                <li>
                                                    <span className="title">Whatsapp number:</span>
                                                    <span className="text"><a href="#">{patient_profile.phone_number_2}&nbsp;&nbsp;</a></span>
                                                </li>
                                                <li>
                                                    <span className="title">Email:</span>
                                                    <span className="text"><a href="#">{patient_profile.email}&nbsp;&nbsp;</a></span>
                                                </li>
                                                <li>
                                                    <span className="title">Birthday:</span>
                                                    <span className="text">{patient_profile.date_of_birth}&nbsp;&nbsp;</span>
                                                </li>
                                                <li>
                                                    <span className="title">Gender:</span>
                                                    <span className="text">{patient_profile.gender}&nbsp;&nbsp;</span>
                                                </li>
                                                {/* <li>
                                                    <span className="title">Practice number:</span>
                                                    <span className="text">{doctor_profile.practice_number}&nbsp;&nbsp;</span>
                                                </li> */}
                                                <li>
                                                    <span className="title">languages:</span>
                                                    <span className="text">{patient_profile.area}&nbsp;&nbsp;</span>
                                                </li>
                                                {/* <li>
                                                    <span className="title">Region:</span>
                                                    <span className="text">{doctor_profile.region}&nbsp;&nbsp;</span>
                                                </li> */}
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>
				<div className="profile-tabs">
					<ul className="nav nav-tabs nav-tabs-bottom">
                        {patient_write && <>
						<li className="nav-item"><a className="nav-link active" href="#about-cont" data-toggle="tab">Medical Bio</a></li>
                        <li className="nav-item"><a className="nav-link" href="#bottom-tab2" data-toggle="tab">Current Medication</a></li>
						<li className="nav-item"><a className="nav-link" href="#bottom-tab3" data-toggle="tab">Facility Visits</a></li>
                        <li className="nav-item"><a className="nav-link" href="#bottom-tab4" data-toggle="tab">Contact Persons</a></li>
                        <li className="nav-item"><a className="nav-link" href="#bottom-tab5" data-toggle="tab">Uploads</a></li></>}
                        <li className="nav-item"><a className={`nav-link ${pharmacy_read && (patient_write != true)?'active':''}`} href="#bottom-tab6" data-toggle="tab">Prescription</a></li>
                        <li className="nav-item"><a className={`nav-link ${lab_read && (patient_write != true) && (pharmacy_read != true)?'active':''}`} href="#bottom-tab7" data-toggle="tab">Lab</a></li>
						
					</ul>

					<div className="tab-content">
						<div className={`tab-pane ${patient_write === true?'active':''}`} id="about-cont">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card-box">
                            <h4 className="card-title">&nbsp;&nbsp;&nbsp;&nbsp;<div className="pull-right" style={{marginRight:"475px"}}>Insurance Card Number: {patient_profile.insurance_card_number}</div></h4>
                            <div className="experience-box">
                                <div className="row">
                                    <div className="col-md-4">
                                        <ul className="experience-list">
                                            <li>
                                                <div className="experience-user">
                                                    <div className="before-circle"></div>
                                                </div>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <a href="#/" className="name">Blood type</a>
                                                        <div>{patient_profile.blood_type}</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-user">
                                                    <div className="before-circle"></div>
                                                </div>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <a href="#/" className="name">Allergies</a>
                                                        <div>{patient_profile.allergies &&
                                                            <div>
                                                                {patient_profile.allergies.map(allergies => (
                                                                    <div>{allergies}</div>
                                                                ))}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-user">
                                                    <div className="before-circle"></div>
                                                </div>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <a href="#/" className="name">Family illness</a>
                                                        <div>{patient_profile.family_illness &&
                                                            <div>
                                                                {patient_profile.family_illness.map(family_illness => (
                                                                    <div key={family_illness}>{family_illness}</div>
                                                                ))}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                        <div className="col-md-6">
                                        {patient_profile.insurance_card_front &&
                                            <a className="chat-img-attach" >
                                                <img width="282" height="237" alt="" src={patient_profile.insurance_card_front}/>
                                                <div className="chat-placeholder">
                                                    <div className="chat-img-name">Front</div>
                                                </div>
                                            </a>
                                        }
                                        {!patient_profile.insurance_card_front &&
                                            <a className="chat-img-attach" >
                                                <img width="282" height="237" alt="" src="assets/images/placeholder.jpg"/>
                                                <div className="chat-placeholder">
                                                    <div className="chat-img-name">Front</div>
                                                </div>
                                            </a>
                                        }
                                        </div>
                                        <div className="col-md-6">
                                        {patient_profile.insurance_card_back &&
                                            <a className="chat-img-attach" >
                                                <img width="282" height="237" alt="" src={patient_profile.insurance_card_back}/>
                                                <div className="chat-placeholder">
                                                    <div className="chat-img-name">Back</div>
                                                </div>
                                            </a>
                                        }
                                        {!patient_profile.insurance_card_back &&
                                            <a className="chat-img-attach" >
                                                <img width="282" height="237" alt="" src="assets/images/placeholder.jpg"/>
                                                <div className="chat-placeholder">
                                                    <div className="chat-img-name">Back</div>
                                                </div>
                                            </a>
                                        }
                                        </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
						</div>
						<div className="tab-pane" id="bottom-tab2">
                        <div className="card-box mb-0">
                            <h3 className="card-title">&nbsp;&nbsp;</h3>
                            <div className="experience-box">
                                <ul className="experience-list">
                                    <li>
                                        <div className="experience-user">
                                            <div className="before-circle"></div>
                                        </div>
                                        <div className="experience-content">
                                            <div className="timeline-content">
                                                <a href="#/" className="name">Current illness</a>
                                                    <div>{patient_profile.current_illness}</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="experience-user">
                                            <div className="before-circle"></div>
                                        </div>
                                        <div className="experience-content">
                                            <div className="timeline-content">
                                                <a href="#/" className="name">Current medication</a>
                                                <div>{patient_profile.current_medication}</div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
						</div>
						<div className="tab-pane" id="bottom-tab3">
                        <div className="card-box mb-0">
                            <h3 className="card-title"></h3>
                            <div className="experience-box">
                                <ul className="experience-list">
                                    <li>
                                        <div className="experience-user">
                                            <div className="before-circle"></div>
                                        </div>
                                        <div className="experience-content">
                                            <div className="timeline-content">
                                                <a href="#/" className="name">Visits: </a>
                                                <div className="table-responsive">
                                                    <table className="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Doctor</th>
                                                                <th>Temperature</th>
                                                                <th>Blood pressure</th>  
                                                                <th>Details</th> 
                                                                <th className="text-right">Action</th> 
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {visits.map(visit => (
                                                                <tr key={visit.id}>
                                                                    <td>{formatDatetime(visit.visit_date)}</td>
                                                                    <td> 
                                                                        <Link to={`/doctor_profile?id=${visit.professional.id}`}>
                                                                            {visit.professional.avatar &&
                                                                                <img width="28" height="28" src={visit.professional.avatar} className="rounded-circle m-r-5" alt="" />
                                                                            }
                                                                            {!visit.professional.avatar &&
                                                                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt="" />
                                                                            }
                                                                            {visit.professional.title} {visit.professional.first_name} {visit.professional.last_name}
                                                                        </Link>
                                                                    </td>
                                                                    <td>{visit.temperature}{visit.temperature_unit}</td>
                                                                    <td>{visit.blood_pressure}</td>
                                                                    <td>
                                                                        <div className="dropdown action-label">
                                                                            <a className="custom-badge status-green" href="#" data-toggle="dropdown" data-toggle="modal" data-target={`#view_treatment${visit.id}`}>
                                                                                Visit Details
                                                                            </a>
                                                                        </div>
                                                                        <div className="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id={`view_treatment${visit.id}`}>
                                                                            <div className="modal-dialog modal-lg">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                            <span aria-hidden="true">&times;</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    {visit.treatments && 
                                                                                        <div className="table-responsive">
                                                                                            {diagnosis_table &&
                                                                                            <table className="table mb-0">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>Diagnosis</th>  
                                                                                                        <th>Temprature</th>  
                                                                                                        <th>Blood Pressure</th> 
                                                                                                        <th>Scans</th>
                                                                                                         {/* <th>Test results</th> */}
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                {visit.treatments.map(treatment => (
                                                                                                    <Fragment>
                                                                                                        {treatment.treatment_type == 'diagnosis' &&
                                                                                                    <tr key={treatment.id}>
                                                                                                        <td>{treatment.details[0]}</td>
                                                                                                        <td>{visit.temperature}{visit.temperature_unit}</td>
                                                                                                        <td>{visit.blood_pressure}</td>
                                                                                                       <td>
                                                                                                            {treatment.scans &&
                                                                                                                <div>
                                                                                                                    {treatment.scans.map(scan => (
                                                                                                                   
                                                                                                                        <img src={scan.thumbnail} className="img-thumbnail img-fluid" alt=""/>
                                                                                                                    ))}
                                                                                                                </div>
                                                                                                            }
                                                                                                        </td>
                                                                                                        {/*  <td>
                                                                                                            {treatment.test_results &&
                                                                                                                <div>
                                                                                                                    {treatment.test_results.map(test_result => (
                                                                                                                        <img src={test_result.thumbnail} className="img-thumbnail img-fluid" alt=""/>
                                                                                                                    ))}
                                                                                                                </div>
                                                                                                            }
                                                                                                        </td> */}
                                                                                                    </tr>}
                                                                                                    </Fragment>
                                                                                                ))} 
                                                                                                </tbody>      
                                                                                            </table>
                                                                                            }
                                                                                            {medication_table &&
                                                                                            <Fragment>
                                                                                            <table className="table mb-0">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>Prescription</th>  
                                                                                                        
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                {visit.treatments.map(treatment => (
                                                                                                    <Fragment>
                                                                                                    {treatment.treatment_type == 'medication' &&
                                                                                                    <tr key={treatment.id}>
                                                                                                        <td>{formartText(treatment.details[0])}</td>
                                                                                                        
                                                                                                        
                                                                                                    </tr>
                                                                                                    }
                                                                                                    
                                                                                                </Fragment>
                                                                                                ))} 
                                                                                                </tbody>      
                                                                                            </table>

                                                                                            <table className="table mb-0">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>Remarks</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>{visit.remarks}</td>
                                                                                                    </tr>
                                                                                                </tbody>      
                                                                                            </table>

                                                                                            </Fragment>
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-right">
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <br/>
                                                    <br/><br/>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
						</div>
                        <div className="tab-pane" id="bottom-tab4">
                            <div className="card-box mb-0">
                                <h3 className="card-title">&nbsp;&nbsp;</h3>
                                <div className="experience-box">
                                    <ul className="experience-list">
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="#/" className="name">Emergency contacts: </a>
                                                    <div className="table-responsive">
                                                        <table className="table mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Firstname</th>
                                                                    <th>Lastname</th> 
                                                                    <th>Phone number</th>
                                                                    <th>Email</th>  
                                                                    <th>Gender</th>  
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {patient_profile.emergency_contact &&
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td>{patient_profile.emergency_contact.first_name}</td>
                                                                        <td>{patient_profile.emergency_contact.last_name}</td>
                                                                        <td>{patient_profile.emergency_contact.phone_number}</td>
                                                                        <td>{patient_profile.emergency_contact.email}</td>
                                                                        <td>{patient_profile.emergency_contact.gender}</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
						</div>
                        <div className="tab-pane" id="bottom-tab5">
                            <div className="card-box mb-0">
                                <h3 className="card-title">&nbsp;&nbsp;</h3>
                                <div className="experience-box">
                                    <div class="row">
                                        {patient_profile.uploaded_files && 
                                            <Fragment>
                                                {patient_profile.uploaded_files.length > 0 &&
                                                <Fragment>
                                                {patient_profile.uploaded_files.map(uploaded_file => (
                                                <div class="col-sm-6 col-md-6 col-lg-4">
                                                    <div class="blog grid-blog">
                                                        <div class="blog-image">
                                                            <a ><img class="img-fluid" src={uploaded_file.url} alt=""/></a>
                                                        </div>
                                                    </div>
                                                </div>))}
                                                </Fragment>
                                                }
                                            </Fragment>
                                        }
                                        {patient_profile.uploaded_files && 
                                            <Fragment>
                                            {patient_profile.uploaded_files.length == 0 &&
                                                
                                                <div class="col-sm-6 col-md-6 col-lg-4">
                                                    <div class="blog grid-blog">
                                                        <div class="blog-image">
                                                        <a><img class="img-fluid" src="assets/img/placeholder.jpg" alt=""/></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                           </Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
						</div>
                        <div className={`tab-pane ${pharmacy_read && (patient_write != true)?'active':''}`} id="bottom-tab6">
                            <div className="card-box mb-0">
                            <h3 className="card-title"></h3>
                            <div className="experience-box">
                                <ul className="experience-list">
                                    <li>
                                        <div className="experience-user">
                                            <div className="before-circle"></div>
                                        </div>
                                        <div className="experience-content">
                                            <div className="timeline-content">
                                                <a href="#/" className="name">Prescription: </a>
                                                <div className="table-responsive">
                                                    <table className="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Prescription ID</th> 
                                                                <th>Prescription Served By</th> 
                                                                <th>Last Prescription Served on</th> 
                                                                {pharmacy_write &&
                                                                    <th >Action</th> 
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {pharmacy?.map(visit => (
                                                                <>
                                                                {visit.medication.map((treatment, index)=> (
                                                                    <>
                                                                    {treatment.treatment_type === 'medication' && treatment.details[0] !== '' && 
                                                                <tr key={treatment.id}>
                                                                    <td>{formatDatetime(visit.visit_date)}</td>
                                                                    <td>
                                                                        {visit.medication.map(treatment => (
                                                                            <Fragment>
                                                                            {treatment.treatment_type == 'medication' &&
                                                                            <ul key={treatment.id}>
                                                                                 <li><Link to={`/search/${treatment.id}/medication`}> {treatment.id}</Link></li>
                                                                                {/* <li>{formartText(treatment.details[0])}</li> */}
                                                                                
                                                                            </ul>
                                                                            }
                                                                        </Fragment>
                                                                        ))} 
                                                                    </td>
                                                                    
                                                                    <td> 
                                                                        {visit.medication_served_by &&
                                                                        <div >
                                                                            {visit.medication_served_by.avatar &&
                                                                                <img width="28" height="28" src={visit.medication_served_by.avatar} className="rounded-circle m-r-5" alt="" />
                                                                            }
                                                                            {!visit.medication_served_by.avatar &&
                                                                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt="" />
                                                                            }
                                                                            {visit.medication_served_by.title} {visit.medication_served_by.first_name} {visit.medication_served_by.last_name}
                                                                        </div>
                                                                        }
                                                                    </td>
                                                                    <td>{formatDatetime(visit.last_medication_served_on)}</td>
                                                                    <td >
                                                                        {visit.medication_served?<button className="btn btn-sm btn-pill btn-success" type="button">Served</button>
                                                                            :(pharmacy_write?<button data-toggle="modal" data-target={`#pharmacy${visit.id}`} className="btn btn-sm btn-primary" type="button">Mark as served</button>:<button className="btn btn-sm btn-pill btn-danger" type="button">Not Served</button>)
                                                                        }
                                                                    {pharmacy_write &&
                                                                    <div id={`pharmacy${visit.id}`} className="modal fade delete-modal" role="dialog">
                                                                        <div className="modal-dialog modal-dialog-centered">
                                                                            <div className="modal-content">
                                                                                <div className="modal-body text-center">
                                                                                
                                                                                    Are you sure you want mark as served?
                                                                                    <br/>
                                                                                    <br/>
                                                                                    {pharmacy_success &&
                                                                                        <div class="alert alert-success" role="alert">Successfully Served.</div>
                                                                                    }
                                                                                    {pharmacy_error &&
                                                                                        <div class="alert alert-danger" role="alert">Error serving. Try again</div>
                                                                                    }
                                                                                    <br/>
                                                                                    <div className="m-t-20">
                                                                                        <a href="#" className="btn btn-white" data-dismiss="modal">
                                                                                            Close
                                                                                        </a>
                                                                                        <button type="submit" className="btn btn-danger" onClick={() => handleServe(visit.id, index)} disabled={pharmacy_loading || pharmacy_success}>
                                                                                            {pharmacy_loading?
                                                                                                <Fragment><i className="fa fa-spinner fa-pulse"></i> loading...</Fragment>:<Fragment>Mark as served</Fragment>}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    }

                                                                    </td>
                                                                </tr>
                                                                }
                                                                </>
                                                                ))}
                                                                </>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <br/>
                                                    <br/><br/>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
						</div>
                        <div className={`tab-pane ${lab_read && (patient_write != true) && (pharmacy_read != true)?'active':''}`} id="bottom-tab7">
                            <div className="card-box mb-0">
                            <h3 className="card-title"></h3>
                            <div className="experience-box">
                                <ul className="experience-list">
                                    <li>
                                        <div className="experience-user">
                                            <div className="before-circle"></div>
                                        </div>
                                        <div className="experience-content">
                                            <div className="timeline-content">
                                                <a href="#/" className="name">Lab: </a>
                                                <div className="table-responsive">
                                                    <table className="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Lab ID</th> 
                                                                <th>Lab Served By</th> 
                                                                <th>Last Lab Served on</th> 
                                                                {lab_write &&
                                                                    <th >Action</th> 
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {pharmacy?.map(visit => (
                                                                <>
                                                                {visit.medication.map((treatment, index)=> (
                                                                    <>
                                                                    {treatment.treatment_type === 'medication' && treatment.details[0] !== '' && 
                                                                <tr key={treatment.id}>
                                                                    <td>{formatDatetime(visit.visit_date)}</td>
                                                                    <td>
                                                                        <ul key={treatment.id} >

                                                                            <li><Link to={`/search/${visit.id}/lab`}> {visit.id}</Link></li>

                                                                            {/* {visit.lab_request.map(treatment => (
                                                                                
                                                                                <li>{formartText(treatment)}</li>
                                                                                
                                                                            ))}  */}
                                                                        </ul>
                                                                    </td>
                                                                    
                                                                    <td> 
                                                                        {visit.medication_served_by &&
                                                                        <div >
                                                                            {visit.medication_served_by.avatar &&
                                                                                <img width="28" height="28" src={visit.medication_served_by.avatar} className="rounded-circle m-r-5" alt="" />
                                                                            }
                                                                            {!visit.medication_served_by.avatar &&
                                                                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt="" />
                                                                            }
                                                                            {visit.medication_served_by.title} {visit.medication_served_by.first_name} {visit.medication_served_by.last_name}
                                                                        </div>
                                                                        }
                                                                    </td>
                                                                    <td>{formatDatetime(visit.last_medication_served_on)}</td>
                                                                    <td >
                                                                        {visit.medication_served?<button className="btn btn-sm btn-pill btn-success" type="button">Served</button>
                                                                            :(pharmacy_write?<button data-toggle="modal" data-target={`#lab${visit.id}`} className="btn btn-sm btn-primary" type="button">Mark as served</button>:<button className="btn btn-sm btn-pill btn-danger" type="button">Not Served</button>)
                                                                        }
                                                                    {pharmacy_write &&
                                                                    <div id={`lab${visit.id}`} className="modal fade delete-modal" role="dialog">
                                                                        <div className="modal-dialog modal-dialog-centered">
                                                                            <div className="modal-content">
                                                                                <div className="modal-body text-center">
                                                                                
                                                                                    Are you sure you want mark as served?
                                                                                    <br/>
                                                                                    <br/>
                                                                                    {pharmacy_success &&
                                                                                        <div class="alert alert-success" role="alert">Successfully Served.</div>
                                                                                    }
                                                                                    {pharmacy_error &&
                                                                                        <div class="alert alert-danger" role="alert">Error serving. Try again</div>
                                                                                    }
                                                                                    <br/>
                                                                                    <div className="m-t-20">
                                                                                        <a href="#" className="btn btn-white" data-dismiss="modal">
                                                                                            Close
                                                                                        </a>
                                                                                        <button type="submit" className="btn btn-danger" onClick={() => handleServe(visit.id, index)} disabled={pharmacy_loading || pharmacy_success}>
                                                                                            {pharmacy_loading?
                                                                                                <Fragment><i className="fa fa-spinner fa-pulse"></i> loading...</Fragment>:<Fragment>Mark as served</Fragment>}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    }

                                                                    </td>
                                                                </tr>
                                                                }
                                                                </>
                                                                ))}
                                                                </>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <br/>
                                                    <br/><br/>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
						</div>
					</div>
				</div>
            </div>
        </div>
        </div>
        </main>
    </div>
    </div>
    </div>
  );
}

function mapStateToProps({patient_profile, visits}){
    return {error:patient_profile.error, success:patient_profile.success, loading:patient_profile.loading,
    pharmacy:visits.pharmacy, pharmacy_loading:visits.loading, pharmacy_success:visits.success, pharmacy_error:visits.error, 
    };
    
  }
export default connect(mapStateToProps, {serve_medication})(PatientProfileComponent);


