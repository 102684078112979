
import axios from 'axios'
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
    loading: false,
    specialities: [],
    error: null,
  };
  
  function specialities(state = INITIAL_STATE, action) {
    const { type, payload, next_page, count} = action;
      switch (type) {
        case 'FETCH_SPECIALITY':
          return { ...state, loading: true };
        case 'FETCH_SPECIALITY_SUCCESS':
            return { ...state, loading: false, specialities: payload, success:null, error:null, next_page:next_page, count:count};
        case 'FETCH_SPECIALITY_FAILED':
          return { ...state, loading: false, error: payload };
        case 'CREATE_SPECIALITY_SUCCESS':
            return { ...state, loading: false, specialities: payload, create_success:true, create_error:null};
        case 'CREATE_SPECIALITY_FAILED':
            return { ...state, loading: false, create_error: payload, create_success:null};
        case 'UPDATE_SPECIALITY_SUCCESS':
              return { ...state, loading: false, specialities: payload, create_success:true, create_error:null};
        case 'UPDATE_SPECIALITY_FAILED':
            return { ...state, loading: false, create_error: payload, create_success:null};
        case 'SEARCH_SPECIALITY_SUCCESS':
          return { ...state, loading: false, specialities: payload};
        case 'SEARCH_SPECIALITY_FAILED':
          return { ...state, loading: false, error: payload};

        case 'DELETE_SPECIALITY_SUCCESS':
            return { ...state, loading: false, specialities: payload, create_success:true, create_error:null};
        case 'DELETE_SPECIALITY_FAILED':
                return { ...state, loading: false, create_error: payload, create_success:null};
        case 'LOAD_MORE_SPECIALITY_SUCCESS':
            var specialities = state.specialities.concat(payload)
            return { ...state, loading: false, specialities: specialities, error: null, success: null, next_page:next_page};
        case 'LOAD_MORE_SPECIALITY_FAILED':
            return { ...state, loading: false};
        default:
          return state;
      }
    }

    export const loadMoreSpeciality = api => async dispatch => {
        dispatch({ type: 'FETCH_SPECIALITY' });
        try {
          const { data } = await axios.get(api);
      
          dispatch({ type: 'LOAD_MORE_SPECIALITY_SUCCESS', payload: data.results, next_page:data.next});
        } catch (e) {
          dispatch({ type: 'LOAD_MORE_SPECIALITY_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
        }
      };


      export const create_speciality = data => async dispatch => {
        dispatch({ type: 'FETCH_SPECIALITY' });
        try {
           await axios.post(`${Literals.BASE_API}web/backend/config/`, data);
      
           var specialities = await axios.get(`${Literals.BASE_API}web/backend/config/?page=1&page_size=50`);
      
          dispatch({ type: 'CREATE_SPECIALITY_SUCCESS', payload: specialities.data.results, create_success:true});
          document.getElementById("speciality-form").reset();
      
          document.getElementById("add_speciality").style.display = "none";
        } catch (e) {
          console.log('error is ', e)
          dispatch({
            type: 'CREATE_SPECIALITY_FAILED',
            payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
          });
        }
      };

      export const update_speciality = data => async dispatch => {
        dispatch({ type: 'FETCH_SPECIALITY' });
        try {
           await axios.put(`${Literals.BASE_API}web/backend/${data.id}/config/`, data);
      
           var specialities = await axios.get(`${Literals.BASE_API}web/backend/config/?page=1&page_size=50`);
      
          dispatch({ type: 'UPDATE_SPECIALITY_SUCCESS', payload: specialities.data.results, create_success:true});
      
          // document.getElementById("update_speciality").style.display = "none";
        } catch (e) {
          console.log('error is ', e)
          dispatch({
            type: 'UPDATE_SPECIALITY_FAILED',
            payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
          });
        }
      };

      export const delete_speciality = id => async dispatch => {
        dispatch({ type: 'FETCH_SPECIALITY' });
        try {
           await axios.delete(`${Literals.BASE_API}web/backend/${id}/config/`);
      
           var specialities = await axios.get(`${Literals.BASE_API}web/backend/config/?page=1&page_size=50`);
      
          dispatch({ type: 'DELETE_SPECIALITY_SUCCESS', payload: specialities.data.results, create_success:true});
          
        } catch (e) {
          console.log('error is ', e)
          dispatch({
            type: 'DELETE_SPECIALITY_FAILED',
            payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
          });
        }
      };


      export const search_keyword = data => async dispatch => {

        try {
          var keyword = data.keyword?data.keyword:'';
          let response = await axios.get(`${Literals.BASE_API}web/backend/config/?page=1&page_size=100&keyword=${keyword}`);
          document.getElementById("loader").classList.add("hide");
          dispatch({ type: 'SEARCH_SPECIALITY_SUCCESS', payload: response.data.results});
        } catch (e) {
          document.getElementById("loader").classList.add("hide");
          dispatch({
            type: 'SEARCH_SPECIALITY_FAILED',
            payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
          });
        }
      };
      
  
    export default specialities;
  