import React, {useEffect, useState, Fragment} from 'react';
import Menu from '../Menu/MenuComponent';
import Topnav from '../Menu/TopnavComponent';
import {Link} from 'react-router-dom';
import {verifyProfessional, search_keyword,loadMoreDoctors, loadPageDoctors, subscribe_professional} from './duck/reducer';
import {verifyWebProfessional, search_web_keyword,loadMoreWebDoctors, loadPageWebDoctors} from './duck/web_doctors_reducer';
import { loadMoreSubscribedDoctors } from "./duck/subscribed_reducer";
import {connect} from 'react-redux';
import {tableThemes} from '../helpers/utils';
import TopNavLoader from '../helpers/utils';
import Literals from '../helpers/Literals'




function DoctorsComponent({ fetchDoctors, fetchWebDoctors, doctors = [], web_doctors = [], verifyProfessional, verifyWebProfessional, search_keyword, search_web_keyword, error, success, next_page, prev_page, loading, count, loadMoreDoctors, loadMoreWebDoctors, web_error, web_success, web_next_page, web_loading, loadPageDoctors, loadPageWebDoctors, web_count, web_prev_page, 
  fetchSubscribedDoctors, subscribed_doctors=[], subscribed_error, subscribed_success, subscribed_next_page, subscribed_loading, loadMoreSubscribedDoctors, subscribe_professional}) {
    useEffect(() => {
          fetchDoctors(); fetchWebDoctors(); fetchSubscribedDoctors();
      }, [fetchDoctors, fetchWebDoctors, fetchSubscribedDoctors]);

      const [state, setState] = useState({});
      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
	  }

    function handleSearch(e) {
      e.preventDefault();
          document.getElementById("loader").classList.remove("hide");
          if(tabState.tab=='app'){
            search_keyword(state);
          }else{
            search_web_keyword(state)
          }
      } 
      
      function handleClearField(e){
        e.preventDefault();
        document.getElementById("keyword").value='';
        state.keyword = ''
        document.getElementById("loader").classList.remove("hide");
        if(tabState.tab=='app'){
          search_keyword(state);
        }else{
          search_web_keyword(state)
        }
        }


      function verify(id, index){
       
        var status = document.getElementById("verify"+ id +"").checked;
        document.getElementById("loader"+ id +"").classList.remove("hide");
        
        if(tabState.tab=='app'){
          verifyProfessional({"is_verified":status, "id":id, "index":index})
        }else{
          verifyWebProfessional({"is_verified":status, "id":id, "index":index})
        }
        

      }


      const [tabState, setTabState] = useState({tab:'app'});
      function handleTabChange(tab) {
        setTabState({ tab:tab });
	  }
    
   
    if(tabState.tab != 'app'){

      var next_page = web_next_page
      var count = web_count
      var prev_page = web_prev_page
      var loading = web_loading

    }

    const urlParams = new URLSearchParams(next_page);
    var paginateCount = Math.round(count/50)
    var page_number = parseInt(urlParams.get('page'))?parseInt(urlParams.get('page')) -2:paginateCount-1;

    var tableData = JSON.parse(localStorage.getItem('tableDataThemes')) || tableThemes.scroll
    
      function nextPage(next_page){
      if(tabState.tab=='app'){
        if(next_page && !loading){
          
          loadPageDoctors(next_page+'&organization=false');
        }
      }else{
        if(web_next_page && !web_loading){
          loadPageWebDoctors(web_next_page+'&organization=true');
        }
      }
    }

    
    if(tableData.text === tableThemes.scroll.text){
    //infinite scroll
    var doctors_page = true
      window.onscroll = () => {
        // Checks that the page has scrolled to the bottom
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
              
              if(tabState.tab=='app'){
                if(next_page && !loading && doctors_page){
                  loadMoreDoctors(next_page+'&organization=false');
                }
              }else if (tabState.tab=='web'){
                if(web_next_page && !web_loading && doctors_page){
                  loadMoreWebDoctors(web_next_page+'&organization=true');
                }
              }else{
                if(subscribed_next_page && !subscribed_loading && doctors_page){
                loadMoreSubscribedDoctors(subscribed_next_page+'&organization=false')
                }
              }
                
            }
      };
    }

    function handleSubmit(id, index) {

         
      subscribe_professional(id, index)

    } 
    
  
  return (
    <div className="c-app c-legacy-theme c-no-layout-transition" >
    <Menu/>
    <div className="c-wrapper">
        <Topnav/>
        <TopNavLoader loading={loading}/>
        <div className="c-body">
            <main className="c-main">
                <div className="container-fluid">
                    <div className="fade-in">
          <div className="row">
              <div className="col-sm-4 col-3">
                  {/* <h4 className="page-title">Doctors</h4> */}
                  {/* {success &&
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
								</button>
								<a className="text-center">{success}</a>
						    </div>
                        } */}
                        {error &&
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
									{/* <span aria-hidden="true">×</span> */}
								</button>
								<a className="text-center">{error}</a>
						    </div>
                        }

              </div>
              
          </div>

          <div className="card">
            <div className="card-header"> 
              <div className="row">
                  <div className="col-md-3">
                    <label>Keyword</label>
                      <div className="input-group">
                      <input type="text" className="form-control" name="keyword" onChange={handleChange} id="keyword"/>
                          <div className="input-group-append">
                              <button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
                          </div>
                      </div>
                  </div>
                  
                  <div className="col-md-2">
                    <div className="form-group">
                      <label><br/></label>
                      <br/>
                      <button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search <i className="fa fa-spinner fa-pulse hide" id="loader"></i></button>
                    </div>
                  </div>
              </div>
            </div>
          <div class="row">
              <div class="col-md-12 mb-4">
                <div class="nav-tabs-boxed">
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" onClick={() => handleTabChange('app')}><a class="nav-link active" data-toggle="tab" href="#home-1" role="tab" aria-controls="home"><strong><i className="fa fa-mobile-phone"></i> App</strong></a></li>
                    <li class="nav-item" onClick={() => handleTabChange('web')}><a class="nav-link" data-toggle="tab" href="#profile-1" role="tab" aria-controls="profile"><strong><i className="fa fa-desktop"></i> Web</strong></a></li>
                    <li class="nav-item" onClick={() => handleTabChange('subscribed')}><a class="nav-link" data-toggle="tab" href="#subscribed-1" role="tab" aria-controls="subscribed"><strong><i className="fa fa-ticket"></i> Subscribed</strong></a></li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane active" id="home-1" role="tabpanel">
                      

                      <div className="card-body">
                        <table className="table table-responsive-sm table-hover datatable">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th className="text-center">Professional Name</th>
                                <th>Phone Number</th>
                                <th>Practice number</th>
                                <th className="text-center">Speciality</th>
                                {/* <th className="text-center">Available Time</th> */}
                                <th className="text-center">Verified</th>
                                <th>Subscription</th>
                              </tr>
                            </thead>
                            <tbody>
                          {doctors.map((doctor, index)=> (
                            <tr key={index}>
                              <td>{doctor.id}</td>
                              <td>
                                <Link to={`/professional_profile?id=${doctor.id}`}>
                                {doctor.avatar &&
                                <img width="28" height="28" src={doctor.avatar} className="rounded-circle m-r-5" alt=""/>  
                                }
                                {!doctor.avatar &&
                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/>  
                                }
                                &nbsp;
                                {doctor.first_name} {doctor.last_name}&nbsp; 
                                {doctor.is_verified &&
                                  <img width="20" height="20" src="assets/images/verified.jpg" className="rounded-circle m-r-5" alt=""/> 
                                }
                                </Link>&nbsp;&nbsp;
                                
                                {/* <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                  <span className="badge badge-dark">ID: {doctor.id}</span>
																	</a>
																	<div className="dropdown-menu dropdown-menu-right">
																		<a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{doctor.email}</a>
																		<a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{doctor.phone_number}</a>
																		<a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{doctor.gender}</a>
																		<a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{doctor.date_of_birth}</a>
																	</div> */}
                              </td>
                              
                              <td>{doctor.phone_number}</td>
                              <td>{doctor.practice_number}</td>
                              <td className="text-center"> {doctor.speciality && <Fragment>
                                {doctor.speciality.map(speciality =>(
                                  <Fragment>
                                    <span className="badge badge-pill badge-warning" key={speciality}>
                                  {speciality}
                                  </span>&nbsp;</Fragment>
                                  ))}</Fragment>}
                              </td>
                              {/* <td className="text-center">
                              {doctor.available_time &&
                                  <Fragment>
                                    <a className="custom-badge status-red dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                      <div className="text-center"><span className="badge badge-dark"><i className="fa fa-calendar"></i> Available Time</span>	</div>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <ul>
                                      {doctor.available_time.map(available_time =>(
                                        <li>
                                        <a className="dropdown-item" ><span className="badge badge-primary">{available_time.day}</span>&nbsp;<span className="badge badge-dark">{formatTime(available_time.from_time)}-{formatTime(available_time.to_time)}</span>&nbsp;<span className="badge badge-light">GHS {available_time.amount_charged}</span></a>
                                        </li>
                                      ))}
                                      </ul>
                                      
                                    </div>
                                  </Fragment>}
                              </td> */}
                              <td>
                                <label class="c-switch c-switch-label c-switch-pill c-switch-primary text-center">
                                  {/* {loading} */}
                                  
                                    <input class="c-switch-input" type="checkbox" defaultChecked={doctor.is_verified} onChange={() => verify(doctor.id, index)} id={`verify${doctor.id}`} /><span class="c-switch-slider" data-checked="On" data-unchecked="Off"></span>
                                      <i className="fa fa-spinner fa-pulse hide" id={`loader${doctor.id}`}></i>
                                  </label>
                              </td>
                              <td>
                                {doctor.user_type.includes('subscribed_professional')?
                                  <button className="btn btn-sm btn-pill btn-success" type="button">Subscribed</button>
                                  :
                                  <button data-toggle="modal" data-target={`#tag${doctor.id}`} className="btn btn-sm btn-pill btn-primary" type="button">Subscribe</button>
                                  
                                  }
                                  <div id={`tag${doctor.id}`} className="modal fade delete-modal" role="dialog">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-body text-center">
                                            
                                                Are you sure want to subscribe {doctor.first_name} ?
                                                <br/>
                                                <br/>
                                                {success &&
                                                    <div class="alert alert-success" role="alert">Successfully subscribed <strong>{doctor.first_name}</strong>.</div>
                                                }
                                                {error &&
                                                    <div class="alert alert-danger" role="alert">Error subscribing <strong>{doctor.first_name}</strong>.</div>
                                                }
                                                <br/>
                                                <div className="m-t-20">
                                                    <input hidden name="id" value={doctor.id} onChange={handleChange}></input>
                                                    <a href="#" className="btn btn-white" data-dismiss="modal">
                                                        Close
                                                    </a>
                                                    <button type="submit" className="btn btn-danger" onClick={() => handleSubmit(doctor.id, index)} disabled={loading || success}>
                                                        {loading?
                                                            <Fragment><i className="fa fa-spinner fa-pulse"></i> loading...</Fragment>:<Fragment>Subscribe</Fragment>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      { tableData.text === tableThemes.paginate.text && paginateCount > 0?
                      <ul className="pagination justify-content-center">
                        <li className={prev_page?"page-item":"page-item disabled"} onClick={() => nextPage(prev_page)}><a className="page-link" href="#" >Prev</a></li>
                        {[...Array(paginateCount)].map((page, i)=>(
                          <li className={page_number==i?"page-item active":"page-item"} onClick={() => nextPage(`${Literals.BASE_API}professional/backend/?organization=false&page=${i+1}&page_size=50`)}><a className="page-link" href="#" key={i}>{i+1}</a></li>
                        ))}
                        
                        <li className={next_page?"page-item":"page-item disabled"} onClick={() => nextPage(next_page)}><a className="page-link" href="#">Next</a></li>
                    </ul>:

                    <div className="row">
                      <div className="col-sm-12">
                          <div className="text-center">
                            {loading &&
                              <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            }
                          </div>
                      </div>
                    </div>}
                    
                    </div>
                    
                      </div>
                    <div class="tab-pane" id="profile-1" role="tabpanel">
                    <div className="card-body">
                        <table className="table table-responsive-sm table-hover datatable">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th className="text-center">Professional Name</th>
                                <th>Phone Number</th>
                                <th>Practice number</th>
                                <th className="text-center">Speciality</th>
                                {/* <th >Facility</th> */}
                                {/* <th className="text-center">Available Time</th> */}
                                <th className="text-center">Verified</th>
                              </tr>
                            </thead>
                            <tbody>
                          {web_doctors.map((doctor, index) => (
                            <tr key={doctor.id}>
                              <td>{doctor.id}</td>
                              <td>
                                <Link to={`/professional_profile?id=${doctor.id}`}>
                                {doctor.avatar &&
                                <img width="28" height="28" src={doctor.avatar} className="rounded-circle m-r-5" alt=""/>  
                                }
                                {!doctor.avatar &&
                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/>  
                                }
                                &nbsp;
                                {doctor.first_name} {doctor.last_name}&nbsp; 
                                {doctor.is_verified &&
                                  <img width="20" height="20" src="assets/images/verified.jpg" className="rounded-circle m-r-5" alt=""/> 
                                }
                                </Link>
                              </td>
                              
                              <td>{doctor.phone_number}</td>
                              
                              <td>{doctor.practice_number}</td>
                              <td className="text-center"> {doctor.speciality && <Fragment>
                                {doctor.speciality.map(speciality =>(
                                  <Fragment>
                                    <span className="badge badge-pill badge-warning" key={speciality}>
                                  {speciality}
                                  </span>&nbsp;</Fragment>
                                  ))}</Fragment>}
                              </td>
                              {/* <td className="text-center">
                                  {doctor.organization && <span className="badge badge-primary">{doctor.organization.name}</span>}
                              </td> */}
                              {/* <td className="text-center">
                              {doctor.available_time &&
                                  <Fragment>
                                    <a className="custom-badge status-red dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                      <div className="text-center"><span className="badge badge-dark"><i className="fa fa-calendar"></i> Available Time</span>	</div>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <ul>
                                      {doctor.available_time.map(available_time =>(
                                        <li>
                                        <a className="dropdown-item" ><span className="badge badge-primary">{available_time.day}</span>&nbsp;<span className="badge badge-dark">{formatTime(available_time.from_time)}-{formatTime(available_time.to_time)}</span>&nbsp;<span className="badge badge-light">GHS {available_time.amount_charged}</span></a>
                                        </li>
                                      ))}
                                      </ul>
                                      
                                    </div>
                                  </Fragment>}
                              </td> */}
                              <td>
                                <label class="c-switch c-switch-label c-switch-pill c-switch-primary text-center">
                                  {/* {loading} */}
                                  
                                    <input class="c-switch-input" type="checkbox" defaultChecked={doctor.is_verified} onChange={() => verify(doctor.id, index)} id={`verify${doctor.id}`} /><span class="c-switch-slider" data-checked="On" data-unchecked="Off"></span>
                                      <i className="fa fa-spinner fa-pulse hide" id={`loader${doctor.id}`}></i>
                                  </label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      { tableData.text === tableThemes.paginate.text && paginateCount > 0?
                      <ul className="pagination justify-content-center">
                        <li className={prev_page?"page-item":"page-item disabled"} onClick={() => nextPage(prev_page)}><a className="page-link" href="#" >Prev</a></li>
                        {[...Array(paginateCount)].map((page, i)=>(
                          <li className={page_number==i?"page-item active":"page-item"} onClick={() => nextPage(`${Literals.BASE_API}professional/backend/?organization=false&page=${i+1}&page_size=50`)}><a className="page-link" href="#" key={i}>{i+1}</a></li>
                        ))}
                        
                        <li className={next_page?"page-item":"page-item disabled"} onClick={() => nextPage(next_page)}><a className="page-link" href="#">Next</a></li>
                    </ul>:

                    <div className="row">
                      <div className="col-sm-12">
                          <div className="text-center">
                            {loading &&
                              <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            }
                          </div>
                      </div>
                    </div>}
                      
                    
                    </div>
                    
                    
                      
                    </div>
                    <div class="tab-pane" id="subscribed-1" role="tabpanel">
                      <div className="card-body">
                      <table className="table table-responsive-sm table-hover datatable">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Professional Name</th>
                                <th className="text-center">Phone Number</th>
                                <th className="text-center">Email</th>
                              </tr>
                            </thead>
                            <tbody>
                          {subscribed_doctors.map((doctor, index) => (
                            <tr key={doctor.id}>
                              <td>{doctor.id}</td>
                              <td >
                                <Link to={`/professional_profile?id=${doctor.id}`}>
                                {doctor.avatar &&
                                <img width="28" height="28" src={doctor.avatar} className="rounded-circle m-r-5" alt=""/>  
                                }
                                {!doctor.avatar &&
                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/>  
                                }
                                &nbsp;
                                {doctor.first_name} {doctor.last_name}&nbsp; 
                                {doctor.is_verified &&
                                  <img width="20" height="20" src="assets/images/verified.jpg" className="rounded-circle m-r-5" alt=""/> 
                                }
                                </Link>
                              </td>
                              
                              <td className="text-center">{doctor.phone_number}</td>
                              
                              <td className="text-center">{doctor.email}</td>
                            
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      

                    <div className="row">
                      <div className="col-sm-12">
                          <div className="text-center">
                            {subscribed_loading &&
                              <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            }
                          </div>
                      </div>
                    </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
        </div>
        </div>
      </div>
      </main>
      </div>
    </div>
    </div>
  );
}

function mapStateToProps({doctors, web_doctors, subscribed_doctors}){
  return {error:doctors.error, success:doctors.success, next_page:doctors.next_page, loading:doctors.loading, prev_page:doctors.prev_page, count:doctors.count,
    web_error:web_doctors.web_error, web_success:web_doctors.web_success, web_next_page:web_doctors.web_next_page, web_loading:web_doctors.web_loading, web_count:web_doctors.web_count, web_prev_page:web_doctors.web_prev_page,
  
    subscribed_error:subscribed_doctors.error, subscribed_success:subscribed_doctors.success, subscribed_next_page:subscribed_doctors.next_page, subscribed_loading:subscribed_doctors.loading};
  
}
export default connect(mapStateToProps, { verifyProfessional, search_keyword,loadMoreDoctors,verifyWebProfessional,search_web_keyword,loadMoreWebDoctors, loadPageDoctors, loadPageWebDoctors, subscribe_professional, loadMoreSubscribedDoctors})(DoctorsComponent);