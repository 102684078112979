import axios from 'axios';
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
  loading: false,
  subscriptions: [],
  error: null,
  success:null,
  delete_error: null,
  delete_success:null,
};

function subscriptions(state = INITIAL_STATE, action) {
  const { type, payload, next_page, count, index} = action;
  switch (type) {
    case 'FETCH_SUBSCRIPTIONS':
      return { ...state, loading: true };
    case 'FETCH_SUBSCRIPTIONS_SUCCESS':
      return { ...state, loading: false, subscriptions: payload, success:null, error:null, next_page:next_page, count:count};
    case 'FETCH_SUBSCRIPTIONS_FAILURE':
      return { ...state, loading: false, error: payload, success:null};
    case 'CREATE_SUBSCRIPTIONS_SUCCESS':
      var subscriptions = state.subscriptions.concat(payload)

      return { ...state, loading: false, subscriptions: subscriptions, success:true, error:null};
    case 'CREATE_SUBSCRIPTIONS_FAILED':
        return { ...state, loading: false, error: payload, success:null};
    
    case 'DELETE_SUBSCRIPTIONS_SUCCESS':
      delete state.subscriptions[index]; 
      var subscriptions = state.subscriptions
      
      return { ...state, loading: false, subscriptions: subscriptions, delete_success:true, delete_error:null};
    case 'DELETE_SUBSCRIPTIONS_FAILED':
      return { ...state, loading: false, delete_error: payload, delete_success:null};
    case 'SEARCH_SUBSCRIPTIONS_SUCCESS':
      return { ...state, loading: false, subscriptions: payload};
    case 'SEARCH_SUBSCRIPTIONS_FAILED':
      return { ...state, loading: false, error: payload};
    case 'LOAD_MORE_SUBSCRIPTIONS_SUCCESS':
        var subscriptions = state.subscriptions.concat(payload)
      return { ...state, loading: false, subscriptions: subscriptions, error: null, success: null, next_page:next_page};
    case 'LOAD_MORE_SUBSCRIPTIONS_FAILURE':
      return { ...state, loading: false};

    default:
      return state;
  }
}

export const create_subscription = data => async dispatch => {
  dispatch({ type: 'FETCH_SUBSCRIPTIONS' });
  try {
     
     

     var subscription = await axios.post(`${Literals.BASE_API}web/backend/subscription_packages/`, data);


    dispatch({ type: 'CREATE_SUBSCRIPTIONS_SUCCESS', payload: subscription.data.results, success:true});
    document.getElementById("subscription-form").reset();
    
    // document.getElementById("myModal").style.display = "none";
  } catch (e) {
    console.log('error is ', e)
    dispatch({
      type: 'CREATE_SUBSCRIPTIONS_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};




export const delete_subscription = (id, index) => async dispatch => {
  dispatch({ type: 'FETCH_SUBSCRIPTIONS' });
  try {
    await axios.delete(`${Literals.BASE_API}web/backend/${id}/subscription_packages/`);
    dispatch({ type: 'DELETE_SUBSCRIPTIONS_SUCCESS', payload: true, success:true, index:index});
  } catch (e){
    dispatch({
      type:'DELETE_SUBSCRIPTIONS_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};




export const search_keyword = data => async dispatch => {
  dispatch({ type: 'FETCH_SUBSCRIPTIONS' });

  try {
    var keyword = data.keyword?data.keyword:'';
    let response = await axios.get(`${Literals.BASE_API}web/backend/subscription_packages/?page=1&page_size=100&keyword=${keyword}`);
    document.getElementById("loader").classList.add("hide");
    dispatch({ type: 'SEARCH_SUBSCRIPTIONS_SUCCESS', payload: response.data.results});
  } catch (e) {
    document.getElementById("loader").classList.add("hide");
    dispatch({
      type: 'SEARCH_SUBSCRIPTIONS_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const loadMoreSubscriptions = api => async dispatch => {
  dispatch({ type: 'FETCH_SUBSCRIPTIONS' });
  try {
    const { data } = await axios.get(api);

    dispatch({ type: 'LOAD_MORE_SUBSCRIPTIONS_SUCCESS', payload: data.results, next_page:data.next});
  } catch (e) {
    dispatch({ type: 'LOAD_MORE_SUBSCRIPTIONS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};

export default subscriptions;
