import { connect } from 'react-redux';
import { fetchDoctors, fetchSubscribedDoctors } from './duck/actions';
import { fetchWebDoctors } from './duck/actions';
import DoctorsComponent from './DoctorsPage';

const mapStateToProps = ({ doctors: { doctors }, web_doctors: { web_doctors }, subscribed_doctors:{subscribed_doctors} }) => {
  return {
    doctors,web_doctors, subscribed_doctors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDoctors: () => dispatch(fetchDoctors()),
    fetchWebDoctors: () => dispatch(fetchWebDoctors()),
    fetchSubscribedDoctors: () => dispatch(fetchSubscribedDoctors())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorsComponent);
