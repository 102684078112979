import axios from 'axios';
import Literals from '../../helpers/Literals';
import { authenticateRequest } from '../../helpers/utils';


export const fetchAccounts = () => async dispatch => {
  dispatch({ type: 'FETCH_ACCOUNTS' });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}users/backend/?page=1&page_size=50`);

    dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: data.results, next_page:data.next, count:data.count});
  } catch (e) {
    authenticateRequest(e);
    dispatch({ type: 'FETCH_ACCOUNTS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};






export const getAccount = (id) => async dispatch => {
  dispatch({ type: 'ADMIN_ACCOUNT' });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}users/backend/${id}/`);
    dispatch({ type: 'FETCH_ADMIN_ACCOUNT_SUCCESS', payload: data.results });
  } catch (e) {
    authenticateRequest(e);
    dispatch({ type: 'FETCH_ADMIN_ACCOUNT_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};

export const getPermissions = (id) => async dispatch => {
  dispatch({ type: 'GET_PERMISSIONS' });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}users/backend/config/?user_id=${id}`);
    dispatch({ type: 'GET_PERMISSIONS_SUCCESS', payload: data.results });
  } catch (e) {
    authenticateRequest(e);
    dispatch({ type: 'GET_PERMISSIONS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};
