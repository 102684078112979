
import axios from 'axios'
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
    loading: false,
    languages: [],
    error: null,
  };
  
  function languages(state = INITIAL_STATE, action) {
    const { type, payload, next_page, count} = action;
      switch (type) {
        case 'FETCH_LANGUAGE':
          return { ...state, loading: true };
        case 'FETCH_LANGUAGE_SUCCESS':
            return { ...state, loading: false, languages: payload, success:null, error:null, next_page:next_page, count:count};
        case 'FETCH_LANGUAGE_FAILED':
          return { ...state, loading: false, error: payload };
        case 'CREATE_LANGUAGE_SUCCESS':
            return { ...state, loading: false, languages: payload, create_success:true, error:null};
        case 'CREATE_LANGUAGE_FAILED':
            return { ...state, loading: false, error: payload, create_success:null};
        case 'UPDATE_LANGUAGE_SUCCESS':
              return { ...state, loading: false, languages: payload, create_success:true, error:null};
        case 'UPDATE_LANGUAGE_FAILED':
            return { ...state, loading: false, error: payload, create_success:null};

        case 'SEARCH_LANGUAGE_SUCCESS':
          return { ...state, loading: false, languages: payload};
        case 'SEARCH_LANGUAGE_FAILED':
          return { ...state, loading: false, error: payload};

        case 'DELETE_LANGUAGE_SUCCESS':
            return { ...state, loading: false, languages: payload, create_success:true, error:null};
        case 'DELETE_LANGUAGE_FAILED':
                return { ...state, loading: false, error: payload, create_success:null};
        case 'LOAD_MORE_LANGUAGE_SUCCESS':
            var languages = state.languages.concat(payload)
            return { ...state, loading: false, languages: languages, error: null, success: null, next_page:next_page};
        case 'LOAD_MORE_LANGUAGE_FAILED':
            return { ...state, loading: false};
        default:
          return state;
      }
    }

    export const loadMoreLanguage = api => async dispatch => {
        dispatch({ type: 'FETCH_LANGUAGE' });
        try {
          const { data } = await axios.get(api);
      
          dispatch({ type: 'LOAD_MORE_LANGUAGE_SUCCESS', payload: data.results, next_page:data.next});
        } catch (e) {
          dispatch({ type: 'LOAD_MORE_LANGUAGE_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
        }
      };


      export const create_language = data => async dispatch => {
        dispatch({ type: 'FETCH_LANGUAGE' });
        try {
           await axios.post(`${Literals.BASE_API}web/backend/custom_types/`, data);
      
           var languages = await axios.get(`${Literals.BASE_API}web/backend/custom_types/?page=1&page_size=50&category=language`);
      
          dispatch({ type: 'CREATE_LANGUAGE_SUCCESS', payload: languages.data.results, create_success:true});
          document.getElementById("speciality-form").reset();
      
          document.getElementById("add_speciality").style.display = "none";
        } catch (e) {
          console.log('error is ', e)
          dispatch({
            type: 'CREATE_LANGUAGE_FAILED',
            payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
          });
        }
      };

      export const update_language = data => async dispatch => {
        dispatch({ type: 'FETCH_LANGUAGE' });
        try {
           await axios.put(`${Literals.BASE_API}web/backend/${data.id}/custom_types/`, data);
      
           var languages = await axios.get(`${Literals.BASE_API}web/backend/custom_types/?page=1&page_size=50&category=language`);
      
          dispatch({ type: 'UPDATE_LANGUAGE_SUCCESS', payload: languages.data.results, create_success:true});
      
        } catch (e) {
          console.log('error is ', e)
          dispatch({
            type: 'UPDATE_LANGUAGE_FAILED',
            payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
          });
        }
      };

      export const delete_language = id => async dispatch => {
        dispatch({ type: 'FETCH_LANGUAGE' });
        try {
           await axios.delete(`${Literals.BASE_API}web/backend/${id}/custom_types/`);
      
           var languages = await axios.get(`${Literals.BASE_API}web/backend/custom_types/?page=1&page_size=50&category=language`);
      
          dispatch({ type: 'DELETE_LANGUAGE_SUCCESS', payload: languages.data.results, create_success:true});
          
        } catch (e) {
          console.log('error is ', e)
          dispatch({
            type: 'DELETE_LANGUAGE_FAILED',
            payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
          });
        }
      };

      export const search_keyword = data => async dispatch => {

        try {
          var keyword = data.keyword?data.keyword:'';
          let response = await axios.get(`${Literals.BASE_API}web/backend/custom_types/?page=1&page_size=50&category=language&keyword=${keyword}`);
          document.getElementById("loader").classList.add("hide");
          dispatch({ type: 'SEARCH_LANGUAGE_SUCCESS', payload: response.data.results});
        } catch (e) {
          document.getElementById("loader").classList.add("hide");
          dispatch({
            type: 'SEARCH_LANGUAGE_FAILED',
            payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
          });
        }
      };
      
  
    export default languages;
  