import axios from 'axios';
import Literals from '../../helpers/Literals';

export const REQUEST_USER = 'REQUEST_USER';
export const USER_SUCCESS = 'USER_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const USER_FAILURE = 'USER_FAILURE';
export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_FAILED = 'LOG_OUT_FAILED';


export const getUser = () => dispatch => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    axios.defaults.headers.common['Authorization'] = `Token ${user.auth_token}`;
    dispatch({ type: USER_SUCCESS, payload: user });
  } catch (error) {
    return dispatch({ type: USER_FAILURE, payload: null });
  }
};

export const login = data => async dispatch => {
  dispatch({ type: REQUEST_USER });
  try {
    let user = await axios.post(`${Literals.BASE_API}users/backend/login/`, data);
    window.localStorage.setItem('user', JSON.stringify(user.data.results));
    axios.defaults.headers.common['Authorization'] = `Token ${user.data.results.auth_token}`;
    
    //get user permissions
    var admin_id = user.data.results.id
    let permissions  = await axios.get(`${Literals.BASE_API}users/backend/config/?user_id=${admin_id}`);
    window.localStorage.setItem('permissions', JSON.stringify(permissions.data.results?permissions.data.results:''));


    dispatch({ type: USER_SUCCESS, payload: user.data.results });
  } catch (error){
    dispatch({
      type: LOGIN_FAILED,
      payload: error.response?error.response.data.details:'Error Occured',
    });
  }
};

export const logout = data => async dispatch => {
  dispatch({ type: LOG_OUT });
  try {
    await axios.post(`${Literals.BASE_API}users/backend/logout/`);
    localStorage.clear();
  } catch (error) {
    dispatch({
      type: LOG_OUT_FAILED,
      payload: error.response.data.details,
    });
  }
};

export const forgot_password = data => async dispatch => {
  dispatch({ type: 'REQUEST_USER' });
  try {
    let user = await axios.post(`${Literals.BASE_API}users/backend/reset_password/`, data);
    dispatch({ type: 'RESET_PASSWORD_SUCCESS', payload: user.data });
  } catch (error) {
    dispatch({
      type: 'RESET_PASSWORD_FAILED',
      payload: error.response?error.response.data.details:'Error Occured',
    });
  }
};

const INITIAL_STATE = {
  loading: false,
  user: undefined,
  error: null,
  success:null,
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_USER:
      return { ...INITIAL_STATE, loading: true };
    case USER_SUCCESS:
      return { ...INITIAL_STATE, user: payload };
    case USER_FAILURE:
      return { ...INITIAL_STATE, user: undefined };
    case LOGIN_FAILED:
      return { ...INITIAL_STATE, error: payload };
    case LOG_OUT:
      return { ...INITIAL_STATE };
    case LOG_OUT_FAILED:
      return { ...INITIAL_STATE };
    case 'RESET_PASSWORD_SUCCESS':
      return { loading: false, success:true, error:null};
    case 'RESET_PASSWORD_FAILED':
      return { loading: false, success:null, error:payload};


    default:
      return state;
  }
};

export default AuthReducer;
