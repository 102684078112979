

const INITIAL_STATE = {
  loading: false,
  patient_profile: {},
  error: null,
};

function patient_profile(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
      case 'FETCH_PATIENT_PROFILE':
        return { ...state, loading: true };
      case 'FETCH_PATIENT_PROFILE_SUCCESS':
        return { ...state, loading: false, patient_profile: payload, error: null};
      case 'FETCH_PATIENT_PROFILE_FAILURE':
        return { ...state, loading: false, error: payload };
      default:
        return state;
    }
  }

  export default patient_profile;
