import axios from 'axios';
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
  loading: false,
  doctors: [],
  error: null,
  success:null,
  create_success:null,
  create_error:null,
};

function doctors(state = INITIAL_STATE, action) {
  const { type, payload, next_page, prev_page, count, index} = action;
  switch (type) {
    case 'FETCH_DOCTORS':
      return { ...state, loading: true };
    case 'FETCH_DOCTORS_SUCCESS':
      return { ...state, loading: false, doctors: payload, success:null, error:null, next_page:next_page, prev_page:prev_page, count:count};
    case 'FETCH_DOCTORS_FAILURE':
      return { ...state, loading: false, error: payload, success:null};
    case 'CREATE_DOCTOR_SUCCESS':
      return { ...state, loading: false, doctor: payload, create_success:true, create_error:null};
    case 'CREATE_DOCTOR_FAILED':
        return { ...state, loading: false, create_error: payload, create_success:null};

    case 'VERIFY_DOCTOR_SUCCESS':
      state.doctors[index] = payload
      var doctors = state.doctors
      return { ...state, loading: false, doctors: doctors};
    case 'VERIFY_DOCTOR_FAILED':
      return { ...state, loading: false, error: payload};

      case 'SUBSCRIBE_PROFESSIONAL':
        return { ...state, loading:true};
    case 'SUBSCRIBE_PROFESSIONAL_SUCCESS':
        state.doctors[index] = payload
        var doctors = state.doctors
        return { ...state, loading: false, error: null, success: true, doctors: doctors};
    case 'SUBSCRIBE_PROFESSIONAL_FAILURE':
        return { ...state, loading: false, error: true, success: null};
    
    case 'DELETE_DOCTOR_SUCCESS':
      return { ...state, loading: false, doctor: payload, success:true};
    case 'DELETE_DOCTOR_FAILED':
      return { ...state, loading: false, error: payload, success:null};
    case 'SEARCH_DOCTOR_SUCCESS':
      return { ...state, loading: false, doctors: payload};
    case 'SEARCH_DOCTOR_FAILED':
      return { ...state, loading: false, error: payload};
    case 'LOAD_MORE_DOCTORS_SUCCESS':
        var doctors = state.doctors.concat(payload)
      return { ...state, loading: false, doctors: doctors, error: null, success: null, next_page:next_page};
    case 'LOAD_MORE_DOCTORS_FAILURE':
      return { ...state, loading: false};

    case 'LOAD_PAGE_DOCTORS_SUCCESS':
      return { ...state, loading: false, doctors: payload, success:null, error:null, next_page:next_page, prev_page:prev_page, count:count};
    case 'LOAD_PAGE_DOCTORS_FAILED':
      return { ...state, loading: false, error: payload, success:null};

    default:
      return state;
  }
}



export const verifyProfessional = data => async dispatch => {
  dispatch({ type: 'FETCH_DOCTORS',  loading:true, error:false, success:false});
  try {
    var response = await axios.put(`${Literals.BASE_API}professional/backend/${data.id}/set_status/`, data);
    document.getElementById("loader"+ data.id +"").classList.add("hide");
    // var response  = await axios.get(`${Literals.BASE_API}professional/backend/?page=1&page_size=50`);

    
    dispatch({ type: 'VERIFY_DOCTOR_SUCCESS', payload: response.data.results, success:true, index:data.index});
  } catch (e){
    document.getElementById("loader"+ data.id +"").classList.add("hide");
    dispatch({
      type:'VERIFY_DOCTOR_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};



export const search_keyword = data => async dispatch => {

  try {
    var keyword = data.keyword?data.keyword:'';
    let response = await axios.get(`${Literals.BASE_API}professional/backend/?page=1&page_size=100&keyword=${keyword}`);
    document.getElementById("loader").classList.add("hide");
    dispatch({ type: 'SEARCH_DOCTOR_SUCCESS', payload: response.data.results});
  } catch (e) {
    document.getElementById("loader").classList.add("hide");
    dispatch({
      type: 'SEARCH_DOCTOR_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const loadMoreDoctors = api => async dispatch => {
  dispatch({ type: 'FETCH_DOCTORS' });
  try {
    const { data } = await axios.get(api);

    dispatch({ type: 'LOAD_MORE_DOCTORS_SUCCESS', payload: data.results, next_page:data.next});
  } catch (e) {
    dispatch({ type: 'LOAD_MORE_DOCTORS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};


export const loadPageDoctors = api => async dispatch => {
  dispatch({ type: 'FETCH_DOCTORS' });
  try {
    const { data } = await axios.get(api);

    dispatch({ type: 'LOAD_PAGE_DOCTORS_SUCCESS', payload: data.results, next_page:data.next, prev_page:data.previous, count:data.count});
  } catch (e) {
    dispatch({ type: 'LOAD_PAGE_DOCTORS_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};


export const subscribe_professional = (id, index) => async dispatch => {
  dispatch({ type: 'SUBSCRIBE_PROFESSIONAL' });
  try {
    const { data } = await axios.put(`${Literals.BASE_API}users/backend/${id}/tag/`, {"tag":"subscribed_professional"});

    dispatch({ type: 'SUBSCRIBE_PROFESSIONAL_SUCCESS', payload: data.results, next_page:data.next, index:index});
  } catch (e) {
    dispatch({ type: 'SUBSCRIBE_PROFESSIONAL_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};

export default doctors;
