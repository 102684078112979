import { connect } from 'react-redux';
import { fetchAccounts } from "../Accounts/duck/actions";
import DashboardComponent from './DashboardPage';
import { fetchFacility } from '../Facility/duck/actions';
import { fetchSpeciality } from '../Settings/ducks/actions';
import { fetchDashBoardCount } from "./duck/actions";

const mapStateToProps = ({ 
                           dashboard:{dashboard},
                           accounts:{accounts},
                           facilities:{facilities},
                           specialities:{specialities}
                           
                        }) => {
  return {
    dashboard, accounts, facilities, specialities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDashBoardCount: () => dispatch(fetchDashBoardCount()),
    fetchAccounts: () => dispatch(fetchAccounts()),
    fetchFacility: () => dispatch(fetchFacility()),
    fetchSpeciality: () => dispatch(fetchSpeciality()),
    
    
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
