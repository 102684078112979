import LoginComponent from '../auth/LoginPage';

import AccountsContainer from '../Accounts/AccountsContainer';
import AdminProfileContainer from '../Accounts/AdminProfileContainer';
import AddAdminComponent from '../Accounts/AddAdminPage';
import ProfileComponent from '../Settings/profile/ProfilePage';
import EditProfileComponent from '../Settings/profile/EditProfilePage';
import AppointmentsContainer from '../Appointments/AppointmentsContainer';
import SchedulesContainer from '../Schedules/SchedulesContainer';
import DoctorsContainer from '../Doctors/DoctorsContainer';
import DoctorProfileContainer from '../Doctors/DoctorProfileContainer';
import FacilityConatainer from '../Facility/FacilityContainer';
import CallLogsContainer from '../Appointments/CallLogsContainer';
import PatientsContainer from '../Patients/PatientsContainer';
import PatientProfileContainer from '../Patients/PatientProfileContainer';
import SpecialityContainer from '../Settings/speciality/SpecialityContainer';
import BillingContainer from '../Billing/BillingContainer';
import DashboardContainer from '../Dashboard/DashboardContainer';
import LanguageContainer from '../Settings/language/LanguageContainer';
import AgentsContainer from '../Agents/AgentsContainer';
import ReferralsContainer from "../Agents/ReferralsContainer";
import SubscriptionContainer from '../Subscriptions/SubscriptionContainer';
import Search from '../Search';



export const authorized = [
  { path: '/dashboard', exact: true, component: DashboardContainer },
  { path: '/admins', exact: true, component: AccountsContainer },
  { path: '/admin_profile', exact: true, component: AdminProfileContainer },
  { path: '/add_admin', exact: true, component: AddAdminComponent },
  { path: '/profile', exact: true, component: ProfileComponent },
  { path: '/edit_profile', exact: true, component: EditProfileComponent },
  { path: '/appointments', exact: true, component: AppointmentsContainer },
  { path: '/schedules', exact: true, component: SchedulesContainer },
  { path: '/professionals', exact: true, component: DoctorsContainer },
  { path: '/professional_profile', exact: true, component: DoctorProfileContainer },
  { path: '/facilities', exact: true, component: FacilityConatainer },
  { path: '/call_logs', exact: true, component: CallLogsContainer },
  { path: '/patients', exact: true, component: PatientsContainer },
  { path: '/patient_profile', exact: true, component: PatientProfileContainer },
  { path: '/specialities', exact: true, component: SpecialityContainer },
  { path: '/languages', exact: true, component: LanguageContainer },
  { path: '/billing', exact: true, component: BillingContainer },
  { path: '/agents', exact: true, component: AgentsContainer },
  { path: '/referrals', exact: true, component: ReferralsContainer },
  { path: '/subscriptions', exact: true, component: SubscriptionContainer },
  { path: '/search/:id/:search_type', exact: true, component: Search },

];

export const unauthorized = [{ path: '/login', exact: true, component: LoginComponent },
                             ];
