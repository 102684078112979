import axios from 'axios';
import Literals from '../../helpers/Literals'
import { authenticateRequest } from '../../helpers/utils';

export const fetchUsers = () => async dispatch => {
    dispatch({ type: 'FETCH_USERS' });
    try {
      const { data } = await axios.get(`${Literals.BASE_API}users/backend/?page=1&page_size=100&all_users`);
  
      dispatch({ type: 'FETCH_USERS_SUCCESS', payload: data.results, next_page:data.next, count:data.count});
    } catch (e) {
      authenticateRequest(e);
      dispatch({ type: 'FETCH_USERS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
    }
  };
  
  export const fetchAgents = () => async dispatch => {
    dispatch({ type: 'FETCH_AGENTS' });
    try {
      const { data } = await axios.get(`${Literals.BASE_API}users/backend/?page=1&page_size=100&tag=agent&all_users`);
  
      dispatch({ type: 'FETCH_AGENTS_SUCCESS', payload: data.results, next_page:data.next, count:data.count});
    } catch (e) {
      authenticateRequest(e);
      dispatch({ type: 'FETCH_AGENTS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
    }
  };

export const fetchReferrals = () => async dispatch => {
    dispatch({ type: 'FETCH_REFERRALS' });
    try {
      const { data } = await axios.get(`${Literals.BASE_API}users/backend/referral_points/?page=1&page_size=100`);
  
      dispatch({ type: 'FETCH_REFERRALS_SUCCESS', payload: data.results, next_page:data.next, count:data.count});
    } catch (e) {
      authenticateRequest(e);
      dispatch({ type: 'FETCH_REFERRALS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
    }
  };