import { connect } from 'react-redux';
import { fetchBilling, fetchWebBilling } from './ducks/actions';
import BillingComponent from './BillingPage';

const mapStateToProps = ({ billing: { billing }, web_billing: { web_billing } }) => {
  return {
    billing,web_billing
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchBilling: () => dispatch(fetchBilling()),
    fetchWebBilling: () => dispatch(fetchWebBilling()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingComponent);
