import types from './types';
import axios from 'axios';
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
  loading: false,
  patients: [],
  error: null,
  success:null

};

function patients(state = INITIAL_STATE, action) {
  const { type, payload, next_page, count} = action;
  switch (type) {
    case types.FETCH_PATIENTS:
      return { ...state, loading: true };
    case types.FETCH_PATIENTS_SUCCESS:
      return { ...state, loading: false, patients: payload, success:null, error:null, next_page:next_page, count:count};
    case types.FETCH_PATIENTS_FAILURE:
      return { ...state, loading: false, error: payload };

      case 'CREATE_PATIENT_SUCCESS':
        return { ...state, loading: false, patient: payload, success:true, error:null};
      case 'CREATE_PATIENT_FAILED':
          return { ...state, loading: false, error: payload, success:null};

      case 'UPDATE_PATIENT_SUCCESS':
        return { ...state, loading: false, patient: payload, success:true, error:null};
      case 'UPDATE_PATIENT_FAILED':
          return { ...state, loading: false, error: payload, success:null};

      case 'DELETE_PATIENT_SUCCESS':
        return { ...state, loading: false, patient: payload, success:true, error:null};
      case 'DELETE_PATIENT_FAILED':
          return { ...state, loading: false, error: payload, success:null};

      case 'GET_PATIENT_SUCCESS':
        return { ...state, loading: false, patient: payload, success:true, error:null};
      case 'GET_PATIENT_FAILED':
          return { ...state, loading: false, error: payload, success:null};


      case 'SEARCH_PATIENT_SUCCESS':
        return { ...state, loading: false, patients: payload, error:null};
      case 'SEARCH_PATIENT_FAILED':
          return { ...state, loading: false, error: payload};

      case 'LOAD_MORE_PATIENTS_SUCCESS':
          var patients = state.patients.concat(payload)
          return { ...state, loading: false, patients: patients, error: null, success: null, next_page:next_page};
      case 'LOAD_MORE_PATIENTS_FAILURE':
          return { ...state, loading: false};


    default:
      return state;
  }
}

export const create_patient = data => async dispatch => {
  try {
    var passwordChars = "0123456789abcdefghijklmnopqrstuvwxyz";
    var randPwLen = Math.floor(Math.random() * (8 - 8 + 1)) + 8;
    var randPassword = Array(randPwLen).fill(passwordChars).map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
    data.temporary_password = randPassword;
    if(!data.allergies){
     data.allergies = []
    }
    data['country']='Ghana';
    let patient = await axios.post(`${Literals.BASE_API}patients/web/add_patient/`, data);
    dispatch({ type: 'CREATE_PATIENT_SUCCESS', payload: patient.data, success:true });
    document.getElementById("form").reset();
  } catch (e) {
    dispatch({
      type: 'CREATE_PATIENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const update_patient = data => async dispatch => {
  try {
    var id = data.id
    if(!data.allergies){
      data.allergies = []
     }
    data['country']='Ghana';
    let patient = await axios.put(`${Literals.BASE_API}patients/web/${id}/update_patient/`, data);
    dispatch({ type: 'UPDATE_PATIENT_SUCCESS', payload: patient.data, success:true});
  } catch (e) {
    dispatch({
      type: 'UPDATE_PATIENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const delete_patient = id => async dispatch => {
  try {
    let patient = await axios.delete(`${Literals.BASE_API}patients/web/${id}/delete_patient/`);
    dispatch({ type: 'DELETE_PATIENT_SUCCESS', payload: patient.data, success:true});
  } catch (e) {
    dispatch({
      type: 'DELETE_PATIENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const search_keyword = data => async dispatch => {
  try {
    var keyword = data.keyword?data.keyword:'';
   
      var patients = await axios.get(`${Literals.BASE_API}patients/web/backend/?page=1&page_size=100&keyword=${keyword}&organization=false`);
      document.getElementById("loader").classList.add("hide");
    dispatch({ type: 'SEARCH_PATIENT_SUCCESS', payload: patients.data.results});
  } catch (e) {
    document.getElementById("loader").classList.add("hide");
    dispatch({
      type: 'SEARCH_PATIENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const loadMorePatients = api => async dispatch => {
  dispatch({ type: types.FETCH_PATIENTS });
  try {
   
    const { data } = await axios.get(api);

    dispatch({ type: 'LOAD_MORE_PATIENTS_SUCCESS', payload: data.results, next_page:data.next});
  } catch (e) {
    dispatch({ type: 'LOAD_MORE_PATIENTS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};



export default patients;
