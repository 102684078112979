import React, {useEffect, useState, Fragment} from 'react';
import Menu from '../Menu/MenuComponent';
import Topnav from '../Menu/TopnavComponent';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {tableThemes} from '../helpers/utils';
import TopNavLoader from '../helpers/utils';
import { tag_agent, loadMoreAgents, loadMoreUsers, search_users_keyword, search_agents_keyword} from "./duck/reducer";
import axios from 'axios';
import Literals from '../helpers/Literals'



function AgentsComponent({ fetchAgents, fetchUsers, users, agents, loading, success, error, agents_success, agents_error, agents_loading, agents_next_page, agents_prev_page, agents_count, users_success, users_error, users_loading, users_next_page, users_prev_page, users_count, tag_agent, loadMoreAgents, loadMoreUsers, search_users_keyword, search_agents_keyword}) {
    useEffect(() => {
        fetchAgents();
        fetchUsers(); 
        agentBouns('GET');
      }, [fetchAgents, fetchUsers]);
      

      const [state, setState] = useState({});
      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
	  }

    function handleSearch(e) {
      e.preventDefault();
          document.getElementById("loader").classList.remove("hide");
          if(tabState.tab=='agents'){
            search_agents_keyword(state);
          }else{
              
            search_users_keyword(state)
          }
      } 
      
      function handleClearField(e){
        e.preventDefault();
        document.getElementById("keyword").value='';
        state.keyword = ''
        document.getElementById("loader").classList.remove("hide");
        if(tabState.tab=='agents'){
            search_agents_keyword(state);
          }else{
              
            search_users_keyword(state)
          }
        }

    
        function handleSubmit(id, index) {

            
            tag_agent(id, index);
    
          }
      
      const [bonus, setBouns] = useState(null);
      const [bonusState, setBonusState] = useState({loading:null, success:null, error:null});
      

      async function agentBouns(method){
       
        

        if(method ==='GET'){
          await axios.get(`${Literals.BASE_API}web/backend/global_config/`).then((response)=>{
            var currentBonus = response?.data?.results?.agent_bonus_multiplier * 100

            console.log('currentBonus',currentBonus)
            setBouns(currentBonus)

          }).catch((error)=>{
            console.log(error);
          })

        }else if (method ==='PUT'){
          if(state.agent_bonus_multiplier){

            setBonusState({loading:null, success:null, error:null})
            var bouns = parseFloat((parseFloat(state.agent_bonus_multiplier) / 100).toFixed(2))

            setBonusState({loading:true, success:null, error:null})
            await axios.put(`${Literals.BASE_API}web/backend/global_config/`, {"agent_bonus_multiplier":bouns}).then((response)=>{
              var currentBonus = response.data.agent_bonus_multiplier * 100
              setBouns(currentBonus)
              setBonusState({loading:null, success:true, error:null})
            }).catch((error)=>{
              setBonusState({loading:null, success:null, error:true})
              console.log(error);
            })
          }

        }
        else{
          return
        }

      }

      function handleSubmitBonus(method) {
        agentBouns(method)
        
	  }


     


      const [tabState, setTabState] = useState({tab:'agents'});
      function handleTabChange(tab) {
        setTabState({ tab:tab });
	  }
    
   
    if(tabState.tab != 'agents'){

      var agents_next_page = users_next_page
      var agents_count = users_count
      var agents_prev_page = users_prev_page
      var agents_loading = users_loading

    }

    const urlParams = new URLSearchParams(agents_next_page);
    var paginateCount = Math.round(agents_count/50)
    var page_number = parseInt(urlParams.get('page'))?parseInt(urlParams.get('page')) -2:paginateCount-1;

    var tableData = JSON.parse(localStorage.getItem('tableDataThemes')) || tableThemes.scroll
    
      function nextPage(agents_next_page){
      if(tabState.tab=='agents'){
        if(agents_next_page && !agents_loading){
          return
        //   loadPageDoctors(next_page+'&organization=false');
        }
      }else{
        if(users_next_page && !users_loading){
            return
        //   loadPageWebDoctors(web_next_page+'&organization=true');
        }
      }
    }

    
    // if(tableData.text === tableThemes.scroll.text){
    //infinite scroll
      window.onscroll = () => {
        // Checks that the page has scrolled to the bottom
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
              
              if(tabState.tab=='agents'){
                if(agents_next_page && !agents_loading){
                 loadMoreAgents(agents_next_page);
                }
              }else{
                if(users_next_page && !users_loading){
                  loadMoreUsers(users_next_page);
                }
              }
                
            }
      };
    // }


    
  
  return (
    <div className="c-app c-legacy-theme c-no-layout-transition" >
    <Menu/>
    <div className="c-wrapper">
        <Topnav/>
        <TopNavLoader agents_loading={agents_loading}/>
        <div className="c-body">
            <main className="c-main">
                <div className="container-fluid">
                    <div className="fade-in">
          <div className="row">
              <div className="col-sm-4 col-3">
                  {/* <h4 className="page-title">Doctors</h4> */}
                  
                    {users_error &&
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                {/* <span aria-hidden="true">×</span> */}
                            </button>
                            <a className="text-center">{users_error}</a>
                        </div>
                    }

              </div>
              
          </div>

          <div className="card">
            <div className="card-header"> 
              <div className="row">
                  <div className="col-md-3">
                    <label>Keyword</label>
                      <div className="input-group">
                      <input type="text" className="form-control" name="keyword" onChange={handleChange} id="keyword"/>
                          <div className="input-group-append">
                              <button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
                          </div>
                      </div>
                  </div>
                  
                  <div className="col-md-2">
                    <div className="form-group">
                      <label><br/></label>
                      <br/>
                      <button disabled={loading} type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search <i className="fa fa-spinner fa-pulse hide" id="loader"></i></button>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group float-right">
                      <label><br/></label>
                      <br/>
                      <Link to="/referrals">
                        <button type="button" className="btn btn-info" ><i className="fa fa-handshake-o"></i>&nbsp;&nbsp;Agent Referrals</button>
                      </Link>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <button data-toggle="modal" data-target="#bonus" type="button" className="btn btn-info" ><i className="fa fa-cog"></i>&nbsp;&nbsp;Commission Settings</button>
                        <div id="bonus" className="modal fade delete-modal" role="dialog">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-body text-center">
                                                {bonusState.success &&
                                                    <div class="alert alert-success" role="alert">Commission Successfully Saved.</div>
                                                }
                                                {bonusState.error &&
                                                    <div class="alert alert-danger" role="alert">Error Saving Commission.</div>
                                                }
                                                <div className="row">
                                                  <div className="col-sm-3">
                                                  </div>
                                                  <div className="col-sm-6">
                                                    <label>Agent Bonus Multiplier <span className="text-danger">*</span></label>
                                                    <div class="input-group flex-nowrap"><span class="input-group-text" id="addon-wrapping">Pecentage (%)</span>
                                                      <input className="form-control" type="number" name="agent_bonus_multiplier" onChange={handleChange} defaultValue={bonus} required/>
                                                    </div>
                                                      
                                                  </div>
                                                </div>
                                                <br/>
                                                <br/>
                                            
                                                <div className="m-t-20">
                                                    <a href="#" className="btn btn-white" data-dismiss="modal">
                                                        Close
                                                    </a>
                                                    <button type="submit" className="btn btn-danger"  onClick={(e) => {e.preventDefault(); handleSubmitBonus('PUT')}} disabled={bonusState.loading}>
                                                        {bonusState.loading?
                                                            <Fragment><i className="fa fa-spinner fa-pulse"></i> loading...</Fragment>:<Fragment>Save</Fragment>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
                  </div>
              </div>
            </div>
          <div class="row">
              <div class="col-md-12 mb-4">
                <div class="nav-tabs-boxed">
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" onClick={() => handleTabChange('agents')}><a class="nav-link active" data-toggle="tab" href="#home-1" role="tab" aria-controls="home"><strong><i className="fa fa-address-book"></i> Agents</strong></a></li>
                    <li class="nav-item" onClick={() => handleTabChange('users')}><a class="nav-link" data-toggle="tab" href="#profile-1" role="tab" aria-controls="profile"><strong><i className="fa fa-users"></i> Users</strong></a></li>
                    
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane active" id="home-1" role="tabpanel">
                      

                      <div className="card-body">
                        <table className="table table-responsive-sm table-hover datatable">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th >Name</th>
                                <th>Phone Number</th>
                                <th>Email</th>
                                {/* <th className="text-center">Action</th> */}
                              </tr>
                            </thead>
                            <tbody>
                                
                          {agents.map((agent, index)=> (
                            <tr key={index}>
                              <td>{agent.id}</td>
                              <td>
                                <Link to={`/professional_profile?id=${agent.id}`}>
                                {agent.avatar &&
                                <img width="28" height="28" src={agent.avatar} className="rounded-circle m-r-5" alt=""/>  
                                }
                                {!agent.avatar &&
                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/>  
                                }
                                &nbsp;
                                {agent.first_name} {agent.last_name}&nbsp; 
                                {agent.is_verified &&
                                  <img width="20" height="20" src="assets/images/verified.jpg" className="rounded-circle m-r-5" alt=""/> 
                                }
                                </Link>&nbsp;&nbsp;
                                
                                {/* <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                  <span className="badge badge-dark">ID: {doctor.id}</span>
																	</a>
																	<div className="dropdown-menu dropdown-menu-right">
																		<a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{doctor.email}</a>
																		<a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{doctor.phone_number}</a>
																		<a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{doctor.gender}</a>
																		<a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{doctor.date_of_birth}</a>
																	</div> */}
                              </td>
                              
                              <td>{agent.phone_number}</td>
                              <td>{agent.email}</td>
                              
                             
                              {/* <td>
                                
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      { tableData.text === tableThemes.paginate.text && paginateCount > 0?
                      <ul className="pagination justify-content-center">
                        <li className={agents_prev_page?"page-item":"page-item disabled"} onClick={() => nextPage(agents_prev_page)}><a className="page-link" href="#" >Prev</a></li>
                        {[...Array(paginateCount)].map((page, i)=>(
                          <li className={page_number==i?"page-item active":"page-item"} onClick={() => nextPage(`https://api.auteledoctor.com/api/v1/professional/backend/?organization=false&page=${i+1}&page_size=50`)}><a className="page-link" href="#" key={i}>{i+1}</a></li>
                        ))}
                        
                        <li className={agents_next_page?"page-item":"page-item disabled"} onClick={() => nextPage(agents_next_page)}><a className="page-link" href="#">Next</a></li>
                    </ul>:

                    <div className="row">
                      <div className="col-sm-12">
                          <div className="text-center">
                            {agents_loading &&
                              <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            }
                          </div>
                      </div>
                    </div>}
                    
                    </div>
                    
                      </div>
                    <div class="tab-pane" id="profile-1" role="tabpanel">
                    <div className="card-body">
                        <table className="table table-responsive-sm table-hover datatable">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th >Name</th>
                                <th>Phone Number</th>
                                <th>Email</th>
                                <th >Action</th>
                              </tr>
                            </thead>
                            <tbody>
                            
                          {users.map((user, index) => (
                            <tr key={user.id}>
                              <td>{user.id}</td>
                              <td>
                                <Link to={`/professional_profile?id=${user.id}`}>
                                {user.avatar &&
                                <img width="28" height="28" src={user.avatar} className="rounded-circle m-r-5" alt=""/>  
                                }
                                {!user.avatar &&
                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/>  
                                }
                                &nbsp;
                                {user.first_name} {user.last_name}&nbsp; 
                                {user.is_verified &&
                                  <img width="20" height="20" src="assets/images/verified.jpg" className="rounded-circle m-r-5" alt=""/> 
                                }
                                </Link>
                              </td>
                              
                              <td>{user.phone_number}</td>
                              
                              <td>{user.email}</td>
                             
                              {/* <td className="text-center">
                                  {doctor.organization && <span className="badge badge-primary">{doctor.organization.name}</span>}
                              </td> */}
                              
                              <td>
                                {user.user_type.includes('agent')?
                                <button className="btn btn-sm btn-pill btn-success" type="button">Agent</button>
                                :
                                <button data-toggle="modal" data-target={`#tag${user.id}`} className="btn btn-sm btn-pill btn-primary" type="button">Tag as Agent</button>
                                
                                }
                                <div id={`tag${user.id}`} className="modal fade delete-modal" role="dialog">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-body text-center">
                                            
                                                Are you sure want to tag {user.first_name} as an agent?
                                                <br/>
                                                <br/>
                                                {success &&
                                                    <div class="alert alert-success" role="alert">Successfully tagged <strong>{user.first_name}</strong> as an Agent.</div>
                                                }
                                                {error &&
                                                    <div class="alert alert-danger" role="alert">Error tagging <strong>{user.first_name}</strong> as an Agent.</div>
                                                }
                                                <br/>
                                                <div className="m-t-20">
                                                    <input hidden name="id" value={user.id} onChange={handleChange}></input>
                                                    <a href="#" className="btn btn-white" data-dismiss="modal">
                                                        Close
                                                    </a>
                                                    <button type="submit" className="btn btn-danger" onClick={() => handleSubmit(user.id, index)} disabled={loading || success}>
                                                        {loading?
                                                            <Fragment><i className="fa fa-spinner fa-pulse"></i> loading...</Fragment>:<Fragment>Tag as Agent</Fragment>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      { tableData.text === tableThemes.paginate.text && paginateCount > 0?
                      <ul className="pagination justify-content-center">
                        <li className={users_prev_page?"page-item":"page-item disabled"} onClick={() => nextPage(users_prev_page)}><a className="page-link" href="#" >Prev</a></li>
                        {[...Array(paginateCount)].map((page, i)=>(
                          <li className={page_number==i?"page-item active":"page-item"} onClick={() => nextPage(`https://api.auteledoctor.com/api/v1/professional/backend/?organization=false&page=${i+1}&page_size=50`)}><a className="page-link" href="#" key={i}>{i+1}</a></li>
                        ))}
                        
                        <li className={users_next_page?"page-item":"page-item disabled"} onClick={() => nextPage(users_next_page)}><a className="page-link" href="#">Next</a></li>
                    </ul>:

                    <div className="row">
                      <div className="col-sm-12">
                          <div className="text-center">
                            {users_loading &&
                              <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            }
                          </div>
                      </div>
                    </div>}
                      
                    
                    </div>
                    
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
        </div>
        </div>
      </div>
      </main>
      </div>
    </div>
    </div>
  );
}

function mapStateToProps({agents}){
  return {agents:agents.agents, agents_loading:agents.agents_loading, agents_next_page:agents.agents_next_page,
    users:agents.users, users_loading:agents.users_loading, users_next_page:agents.users_next_page,
    loading:agents.loading, success:agents.success, error:agents.error};
  
}
export default connect(mapStateToProps, {tag_agent, loadMoreAgents, loadMoreUsers, search_users_keyword, search_agents_keyword})(AgentsComponent);