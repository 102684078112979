import React, {useEffect, useState, Fragment} from 'react';
import Menu from '../../Menu/MenuComponent';
import Topnav from '../../Menu/TopnavComponent';
import {loadMoreLanguage, create_language, update_language, delete_language, search_keyword} from '../ducks/language_reducer';
import {connect} from 'react-redux';



function LanguageComponent({ fetchLanguage, languages = [], error, success, next_page, loadMoreLanguage, loading, create_language, update_language, delete_language, search_keyword}) {
    useEffect(() => {
        fetchLanguage();
      }, [fetchLanguage]);

      const [state, setState] = useState({});
      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
	  }
      function handleSubmit(e) {
        e.preventDefault();
        state.category = 'language'
        create_language(state)

      }
      
      
      function handleUpdate(id) {
        var name = document.getElementById("name"+ id +"").value
        var description = document.getElementById("description"+ id +"").value
        update_language({id:id, name:name, description:description, category:'language'})

      }

      function handleDelete(id) {
        delete_language(id)
      }


    //infinite scroll
    var language_page = true
    window.onscroll = () => {
      // Checks that the page has scrolled to the bottom
          if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
              if(next_page && !loading && language_page){
                loadMoreLanguage(next_page);
              }
          }
    };

    function handleSearch(e) {
        e.preventDefault();
            document.getElementById("loader").classList.remove("hide");
  
           
            search_keyword(state);
            
        } 
        
        function handleClearField(e){
          e.preventDefault();
          document.getElementById("keyword").value='';
          state.keyword = ''
          document.getElementById("loader").classList.remove("hide");
         
            search_keyword(state);
          
          }
  
  return (
    <div className="c-app c-legacy-theme c-no-layout-transition" >
    <Menu/>
    <div className="c-wrapper">
        <Topnav/>
        <div className="c-body">
            <main className="c-main">
                <div className="container-fluid">
                    <div className="fade-in">
                       

                        <div className="card">
                            <div className="card-header"> 
                                <div className="row">
                                <div className="col-md-3">
                                    <label>Keyword</label>
                                    <div className="input-group">
                                    <input type="text" className="form-control" name="keyword" onChange={handleChange} id="keyword"/>
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-2">
                                    <div className="form-group">
                                    <label><br/></label>
                                    <br/>
                                    <button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search <i className="fa fa-spinner fa-pulse hide" id="loader"></i></button>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                {error &&
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                
								<a className="text-center">{error}</a>
						    </div>
                        }
                                        <div className="form-group">
                                        <label><br/></label>
                                        <br/>
                                       
                                        <a href="#" className="btn btn btn-primary btn-rounded float-right" type="button" data-toggle="modal" data-target="#add_speciality"><i className="fa fa-plus"></i> Add Language</a>
                                            <div className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="add_speciality">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            Add Language
                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form id="speciality-form">
                                                                <div className="row">
                                                                    
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Name <span className="text-danger">*</span></label>
                                                                            <input className="form-control" type="text" name="name" onChange={handleChange} required/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Description<span className="text-danger">*</span></label>
                                                                            <textarea className="form-control" name="description" onChange={handleChange}></textarea>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    
                                                                </div>
                                                            </form>

                                                        </div>
                                                        <div class="modal-footer">
                                                            <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
                                                            <button class="btn btn-primary" type="button" onClick={handleSubmit} disabled={!(state.name && state.description)}>{loading?<Fragment>Loading <i className="fa fa-spinner fa-pulse"></i></Fragment>:<Fragment> Save</Fragment>}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
          
                            <div className="card-body">
                                <table className="table table-responsive-sm table-hover datatable">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Category</th>
                                            <th>Description</th>
                                            <th style={{width: "158px"}}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {languages.map(language => (
                                            <tr key={language.id}>
                                                <td>{language.name}</td>
                                                <td>{language.category}</td>
                                                <td>
                                                   {language.description}
                                                
                                                </td>
                                                <td>
                                                    <span>
                                                        <a  href="#" data-toggle="modal" data-target={`#update${language.id}`}><i className="fa fa-edit m-r-5"></i>&nbsp;Update</a>
                                                    </span>&nbsp;|&nbsp;
                                                    <span>
                                                        <a  href="#" data-toggle="modal" data-target={`#delete${language.id}`} style={{color:"red"}}><i className="fa fa-trash-o m-r-5"></i>&nbsp;Delete</a>
                                                    </span>
                                                
                                                    <div id={`delete${language.id}`} className="modal fade delete-modal" role="dialog">
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="modal-body text-center">
                                                                
                                                                    <h3>Are you sure want to delete {language.name} ?</h3>
                                                                    <div className="m-t-20">
                                                                        <input hidden name="id" value={language.id}></input>
                                                                        <a href="#" className="btn btn-white" data-dismiss="modal">
                                                                            Close
                                                                        </a>
                                                                        <button type="submit" data-dismiss="modal" className="btn btn-danger" onClick={() => handleDelete(language.id)}>
                                                                            Delete
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id={`update${language.id}`}>
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    Update Language
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <form id="speciality-form">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Name <span className="text-danger">*</span></label>
                                                                                    <input className="form-control" type="text" id={`name${language.id}`} name={`name${language.id}`}  required defaultValue={language.name}/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Description<span className="text-danger">*</span></label>
                                                                                    <textarea className="form-control" id={`description${language.id}`} name={`description${language.id}`}>{language.description}</textarea>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            
                                                                        </div>
                                                                    </form>

                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
                                                                    <button class="btn btn-primary" type="button" onClick={() => handleUpdate(language.id)}>{loading?<Fragment>Loading <i className="fa fa-spinner fa-pulse"></i></Fragment>:<Fragment> Update</Fragment>}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                     </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                               
            
           
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="text-center">
                                    {loading &&
                                        <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        </div>
    </div>
  );
}

function mapStateToProps({languages}){
    return {error:languages.error, success:languages.success, next_page:languages.next_page, loading:languages.loading};
    
  }
export default connect(mapStateToProps, {loadMoreLanguage, create_language, update_language, delete_language, search_keyword})(LanguageComponent);
