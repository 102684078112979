import axios from 'axios';
import Literals from '../../helpers/Literals'
import { authenticateRequest } from '../../helpers/utils';


export const fetchDashBoardCount = () => async dispatch => {
  dispatch({ type: 'FETCH_ALL_COUNT' });
  try {
    let professionals = await axios.get(`${Literals.BASE_API}professional/backend/?page=1&page_size=50`);
    let patients = await axios.get(`${Literals.BASE_API}patients/web/backend/?page=1&page_size=50`);
    let appointment = await axios.get(`${Literals.BASE_API}records/appointments/backend/?page=1&page_size=50`);
    let billing = await axios.get(`${Literals.BASE_API}web/backend/billing/?page=1&page_size=50`);
    let calls = await axios.get(`${Literals.BASE_API}records/call/backend/?page=1&page_size=50`);

    const count_data = {'professionals_count':professionals.data?.count || 0,
                        'patients_count':patients.data?.count || 0,
                        'appointments_count':appointment.data?.count || 0,
                        'billing_count':billing.data?.count || 0,
                        'calls_count':calls.data?.count || 0,
                       }

    console.log('count_data', count_data)
    dispatch({ type: 'FETCH_COUNT_SUCCESS', payload: count_data});
  } catch (e) {
    authenticateRequest(e)
    dispatch({ type: 'FETCH_COUNT_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};
