import React, {useEffect, useState, Fragment} from 'react';
import Menu from '../Menu/MenuComponent';
import Topnav from '../Menu/TopnavComponent';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { formatDatetime} from '../helpers/utils';
import {loadMoreBilling, search_keyword, export_billing} from './ducks/reducer';
import {loadMoreWebBilling, search_web_keyword, export_web_billing} from './ducks/web_reducer';




function BillingComponent({ fetchBilling, fetchWebBilling, billing = [], web_billing = [], error, web_error, success, web_success, search_keyword, search_web_keyword, next_page, web_next_page, loadMoreBilling, loadMoreWebBilling, loading, web_loading, export_billing, export_web_billing}) {
    useEffect(() => {
        fetchBilling(); fetchWebBilling();
      }, [fetchBilling, fetchWebBilling]);

      const [state, setState] = useState({is_complete:'', keyword:'', start_date:'', end_date:''});

      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
	  }

	  function handleSearch(e) {
		e.preventDefault();
		document.getElementById("loader").classList.remove("hide");
        if(tabState.tab=='app'){
        search_keyword(state);
        }else{
            search_web_keyword(state)
        }
	  } 

	  function handleClearField(e){
		e.preventDefault();
		document.getElementById("start_date").value='';
		document.getElementById("end_date").value='';
        document.getElementById("keyword").value='';
		state.start_date = ''
		state.end_date = ''
        state.keyword = ''
		document.getElementById("loader").classList.remove("hide");
        if(tabState.tab=='app'){
		search_keyword(state);
        }else{
            search_web_keyword(state)
        }
	}

    function handleExport() {
		document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
		if(tabState.tab=='app'){
        	export_billing(state);
		}else{
			export_web_billing(state);
		}

	  } 
	


    //infinite scroll
    var billing_page = true
    window.onscroll = () => {
      // Checks that the page has scrolled to the bottom
          if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            if(tabState.tab=='app'){
              if(next_page && !loading && billing_page){
                loadMoreBilling(next_page+'&start_date='+state.start_date+'&end_date='+state.end_date+'&organization=false&keyword='+state.keyword);
              }
            }else{
                if(web_next_page && !web_loading && billing_page){
                    loadMoreWebBilling(web_next_page+'&start_date='+state.start_date+'&end_date='+state.end_date+'&organization=true&keyword='+state.keyword);
                  }
            }
          }
    };
    const [tabState, setTabState] = useState({tab:'app'});
    function handleTabChange(tab) {
      setTabState({ tab:tab });
    }
  return (
    <div className="c-app c-legacy-theme c-no-layout-transition" >
    <Menu/>
    <div className="c-wrapper">
        <Topnav/>
        <div className="c-body">
            <main className="c-main">
                <div className="container-fluid">
                    <div className="fade-in">
                        <div className="row">
                            <div className="col-sm-4 col-3">
                            {tabState.tab== 'app' && <Fragment>
                                {success &&
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        {/* <span aria-hidden="true">×</span> */}
                                    </button>
                                    <a href={success} className="text-center"><i className="fa fa-download"></i> Click to Download</a>
                                </div>
                            }
                            {error &&
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        {/* <span aria-hidden="true">×</span> */}
                                    </button>
                                    <a className="text-center">{error}</a>
                                </div>
                            }
                            </Fragment>
                            }

                            {tabState.tab== 'web' && <Fragment>
                                {web_success &&
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        {/* <span aria-hidden="true">×</span> */}
                                    </button>
                                    <a href={web_success} className="text-center"><i className="fa fa-download"></i> Click to Download</a>
                                </div>
                            }
                            {web_error &&
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        {/* <span aria-hidden="true">×</span> */}
                                    </button>
                                    <a className="text-center">{web_error}</a>
                                </div>
                            }</Fragment>
                            }
                            </div>
                            
                        </div>
                       

                        <div className="card">
                            <div className="card-header"> 
                                <div className="row">
                                    
                                    <div className="col-md-5">
                                    <label>Start Date</label>
                                    <label className="offset-md-4"> End Date</label>
                                        <div className="input-group">
                                            <input type="date" className="form-control" id="start_date" name="start_date" onChange={handleChange} placeholder="YYYY-MM-DD" required pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"/>
                                            <input type="date" className="form-control" id="end_date" name="end_date" onChange={handleChange} placeholder="YYYY-MM-DD" required pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"/>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Keyword</label>
                                        <div className="input-group">
                                        <input type="text" className="form-control" name="keyword" onChange={handleChange} id="keyword"/>
                                            <div className="input-group-append">
                                                <button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label><br/></label>
                                            <br/>
                                            <button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search <i className="fa fa-spinner fa-pulse hide" id="loader"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label><br/></label>
                                            <br/>
                                            <button className="btn btn btn-primary btn-rounded float-right" onClick={handleExport} disabled={loading}>{loading?<Fragment>Loading <i className="fa fa-spinner fa-pulse"></i></Fragment>:<Fragment><i className="fa fa-file-excel-o"></i> Export</Fragment>}</button>
                                        </div>
                                    </div>
                                   
													
                                </div>
                                
                            </div>
                            <div class="row">
              <div class="col-md-12 mb-4">
                <div class="nav-tabs-boxed">
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" onClick={() => handleTabChange('app')}><a class="nav-link active" data-toggle="tab" href="#home-1" role="tab" aria-controls="home"><strong><i className="fa fa-mobile-phone"></i> App</strong></a></li>
                    <li class="nav-item" onClick={() => handleTabChange('web')}><a class="nav-link" data-toggle="tab" href="#profile-1" role="tab" aria-controls="profile"><strong><i className="fa fa-desktop"></i> Web</strong></a></li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane active" id="home-1" role="tabpanel">
                            <div className="card-body">
                                <table className="table table-responsive-sm table-hover datatable">
                                <thead>
                                    <tr>
                                        <th>Record Date</th>
                                        <th>Process Date</th>                                        
                                        <th>Patient</th>
                                        <th>Professional</th>
                                        <th className="text-center">Speciality</th>
                                        <th className="text-center">Paid Amount</th>
                                        <th className="text-center">Payment Status</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                {billing.map(payment => (
                                    <tr key={payment.id}>
                                        <td>{formatDatetime(payment.record_date)}</td>
                                        <td>{formatDatetime(payment.process_date)}</td>
                                        
                                        <td>
                                            {payment.appointment && <Fragment> {payment.appointment.patient && <Fragment>

                                            <Link to={`/patient_profile?id=${payment.appointment.patient.id}`}>
                                                {payment.appointment.patient.avatar && 
                                                <img width="28" height="28" src={payment.appointment.patient.avatar} className="rounded-circle m-r-5" alt=""/> 
                                                }
                                                {!payment.appointment.patient.avatar && 
                                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
                                                }
                                                {payment.appointment.patient.first_name} {payment.appointment.patient.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {payment.appointment.patient.id}</span>
                                            </Link>
                                            <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{payment.appointment.patient.email}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{payment.appointment.patient.phone_number}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{payment.appointment.patient.gender}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{payment.appointment.patient.date_of_birth}</a>
                                                </div>
                                                </Fragment>
                                            }
                                            </Fragment>
                                            }
                                            
                                        </td>
                                        <td>
                                            {payment.appointment && <Fragment>
                                                {payment.appointment.professional && <Fragment>
                                            <Link to={`/professional_profile?id=${payment.appointment.professional.id}`}>
                                                {payment.appointment.professional.avatar && 
                                                <img width="28" height="28" src={payment.appointment.professional.avatar} className="rounded-circle m-r-5" alt=""/> 
                                                }
                                                {!payment.appointment.professional.avatar && 
                                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
                                                }
                                                {payment.appointment.professional.first_name} {payment.appointment.professional.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {payment.appointment.professional.id}</span>
                                            </Link>
                                            <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{payment.appointment.professional.email}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{payment.appointment.professional.phone_number}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{payment.appointment.professional.gender}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{payment.appointment.professional.date_of_birth}</a>
                                                </div>
                                                </Fragment>}
                                            </Fragment>
                                            }
                                            
                                        </td>
                                        <td className="text-center">
                                        {payment.appointment && <Fragment>
                                            {payment.appointment.speciality &&
                                                <Fragment>
                                                    {payment.appointment.speciality.name}
                                                </Fragment>}
                                                </Fragment>}
                                        </td>
                                        <td className="text-center">{payment.currency} {payment.amount}</td>
                                        <td className="text-center">
                                            {payment.appointment &&
                                                <Fragment>
											        {payment.appointment.bill_paid && <span className="badge badge-success">Paid</span>}
                                                </Fragment>
                                            }
										</td>
                                        {/* <td>{payment.transaction_id}</td> */}
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="text-center">
                                    {loading &&
                                        <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="profile-1" role="tabpanel">
                        <div className="card-body">
                                <table className="table table-responsive-sm table-hover datatable">
                                <thead>
                                    <tr>
                                        <th>Record Date</th>
                                        <th>Process Date</th>                                        
                                        <th>Patient</th>
                                        <th>Professional</th>
                                        <th>Speciality</th>
                                        <th>Paid Amount</th>
                                        <th>Payment Status</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                {web_billing.map(payment => (
                                    <tr key={payment.id}>
                                        <td>{formatDatetime(payment.record_date)}</td>
                                        <td>{formatDatetime(payment.process_date)}</td>
                                        
                                        <td>
                                            {payment.appointment && <Fragment> {payment.appointment.patient && <Fragment>

                                            <Link to={`/patient_profile?id=${payment.appointment.patient.id}`}>
                                                {payment.appointment.patient.avatar && 
                                                <img width="28" height="28" src={payment.appointment.patient.avatar} className="rounded-circle m-r-5" alt=""/> 
                                                }
                                                {!payment.appointment.patient.avatar && 
                                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
                                                }
                                                {payment.appointment.patient.first_name} {payment.appointment.patient.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {payment.appointment.patient.id}</span>
                                            </Link>
                                            <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{payment.appointment.patient.email}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{payment.appointment.patient.phone_number}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{payment.appointment.patient.gender}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{payment.appointment.patient.date_of_birth}</a>
                                                </div>
                                                </Fragment>
                                            }
                                            </Fragment>
                                            }
                                            
                                        </td>
                                        <td>
                                            {payment.appointment && <Fragment>
                                                {payment.appointment.professional && <Fragment>
                                            <Link to={`/professional_profile?id=${payment.appointment.professional.id}`}>
                                                {payment.appointment.professional.avatar && 
                                                <img width="28" height="28" src={payment.appointment.professional.avatar} className="rounded-circle m-r-5" alt=""/> 
                                                }
                                                {!payment.appointment.professional.avatar && 
                                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
                                                }
                                                {payment.appointment.professional.first_name} {payment.appointment.professional.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {payment.appointment.professional.id}</span>
                                            </Link>
                                            <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{payment.appointment.professional.email}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{payment.appointment.professional.phone_number}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{payment.appointment.professional.gender}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{payment.appointment.professional.date_of_birth}</a>
                                                </div>
                                                </Fragment>}
                                            </Fragment>
                                            }
                                            
                                        </td>
                                        <td className="text-center">
                                        {payment.appointment && <Fragment>
                                            {payment.appointment.speciality &&
                                                <Fragment>
                                                   {payment.appointment.speciality.name}
                                                </Fragment>}
                                                </Fragment>}
                                        </td>
                                        <td>{payment.currency} {payment.amount}</td>
                                        <td className="text-center">
                                            {payment.appointment &&
                                                <Fragment>
											        {payment.appointment.bill_paid && <span className="badge badge-success">Paid</span>}
                                                </Fragment>
                                            }
										</td>
                                        {/* <td>{payment.transaction_id}</td> */}
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="text-center">
                                    {loading &&
                                        <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>


                        </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
                </div>
                </div>
            </main>
        </div>
        </div>
    </div>
  );
}

function mapStateToProps({billing, web_billing}){
    return {error:billing.error, success:billing.success, next_page:billing.next_page, loading:billing.loading,
        web_error:web_billing.web_error, web_success:web_billing.web_success, web_next_page:web_billing.web_next_page, web_loading:web_billing.web_loading};
    
  }
export default connect(mapStateToProps, {loadMoreBilling, search_keyword, loadMoreWebBilling, search_web_keyword, export_billing, export_web_billing})(BillingComponent);
