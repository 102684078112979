import axios from 'axios'
import Literals from '../../helpers/Literals';
import { authenticateRequest } from '../../helpers/utils';


const INITIAL_STATE = {
  loading: false,
  schedules: [],
  error: null,
};

function schedules(state = INITIAL_STATE, action) {
  const { type, payload, next_page} = action;
  switch (type) {
    case 'FETCH_SCHEDULES':
      return { ...state, loading: true };
    case 'FETCH_SCHEDULES_SUCCESS':
      return { ...state, loading: false, schedules: payload, success:null, error:null, next_page:next_page};
    case 'FETCH_SCHEDULES_FAILED':
      return { ...state, loading: false, error: payload };

    case 'SEARCH_SCHEDULES_SUCCESS':
      return { ...state, loading: false, schedules: payload, success:null, error:null};
    case 'SEARCH_SCHEDULES_FAILURE':
      return { ...state, loading: false, error: payload };

    case 'DELETE_APPOINTMENT_SUCCESS':
      return { ...state, loading: false, schedule: payload, success:'Deleted successfully', error:null};
    case 'DELETE_APPOINTMENT_FAILED':
      return { ...state, loading: false, error: payload, success:null};

    case 'EXPORT_SCHEDULES':
        return { ...state, loading: true, success:null, error:null};
    case 'EXPORT_SCHEDULES_SUCCESS':
      return { ...state, loading: false, schedule: payload, success:payload.results.url, error:null};
    case 'EXPORT_SCHEDULES_FAILED':
      return { ...state, loading: false, error: payload};

    case 'LOAD_MORE_SCHEDULES_SUCCESS':
      var schedules = state.schedules.concat(payload)
      return { ...state, loading: false, schedules: schedules, error: null, success: null, next_page:next_page};
    case 'LOAD_MORE_SCHEDULES_FAILURE':
      return { ...state, loading: false};

    default:
      return state;
  }
}

export const search_keyword = data => async dispatch => {
  try {
    var keyword = data.keyword?data.keyword:'';
    var start_date = data.start_date?data.start_date:'';

    
   
    let response = await axios.get(`${Literals.BASE_API}records/appointments/?professional&page=1&page_size=50&keyword=${keyword}&start_date=${start_date}`);
    dispatch({ type: 'SEARCH_SCHEDULES_SUCCESS', payload: response.data.results});
  } catch (error) {
    authenticateRequest(error)
    dispatch({
      type: 'SEARCH_SCHEDULES_FAILURE',
      payload: error.response.data.details,
    });
  }
};


export const export_appointment = () => async dispatch => {
  dispatch({ type: 'EXPORT_SCHEDULES', success:null, error:null });
  try {
    let appointment = await axios.get(`${Literals.BASE_API}records/appointments/backend/?professional&export`);
    dispatch({ type: 'EXPORT_SCHEDULES_SUCCESS', payload: appointment.data});
  } catch (e){
    dispatch({
      type: 'EXPORT_SCHEDULES_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const loadMoreSchedules = api => async dispatch => {
  dispatch({ type: 'FETCH_SCHEDULES' });
  try {

    const { data } = await axios.get(api);

    dispatch({ type: 'LOAD_MORE_SCHEDULES_SUCCESS', payload: data.results, next_page:data.next});
  } catch (e) {
    authenticateRequest(e)
    dispatch({ type: 'LOAD_MORE_SCHEDULES_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};


export default schedules;
