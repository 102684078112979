import { connect } from 'react-redux';
import { fetchFacility } from './duck/actions';
import FacilityComponent from './FacilityPage';

const mapStateToProps = ({ facilities: { facilities } }) => {
  return {
    facilities,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFacility: () => dispatch(fetchFacility()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FacilityComponent);
