import axios from 'axios';
import Literals from '../../helpers/Literals';
import { authenticateRequest } from '../../helpers/utils';


export const fetchSchedules = () => async dispatch => {
  dispatch({ type: 'FETCH_SCHEDULES' });
  try {
    //permissions
    const user = JSON.parse(window.localStorage.getItem('user'))
    
    var {data} = await axios.get(`${Literals.BASE_API}records/appointments/?professional&page=1&page_size=50`);
    dispatch({ type: 'FETCH_SCHEDULES_SUCCESS', payload: data.results, next_page:data.next});
  } catch (e) {
    authenticateRequest(e)
    dispatch({ type: 'FETCH_SCHEDULES_FAILED', payload: e.response });
  }
};

