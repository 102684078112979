import axios from 'axios';
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
  loading: false,
  error: null,
  success:null,
  subscribed_patients:[]
};

function subscribed_patients(state = INITIAL_STATE, action) {
    const { type, payload, next_page} = action;
    switch (type) {
      case 'FETCH_SUBSCRIBED_PATIENTS':
        return { ...state, loading: true };
      case 'FETCH_SUBSCRIBED_PATIENTS_SUCCESS':
        return { ...state, loading: false, subscribed_patients: payload, error:null, success:null};
      case 'FETCH_SUBSCRIBED_PATIENTS_FAILURE':
        return { ...state, loading: false, error: payload };
      case 'LOAD_MORE_SUBSCRIBED_PATIENTS_SUCCESS':
        var subscribed_patients = state.subscribed_patients.concat(payload)
        return { ...state, loading: false, subscribed_patients: subscribed_patients, success:null, error:null, next_page:next_page};
      case 'LOAD_MORE_SUBSCRIBED_PATIENTS_FAILED':
        return { ...state, loading: false, error: payload, success:null};
      default:
        return state;
    }
  }

  export const loadMoreSubscribedPatients = api => async dispatch => {
    dispatch({ type: 'FETCH_SUBSCRIBED_DOCTORS' });
    try {
      const { data } = await axios.get(api);
  
      dispatch({ type: 'LOAD_MORE_SUBSCRIBED_DOCTORS_SUCCESS', payload: data.results, next_page:data.next});
    } catch (e) {
      dispatch({ type: 'LOAD_MORE_SUBSCRIBED_DOCTORS_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
    }
  };

  export default subscribed_patients;
