const INITIAL_STATE = {
  loading: false,
  admin_permissions: {},
  error: null,
};

function admin_permissions(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
      case 'GET_PERMISSIONS':
        return { ...state, loading: true };
      case 'GET_PERMISSIONS_SUCCESS':
          return { ...state, loading: false, admin_permissions:payload, success: true, error: null, };
      case 'GET_PERMISSIONS_FAILURE':
            return { ...state, loading: false, error: payload, success: false};
      default:
        return state;
    }
  }

  export default admin_permissions;
