import { connect } from 'react-redux';
import { fetchAppointments, fetchWebAppointments } from './duck/actions';
import AppointmentsComponent from './AppointmentsPage';

const mapStateToProps = ({ appointments: { appointments }, web_appointments: { web_appointments } }) => {
  return {
    appointments,web_appointments
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAppointments: () => dispatch(fetchAppointments()),
    fetchWebAppointments: () => dispatch(fetchWebAppointments()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsComponent);
