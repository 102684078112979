import React, {useEffect, useState} from 'react';
import Menu from '../Menu/MenuComponent'
import Topnav from '../Menu/TopnavComponent'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {export_appointment} from '../Appointments/duck/reducer';
import {search_keyword, loadMoreSchedules} from './duck/reducer';
import { formatDatetime } from '../helpers/utils';
import { Fragment } from 'react';


function SchedulesComponent({ fetchSchedules, schedules = [], export_appointment, search_keyword, error, success, loadMoreSchedules, next_page, loading}) {
    useEffect(() => {
        fetchSchedules();
	}, [fetchSchedules]);
	
	const [state, setState] = useState({});

    function handleExport(id) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        export_appointment(id);

      } 
      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
      }
      function handleSearch(e) {
        e.preventDefault();
        search_keyword(state);

    } 
    function handleClearField(e){
		e.preventDefault();
		document.getElementById("start_date").value='';
		document.getElementById("keyword").value='';
		state.keyword = ''
		state.start_date = ''
		search_keyword(state);
    }
    
    

    
    //infinite scroll
	window.onscroll = () => {
		// Checks that the page has scrolled to the bottom
			if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
				if(next_page && !loading){
					loadMoreSchedules(next_page);
				}
			}
		};

  return (
    <div class="c-app c-legacy-theme c-no-layout-transition" >
    <Menu/>
    <div class="c-wrapper">
        <Topnav/>
        <div class="c-body">
            <main class="c-main">
                <div class="container-fluid">
                <div class="fade-in">
                    
                <div className="row">
                    <div className="col-sm-4 col-3">
                       
                        {success &&
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    {/* <span aria-hidden="true">×</span> */}
                                </button>
                                <a href={success} className="text-center"><i className="fa fa-download"></i> Click to Download</a>
                            </div>
                        }
                        {error &&
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
									<span aria-hidden="true">×</span>
								</button>
								<a className="text-center">{error}</a>
						    </div>
                        }
                    </div>
                   
                </div>
				<div className="row">
					<div className="col-md-12">
                    <div class="card">
                                            <div class="card-header"> 
                    <div className="row">
							<div className="col-md-3">
								<div className="form-group">
									<label>Keyword</label>
									<input type="text" className="form-control" name="keyword" id="keyword" onChange={handleChange} />
								</div>
							</div>
                            <div className="col-md-3">
                            <label>Date</label>
                                <div className="input-group">
                                    <input type="date" className="form-control" id="start_date" name="start_date" onChange={handleChange} placeholder="YYYY-MM-DD" required pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"/>
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
                                    </div>
                                </div>
                            </div>
							<div className="col-md-2">
								<div className="form-group">
									<label><br/></label>
									<br/>
									<button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search</button>
								</div>
							</div>
                            <div className="col-md-4">
                                <button className="btn btn btn-primary btn-rounded float-right" onClick={handleExport} disabled={loading}>{loading?<Fragment>Loading <i className="fa fa-spinner fa-pulse"></i></Fragment>:<Fragment><i className="fa fa-file-excel-o"></i> Export</Fragment>}</button>
                            </div>
						</div>
                        </div>
                        <div class="card-body">
						<div className="table-responsive">
							<table className="table table-border table-striped custom-table mb-0">
								<thead>
									<tr> 
                                        <th>Date</th>
										<th>Doctor Name</th>
                                        <th>ID</th>
                                        <th>Patient Name</th>
                                        <th>ID</th>
                                        <th>Bill Paid</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Completed</th>
                                        <th className="text-center">Facility</th>
									</tr>
								</thead>
								<tbody>
                                {schedules.map(schedule => (
									<tr key={schedule.id}>
                                        <td>{formatDatetime(schedule.start_date)}</td>
										<td>
                                            {schedule.professional && <div> 
                                                <Link to={`/doctor_profile?id=${schedule.professional.id}`}>
                                                {schedule.professional.avatar && 
                                                    <img width="28" height="28" src={schedule.professional.avatar} className="rounded-circle m-r-5" alt=""/> 
                                                }
                                                {!schedule.professional.avatar && 
                                                    <img width="28" height="28" src="assets/img/user.jpg" className="rounded-circle m-r-5" alt=""/> 
                                                }
                                               
                                                {schedule.professional.title} {schedule.professional.first_name} {schedule.professional.last_name}&nbsp;&nbsp;
                                                </Link>
                                                
                                                <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a className="dropdown-item" ><i className="fa fa-envelope"></i> {schedule.professional.email}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-phone"></i> {schedule.professional.phone_number}</a>
                                                </div>
                                                </div>
                                            }
                                        </td>
                                        <td>{schedule.professional.id}</td>
										<td>
                                            <Link to={`/patient_profile?id=${schedule.patient.id}`}>
                                                {schedule.patient.avatar && 
                                                <img width="28" height="28" src={schedule.patient.avatar} className="rounded-circle m-r-5" alt=""/> 
                                                }
                                                {!schedule.patient.avatar && 
                                                <img width="28" height="28" src="assets/img/user.jpg" className="rounded-circle m-r-5" alt=""/> 
                                                }&nbsp;
                                                {schedule.patient.first_name} {schedule.patient.last_name}&nbsp;&nbsp;
                                            </Link>
                                            <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a className="dropdown-item" ><i className="fa fa-envelope"></i> {schedule.patient.email}</a>
                                                    <a className="dropdown-item" ><i className="fa fa-phone"></i> {schedule.patient.phone_number}</a>
                                                </div>
                                        </td>
                                        <td>{schedule.patient.id}</td>
										<td>
                                            {schedule.bill_paid==false &&
                                            <div className="dropdown action-label">
                                                <a className="custom-badge status-red dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                Not Paid
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a className="dropdown-item" href={`mailto:${schedule.patient.email}?subject=Payment%20link%20to%20make%20payment&body=${schedule.checkout_url}`}>
                                                    <kbd>{schedule.checkout_url}</kbd> 
                                                    <button className="custom-badge status-green">Email payment link</button></a>
                                                    <a className="dropdown-item" onClick={() =>  navigator.clipboard.writeText(''+ `${schedule.checkout_url}` +'')}><button className="custom-badge status-green">Copy link</button></a>
                                                </div>
                                            </div>
                                            }
                                            {schedule.bill_paid==true && <span className="badge badge-info">Paid</span>}
                                        </td>
                                        <td className="text-center">
                                            {schedule.payment_option=='nhis' && schedule.bill_paid==false &&
                                                <div>
                                                    
                                                    <span class="badge badge-warning"><i className="fa fa-clock-o"></i> Pending Approval</span>
                                                </div>
                                            }
                                            {['nhis', 'card', 'mobile_money'].includes(schedule.payment_option) && schedule.bill_paid==true &&
                                                <Fragment>
                                                    {schedule.professional &&
                                                        <div className="text-center"><span class="badge badge-success"><i className="fa fa-random"></i> Assigned</span></div>
                                                    }
                                                    {!schedule.professional &&
                                                        <div className="text-center"><span class="badge badge-success"><i className="fa fa-check"></i> Approved</span></div>
                                                    }
                                                </Fragment>
                                                // <span className="custom-badge status-grey"><i className="fa fa-remove"></i> Not Approved</span>
                                            }
                                            {['card', 'mobile_money'].includes(schedule.payment_option) && schedule.bill_paid==false &&
                                                <div className="text-center"><span class="badge badge-danger"><i className="fa fa-warning"></i> Payment not completed</span></div>
                                            }
                                        </td>
                                        <td className="text-center">{schedule.is_complete && <span class="badge badge-dark">Yes</span>}
                                            {!schedule.is_complete && <span class="badge badge-light">No</span>}
                                        
                                        </td>
                                        <td className="text-center">
                                            {schedule.institution && <span class="badge badge-primary">{schedule.institution.name}</span>}
                                        </td>
                                    </tr>
                                ))}
								</tbody>
							</table>
						</div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="text-center">
                                    {loading &&
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                
            </div>
        </div>
        </main>
        </div>
        </div>
        </div>
    
  );
}

function mapStateToProps({schedules}){
    return {error:schedules.error, success:schedules.success, next_page:schedules.next_page, loading:schedules.loading};
    
  }

export default connect(mapStateToProps, { export_appointment, search_keyword, loadMoreSchedules})(SchedulesComponent);

