import { connect } from 'react-redux';
import { fetchSchedules } from './duck/actions';
import SchedulesComponent from './SchedulesPage';

const mapStateToProps = ({ schedules: { schedules } }) => {
  return {
    schedules,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSchedules: () => dispatch(fetchSchedules()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesComponent);


