import axios from 'axios';
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
  loading: false,
  error: null,
};

function doctor_profile(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
      case 'FETCH_DOCTOR_PROFILE':
        return { ...state, loading: true };
      case 'FETCH_DOCTOR_PROFILE_SUCCESS':
        return { ...state, loading: false, doctor_profile: payload, error:null, success:null};
      case 'FETCH_DOCTOR_PROFILE_FAILURE':
        return { ...state, loading: false, error: payload };
      case 'UPDATE_DOCTOR_SUCCESS':
        return { ...state, loading: false, doctor: payload, error:null, success:true};
      case 'UPDATE_DOCTOR_FAILED':
        return { ...state, loading: false, error: payload, success:null};
      default:
        return state;
    }
  }

  export const update_doctor = data => async dispatch => {
    try {
  
      
  
      var doctor_id = data.doctor_id
      var doctor_data = data.doctor_data
    
    // var doctor_data = data.doctor_data
    var admin_permissions = data.admin_permissions
    
    if(doctor_data.speciality){
      if(!Array.isArray(doctor_data.speciality)){
    const speciality = doctor_data.speciality.split(',')
    doctor_data['speciality'] = speciality ;
    }
  }else{
    doctor_data['speciality'] = [];
  }
    
    if(doctor_data.department_ids){
      if(!Array.isArray(doctor_data.department_ids)){
      console.log('data_department...',doctor_data.department_ids)
      doctor_data['department_ids'] = doctor_data.department_ids.split(',')
      }
    
    }else{
      doctor_data['department_ids'] =[]
    }
    if(doctor_data.languages){
      if(!Array.isArray(doctor_data.languages)){
      doctor_data['languages'] = doctor_data.languages.split(',')
      }
    }else{
      doctor_data['languages'] = []

    }
    if(doctor_data.image){

      if (doctor_data.image.files.length > 0){

        console.log('Processing image...')
      const formData = new FormData()
      formData.append('avatar', doctor_data.image.files[0], doctor_data.image.files[0].name)
      formData.append('first_name', doctor_data.first_name || '');
      formData.append('last_name', doctor_data.last_name || '');
      // formData.append('username', doctor_data.username || '');
      formData.append('email', doctor_data.email || '');
      // formData.append('password', doctor_data.password || '');
      if(doctor_data.date_of_birth){
        formData.append('date_of_birth', doctor_data.date_of_birth);
      }
      formData.append('gender', doctor_data.gender || '');
      formData.append('phone_number', doctor_data.phone_number || '');
      formData.append('phone_number_2', doctor_data.phone_number_2 || '');
      formData.append('region', doctor_data.region || '');
      formData.append('area', doctor_data.area || '');
      formData.append('languages', JSON.stringify(doctor_data.languages));
      formData.append('national_id_number', doctor_data.national_id_number || '');
      formData.append('practice_number', doctor_data.practice_number || '');
      formData.append('speciality', JSON.stringify(doctor_data.speciality));
      formData.append('department_ids', JSON.stringify(doctor_data.department_ids));

      var doctor_data = formData

      }
      delete doctor_data.image
    }
      doctor_data['country']='Ghana';
         
      let doctor = await axios.put(`${Literals.BASE_API}professional/backend/${doctor_id}/`, doctor_data);
  
  
      //update as admin 
      doctor_data['password'] = null
      let admin = await axios.put(`${Literals.BASE_API}users/backend/${doctor_id}/`, doctor_data);
  
  
      // set user permissions
      let admin_access = await axios.put(`${Literals.BASE_API}users/backend/config/?send_reminders&user_id=${doctor_id}`, admin_permissions);
  
  
      dispatch({ type: 'UPDATE_DOCTOR_SUCCESS', payload: doctor.data, success:true});
    } catch (e) {
      dispatch({
        type: 'UPDATE_DOCTOR_FAILED',
        payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
      });
    }
  };
  
  

  export default doctor_profile;
