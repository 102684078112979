import { connect } from 'react-redux';
import { fetchAccounts } from './duck/actions';
import AccountsComponent from './AdminsPage';

const mapStateToProps = ({ accounts: { accounts } }) => {
  return {
    accounts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAccounts: () => dispatch(fetchAccounts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsComponent);
