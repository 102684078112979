import React, { useEffect, useState, Fragment} from 'react';
import Menu from '../Menu/MenuComponent';
import Topnav from '../Menu/TopnavComponent';
import { Link } from 'react-router-dom';
import { search_keyword,loadMorePatients} from './duck/reducer';
import { search_web_keyword,loadMoreWebPatients} from './duck/web_patient_reducer';
import { loadMoreSubscribedPatients } from "./duck/subscribed_reducer";
import {connect} from 'react-redux';
import { formatDatetime } from '../helpers/utils';


function PatientsComponent({ fetchPatients, fetchWebPatients, patients = [], web_patients = [], search_keyword, search_web_keyword, error, success, next_page, web_next_page, loadMorePatients, loadMoreWebPatients, loading, web_loading,
  fetchSubscribedPatients, subscribed_patients=[], subscribed_error, subscribed_success, subscribed_next_page, subscribed_loading, loadMoreSubscribedPatients

}) {
  useEffect(() => {
    fetchPatients(); fetchWebPatients(); fetchSubscribedPatients();
  }, [fetchPatients, fetchWebPatients, fetchSubscribedPatients]);

  const [state, setState] = useState({keyword:''});
  
  
  function handleChange({ target }) {
    setState({ ...state, [target.name]: target.value });
}

function handleSearch(e) {
  e.preventDefault();
  document.getElementById("loader").classList.remove("hide");
    if(tabState.tab=='app'){
      search_keyword(state);
    }else{
      search_web_keyword(state)
    }
  } 

  
    function handleClearField(e){
      e.preventDefault();
      document.getElementById("loader").classList.remove("hide");
      document.getElementById("keyword").value='';
      state.keyword = ''
        if(tabState.tab=='app'){
          search_keyword(state);
        }else{
          search_web_keyword(state)
        }
      }


   //permissions
   const admin_access = JSON.parse(localStorage.getItem('permissions'))?JSON.parse(localStorage.getItem('permissions')):{permissions:null};
   if(admin_access.permissions){
      
       var patients_write = admin_access.permissions.patients.write
   }else{
      
       var patients_write = true
   }

   //infinite scroll
   window.onscroll = () => {
    // Checks that the page has scrolled to the bottom
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            if(tabState.tab=='app'){
              if(next_page && !loading){
                loadMorePatients(next_page+'&organization=false');
              }
            }else if (tabState.tab=='web'){
              if(web_next_page && !web_loading){
                loadMoreWebPatients(web_next_page+'&organization=true');
              }
            } else{
              if(subscribed_next_page && !subscribed_loading){
                loadMoreSubscribedPatients(subscribed_next_page+'&organization=false')
              }
            } 
        }
  };

  const [tabState, setTabState] = useState({tab:'app'});
      function handleTabChange(tab) {
        setTabState({ tab:tab });
	  }
  return (
    <div className="c-app c-legacy-theme c-no-layout-transition" >
    <Menu/>
    <div className="c-wrapper">
        <Topnav/>
        <div className="c-body">
            <main className="c-main">
                <div className="container-fluid">
                    <div className="fade-in">
                    
               
              
          <div className="row">

            {/* <div className="col-md-6">
              {error &&
                  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                    <a className="text-center">{error}</a>
                    </div>
                            }
                            {success &&
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                    <a className="text-center">Deleted successfully</a>
                    </div>
                }
             </div> */}

            <div className="col-md-12">
            <div className="card">
          <div className="card-header"> 
              <div className="row">
                <div className="col-md-3">
                  <label>Keyword</label>
                    <div className="input-group">
                    <input type="text" className="form-control" name="keyword" onChange={handleChange} id="keyword"/>
                        <div className="input-group-append">
                            <button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
                        </div>
                    </div>
                </div>
                
                <div className="col-md-2">
                  <div className="form-group">
                    <label><br/></label>
                    <br/>
                    <button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search <i className="fa fa-spinner fa-pulse hide" id="loader"></i></button>
                  </div>
                </div>
                 
              </div>
              </div>

              <div class="row">
              <div class="col-md-12 mb-4">
                <div class="nav-tabs-boxed">
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" onClick={() => handleTabChange('app')}><a class="nav-link active" data-toggle="tab" href="#home-1" role="tab" aria-controls="home"><strong><i className="fa fa-mobile-phone"></i> App</strong></a></li>
                    <li class="nav-item" onClick={() => handleTabChange('web')}><a class="nav-link" data-toggle="tab" href="#profile-1" role="tab" aria-controls="profile"><strong><i className="fa fa-desktop"></i> Web</strong></a></li>
                    <li class="nav-item" onClick={() => handleTabChange('subscribed')}><a class="nav-link" data-toggle="tab" href="#subscribed-1" role="tab" aria-controls="subscribed"><strong><i className="fa fa-ticket"></i> Subscribed</strong></a></li>
                    
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane active" id="home-1" role="tabpanel">
              <div className="card-body"> 
              <div className="table-responsive">
                <table className="table table-border table-striped custom-table datatable mb-0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Gender</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      <th>Date Created</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {patients.map(patient => (
                      <tr key={patient.id}>
                        <td><Link to={`/patient_profile?id=${patient.id}`}>{patient.id}</Link></td>
                        <td>
                          <Link to={`/patient_profile?id=${patient.id}`}>
                          {patient.avatar &&
                            <img width="28" height="28" src={patient.avatar} className="rounded-circle m-r-5" alt="" />
                          }
                          {!patient.avatar &&
                            <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt="" />
                          }
                          &nbsp;{patient.first_name} {patient.last_name}
                          </Link>
                        </td>
                        <td>{patient.gender}</td>
                        <td>{patient.phone_number}</td>
                        <td>{patient.email}</td>
                        <td><span className="badge badge-pill badge-primary">{formatDatetime(patient.created)}</span></td>
                        <td className="text-center">
                          <div className="dropdown dropdown-action">
                            <a
                              href="#"
                              className=""
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa fa-ellipsis-v"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                            <Link to={`/patient_profile?id=${patient.id}`} className="dropdown-item" >
                                <i className="fa fa-user m-r-5"></i>&nbsp;Profile
                              </Link>
                              
                            </div>
                          </div>
                        
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              </div>
              <div className="row">
              <div className="col-sm-12">
                  <div className="text-center">
                      {loading &&
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      }
                  </div>
              </div>
          </div>
          </div>
          <div class="tab-pane" id="profile-1" role="tabpanel">
              <div className="card-body">
              <div className="table-responsive">
                <table className="table table-border table-striped custom-table datatable mb-0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Gender</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      <th>Date Created</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {web_patients.map(patient => (
                      <tr key={patient.id}>
                        <td><Link to={`/patient_profile?id=${patient.id}`}>{patient.id}</Link></td>
                        <td>
                          <Link to={`/patient_profile?id=${patient.id}`}>
                          {patient.avatar &&
                            <img width="28" height="28" src={patient.avatar} className="rounded-circle m-r-5" alt="" />
                          }
                          {!patient.avatar &&
                            <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt="" />
                          }
                          &nbsp;{patient.first_name} {patient.last_name}
                          </Link>
                        </td>
                        <td>{patient.gender}</td>
                        <td>{patient.phone_number}</td>
                        <td>{patient.email}</td>
                        <td><span className="badge badge-pill badge-primary">{formatDatetime(patient.created)}</span></td>
                        <td className="text-center">
                          <div className="dropdown dropdown-action">
                            <a
                              href="#"
                              className=""
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa fa-ellipsis-v"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                            <Link to={`/patient_profile?id=${patient.id}`} className="dropdown-item" >
                                <i className="fa fa-user m-r-5"></i>&nbsp;Profile
                              </Link>
                              
                            </div>
                          </div>
                        
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="row">
              <div className="col-sm-12">
                  <div className="text-center">
                      {web_loading &&
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      }
                  </div>
              </div>
          </div>
              
              </div>
          </div>
          <div class="tab-pane" id="subscribed-1" role="tabpanel">
                      <div className="card-body">
                      <table className="table table-responsive-sm table-hover datatable">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Patient Name</th>
                                <th className="text-center">Package</th>
                                <th className="text-center">Duration</th>
                                <th className="text-center">Frequency</th>
                                <th className="text-center">Paid</th>
                              </tr>
                            </thead>
                            <tbody>
                          {subscribed_patients.map((patient, index) => (
                            <tr key={patient.id}>
                              <td>{patient.id}</td>
                              <td >
                                <Link to={`/professional_profile?id=${patient.id}`}>
                                {patient.avatar &&
                                <img width="28" height="28" src={patient.avatar} className="rounded-circle m-r-5" alt=""/>  
                                }
                                {!patient.avatar &&
                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/>  
                                }
                                &nbsp;
                                {patient.first_name} {patient.last_name}&nbsp; 
                                {patient.is_verified &&
                                  <img width="20" height="20" src="assets/images/verified.jpg" className="rounded-circle m-r-5" alt=""/> 
                                }
                                </Link>
                              </td>
                              
                              <td className="text-center">{patient.subscription[0]?.package?.name}</td>
                              <td className="text-center">{patient.subscription[0]?.package?.duration} Months</td>
                              <td className="text-center">{patient.subscription[0]?.package?.frequency}</td>
                              
                              <td className="text-center">{patient.subscription[0]?.billing?.currency} {patient.subscription[0]?.billing?.amount}</td>
                            
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      

                    <div className="row">
                      <div className="col-sm-12">
                          <div className="text-center">
                            {subscribed_loading &&
                              <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            }
                          </div>
                      </div>
                    </div>

                      </div>
                    </div>
          </div>
          </div>
          </div>
          </div>
            </div>
          </div>
          
        </div>
        </div>
        </div>
      </main>
      </div>
      </div>
    </div>
  );
}
function mapStateToProps({patients, web_patients, subscribed_patients}){
  return {error:patients.error, success:patients.success, next_page:patients.next_page, loading:patients.loading,
          web_error:web_patients.web_error, web_success:web_patients.web_success, web_next_page:web_patients.web_next_page, web_loading:web_patients.web_loading,
        
          subscribed_error:subscribed_patients.error, subscribed_success:subscribed_patients.success, subscribed_next_page:subscribed_patients.next_page, subscribed_loading:subscribed_patients.loading
        };
  
}
export default connect(mapStateToProps, {search_keyword, search_web_keyword, loadMorePatients, loadMoreWebPatients, loadMoreSubscribedPatients})(PatientsComponent);
