import React, {useState} from 'react';
import Menu from '../../Menu/MenuComponent'
import Topnav from '../../Menu/TopnavComponent'
import { edit_profile, change_password } from './duck/reducer';
import { connect } from "react-redux";


function EditProfileComponent({edit_profile, change_password, success, error, loading}) {
    const user  = JSON.parse(localStorage.getItem('user'));
    const organisation_id  = JSON.parse(localStorage.getItem('organisation_id'));
    
    // useEffect(() => {
    //     getMyprofile(user.id);
    // }, [getMyprofile]);

    const [state, setState] = useState({});
    function handleChange({ target }) {
      setState({ ...state, [target.name]: target.value });
    }
    function handleImage({ target }) {
        
        if (target.files && target.files[0]) {
            state.avatar = target
            var reader = new FileReader();
            
            reader.onload = function(e) {
                var avatar = document.getElementById('avatar')
                avatar.setAttribute('src', e.target.result);
            }

            reader.readAsDataURL(target.files[0]); // convert to base64 string
          }
      }

      function handleSubmit(e) {
        e.preventDefault();

        state.first_name = state.first_name || user.first_name
        state.last_name = state.last_name || user.last_name
        state.username = state.username || user.username
        state.email = state.email || user.email
        state.phone_number = state.phone_number || user.phone_number
        state.user_id = user.id
        state.organisation_id = organisation_id
        state.password = ""
        edit_profile(state)

    }
     
    const [state_password, setPassword] = useState({});

    function handleChangePassword({target}) {
        setPassword({ ...state_password, [target.name]: target.value })
    } 
    function submitChangePassword(e){
        e.preventDefault();
        change_password(state_password)
    }

   
  return (
    <div class="c-app c-legacy-theme c-no-layout-transition" >
            <Menu/>
            <div class="c-wrapper">
                <Topnav/>
                <div class="c-body">
                    <main class="c-main">
                        <div class="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        {error &&
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
									<span aria-hidden="true">×</span>
								</button> */}
								<a className="text-center">{error}</a>
						    </div>
                        }
                        {success &&
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
									<span aria-hidden="true">×</span>
								</button> */}
								<a className="text-center">Updated successfully</a>
						    </div>
                        }

                    </div>
                </div>
                <form>
                    <div className="card">
                    <div className="card-body">
                    <div className="card-box">
                        {/* <h3 className="card-title">Basic Information</h3> */}
                        <div className="row">
                            <div className="col-md-12">
                            <a href="#" className="btn btn-primary float-right" data-toggle="modal" data-target="#change_password">Change Password</a>
                            <div id="change_password" className="modal fade delete-modal" role="dialog">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body text-center">
                                            <img src="assets/images/sent.png" alt="" width="50" height="46"/>
                                            <h3>Are you sure want to change password ?</h3>

                                            <input className="form-control" name="password" type="password" onChange={handleChangePassword} placeholder="Password"></input>
                                            <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Close</a>
                                                <button type="submit" className="btn btn-danger" data-dismiss="modal" onClick={submitChangePassword} disabled={!state_password.password}>Save Password</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                <div className="profile-img-wrap">
                                    <img className="inline-block" id="avatar" src={user.avatar?user.avatar:"assets/images/user.jpg"} alt="user"/>
                                     
                                    <div className="fileupload btn">
                                        <span className="btn-text">edit</span>
                                        <input className="upload" type="file"  name="avatar" onChange={handleImage}/>
                                    </div>
                                    
                                </div>

                                

                                
                                <div className="profile-basic">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <input type="text" name="first_name" onChange={handleChange} className="form-control" defaultValue={user.first_name}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Last Name</label>
                                                <input type="text" name="last_name" onChange={handleChange} className="form-control" defaultValue={user.last_name}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Username</label>
                                                <input type="text" name="username" onChange={handleChange} className="form-control" defaultValue={user.username}/>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Birth Date</label>
                                                <div className="cal-icon">
                                                    <input className="form-control" type="text" defaultValue={user.date_of_birth}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Gendar</label>
                                                <select className="select form-control">
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="focus-label">Email</label>
                                                <input type="email" name="email" onChange={handleChange} className="form-control" defaultValue={user.email}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Phone Number</label>
                                                <input type="text" name="phone_number" onChange={handleChange} className="form-control" defaultValue={user.phone_number}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Country</label>
                                                <select type="text" name="country" onChange={handleChange} className="form-control">
                                                    {user.country &&
                                                        <option value={user.country}>{user.country}</option>
                                                    }
                                                    <option value="Ghana">Ghana</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center m-t-20">
                        {(state.first_name || user.first_name) && (state.last_name || user.last_name) && (state.email || user.email) && (state.phone_number || user.phone_number) &&
                            <button className="btn btn-primary submit-btn" type="button" onClick={handleSubmit} disabled={loading}>{loading?"Loading...":"Save"}</button>
                        }
                        
                    </div>
                    </div>
                    </div>
                    </div>
                    
                    
                    
                </form>
                </div>
                </main>
            </div>
        </div>
    </div>
  );
}

function mapStateToProps({myprofile}){
    return {error:myprofile.error, success:myprofile.success, loading:myprofile.loading};
    
  }
  
export default connect(mapStateToProps, { edit_profile, change_password})(EditProfileComponent);

