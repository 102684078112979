import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';
import auth from '../auth/ducks/reducer';
import accounts from '../Accounts/duck/reducer';
import admin_account from '../Accounts/duck/admin_reducer';
import admin_permissions from '../Accounts/duck/permissions_reducer';
import myprofile from '../Settings/profile/duck/reducer';
import appointments from "../Appointments/duck/reducer";
import web_appointments from "../Appointments/duck/web_reducer";
import callLogs from "../Appointments/duck/get_callLogs_reducer";
import web_callLogs from "../Appointments/duck/web_callLogs_reducer";
import schedules from "../Schedules/duck/reducer";
import doctors from "../Doctors/duck/reducer";
import web_doctors from "../Doctors/duck/web_doctors_reducer";
import facilities from "../Facility/duck/reducer";
import doctor_profile from "../Doctors/duck/doctor_reducer";
import patients from "../Patients/duck/reducer";
import web_patients from "../Patients/duck/web_patient_reducer";
import patient_profile from "../Patients/duck/patient_reducer";
import visits from "../Patients/duck/visit_reducer";
import specialities from "../Settings/ducks/speciality_reducer";
import languages from "../Settings/ducks/language_reducer";
import billing from "../Billing/ducks/reducer";
import web_billing from "../Billing/ducks/web_reducer";
import agents from "../Agents/duck/reducer";
import subscriptions from '../Subscriptions/duck/reducer';
import dashboard from "../Dashboard/duck/reducer";
import subscribed_doctors from "../Doctors/duck/subscribed_reducer";
import subscribed_patients from "../Patients/duck/subscribed_reducer";



const rootReducer = combineReducers({
  auth,
  accounts,
  admin_account,
  admin_permissions,
  myprofile,
  appointments,
  web_appointments,
  schedules,
  doctors,
  web_doctors,
  facilities,
  doctor_profile,
  callLogs,
  web_callLogs,
  patients,
  patient_profile,
  visits,
  specialities,
  billing,
  web_billing,
  languages,
  web_patients,
  agents,
  subscriptions,
  dashboard,
  subscribed_doctors,
  subscribed_patients
  
});
const middlewares = [ReduxThunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
