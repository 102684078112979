import axios from 'axios'
import Literals from '../../../helpers/Literals';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success:null,
  uploaded_signature:null,
  deleted_signature:null,
  change_password:null,
  myprofile:null
};

function myprofile(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case 'EDIT_PROFILE':
      return { ...state, loading: true };
    case 'UPLOAD_SIGNATURE_SUCCESS':
      return { ...state, loading: false, myprofile: payload, success:"Signature uploaded successfully", error:null};
    case 'UPLOAD_SIGNATURE_FAILED':
      return { ...state, loading: false, error: payload, success:null };
    case 'DELETE_SIGNATURE_SUCCESS':
      return { ...state, loading: false, myprofile: payload, success:"Deleted successfully", error:null};
    case 'DELETE_SIGNATURE_FAILED':
      return { ...state, loading: false, error: payload, success:null };
      case 'CHANGE_PASSWORD_SUCCESS':
        return { ...state, loading: false, myprofile: payload, success:"Changed password successfully", error:null};
      case 'CHANGE_PASSWORD_FAILED':
        return { ...state, loading: false, error: payload, success:null };
        case 'EDIT_PROFILE_SUCCESS':
          return { ...state, loading: false, myprofile: payload, success:"Saved successfully", error:null};
        case 'EDIT_PROFILE_FAILED':
          return { ...state, loading: false, error: payload, success:null };
    default:
      return state;
  }
}

export const delete_signature = id => async dispatch => {
    try {
      let response = await axios.delete(`https://ghhis.herokuapp.com/api/v1/records/insurance/${id}/delete_signature/`);
      window.localStorage.removeItem('signature');
      window.localStorage.removeItem('signature_id');
      dispatch({ type: 'DELETE_SIGNATURE_SUCCESS', payload: response.data, success:true});
    } catch (e){
      dispatch({
        type: 'DELETE_SIGNATURE_FAILED',
        payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
      });
    }
  };

  export const change_password = password => async dispatch => {
    try {
      let response = await axios.post(`${Literals.BASE_API}users/backend/change_password/`, password);
      dispatch({ type: 'CHANGE_PASSWORD_SUCCESS', payload: response.data, success:true});
    } catch (e){
      dispatch({
        type: 'CHANGE_PASSWORD_FAILED',
        payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
      });
    }
  };

export const upload_signature = data => async dispatch => {
    console.log('uploading.... data', data)
    // data['image'] = data.image.files[0]

    const formData = new FormData()
    formData.append(
        'image',
        data.image.files[0],
        data.image.files[0].name
    )
    try {
      let response = await axios.post(`https://ghhis.herokuapp.com/api/v1/records/insurance/upload_signature/`, formData);
      window.localStorage.setItem('signature', JSON.stringify(response.data.results.image));
      window.localStorage.setItem('signature_id', JSON.stringify(response.data.results.id));
      document.getElementById("upload-form").reset();
      dispatch({ type: 'UPLOAD_SIGNATURE_SUCCESS', payload: response.data, success:true});
    } catch (e){
      dispatch({
        type: 'UPLOAD_SIGNATURE_FAILED',
        payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
      });
    }
  };
  export const edit_profile = data => async dispatch => {
    dispatch({ type: 'EDIT_PROFILE' });
   
    
    try {
      var profile_data = data
      var id = profile_data.user_id
      console.log('edit.... data', profile_data)
      
      if(profile_data.avatar){

        if (profile_data.avatar.files.length > 0){

          console.log('Processing image...')
        const formData = new FormData()
        formData.append('avatar', profile_data.avatar.files[0], profile_data.avatar.files[0].name)
        formData.append('first_name', profile_data.first_name || '');
        formData.append('last_name', profile_data.last_name || '');
        formData.append('username', profile_data.username || '');
        formData.append('email', profile_data.email || '');
        formData.append('phone_number', profile_data.phone_number || '');
        formData.append('organisation_id', profile_data.organisation_id || '');
        formData.append('user_id', profile_data.user_id || '');
        formData.append('country', profile_data.country || '');
        formData.append('password', "");
        var profile_data = formData
        }
        delete profile_data.avatar
      }
      profile_data['country']='Ghana';
      let response = await axios.put(`${Literals.BASE_API}users/backend/${id}/`, profile_data);
      window.localStorage.setItem('user', JSON.stringify(response.data.results));
      dispatch({ type: 'EDIT_PROFILE_SUCCESS', payload: response.data, success:true});
    } catch (e){
      dispatch({
        type: 'EDIT_PROFILE_FAILED',
        payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
      });
    }
  };

  
export default myprofile;
