import React, { useEffect, useState} from 'react';
import Menu from '../Menu/MenuComponent';
import Topnav from '../Menu/TopnavComponent';
import {connect} from 'react-redux'
import { Link } from 'react-router-dom';
import { deactivate_admin, loadMoreAdmins, search_keyword} from './duck/reducer';
import { Fragment } from 'react';

function AccountsComponent({ fetchAccounts, accounts = [], deactivate_admin, error, success, next_page, loadMoreAdmins, loading, search_keyword}){
    useEffect(() => {
        fetchAccounts();
    }, [fetchAccounts]);

    const [state, setState] = useState({});

    function handleSubmit(id) {

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        deactivate_admin(id);

      } 
      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
      }
     
      

      const user = JSON.parse(window.localStorage.getItem('user'))
      //permissions
    const admin_permissions = JSON.parse(localStorage.getItem('permissions'))?JSON.parse(localStorage.getItem('permissions')):{permissions:null};
    if(admin_permissions.permissions){
        var accounts_write = admin_permissions.permissions.accounts.write
        
        
    }else{
        accounts_write = true
        
    }

    //infinite scroll
    window.onscroll = () => {
        // Checks that the page has scrolled to the bottom
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                if(next_page && !loading){
                    loadMoreAdmins(next_page);
                }
            }
      };


    function handleClearField(e){
        e.preventDefault();
        document.getElementById("keyword").value='';
        state.keyword = ''
        search_keyword(state);
    }

    function handleSearch(e) {
        e.preventDefault();
            search_keyword(state);
        } 
    return (
        <div class="c-app c-legacy-theme c-no-layout-transition" >
            <Menu/>
            <div class="c-wrapper">
                <Topnav/>
                <div class="c-body">
                    <main class="c-main">
                        <div class="container-fluid">
                            <div class="fade-in">
                                <div className="row">
                                    <div className="col-sm-4 col-3">
                                        {success &&
                                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                {/* <span aria-hidden="true">×</span> */}
                                            </button>
                                            <a className="text-center">{success}</a>
                                        </div>
                                    }
                                    {error &&
                                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            <a className="text-center">{error}</a>
                                        </div>
                                    }
                                    </div>
                                    
                                </div>
                                    
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card">
                                            <div class="card-header"> 
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label>Keyword</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" name="keyword" onChange={handleChange} id="keyword"/>
                                                        <div className="input-group-append">
                                                            <button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label><br/></label>
                                                        <br/>
                                                        <button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search</button>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <Link to="/add_admin" className="btn btn btn-primary btn-rounded float-right"><i className="fa fa-plus"></i> Add Admin</Link>
                                                </div>
                                            </div>
                                            </div>
                                                <div class="card-body">
                                                    <table class="table table-responsive-sm table-hover datatable">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Username</th>
                                                                <th>Email</th>
                                                                <th>Phone number</th>
                                                                <th>Organisation</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {accounts.map(account => (
                                                                <tr key={account.id}>
                                                                    <td>
                                                                        <Link to={`/admin_profile?id=${account.id}`}>
                                                                        {!account.avatar &&
                                                                            <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
                                                                        }
                                                                        {account.avatar &&
                                                                        <img width="28" height="28" src={account.avatar} className="rounded-circle m-r-5" alt="" />
                                                                        }&nbsp;               
                                                                        {account.first_name} {account.last_name}
                                                                        </Link>
                                                                    </td>
                                                                    <td>{account.username}</td>
                                                                    <td>{account.email}</td>
                                                                    <td>{account.phone_number}</td>
                                                                    <td>{account.organization && <span class="badge badge-primary">{account.organization.name}</span>}</td>                                              
                                                                    <td className="text-center">
                                                                        <div className="dropdown dropdown-action">
                                                                            <a href="#"  data-toggle="dropdown"
                                                                                aria-expanded="false">
                                                                                <i className="fa fa-ellipsis-v"></i>
                                                                            </a>
                                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                                <Link to={`/admin_profile?id=${account.id}`} className="dropdown-item">
                                                                                    <i className="fa fa-user m-r-5"></i>&nbsp;View Profile
                                                                                </Link>
                        
                                                                                {user.id != account.id &&
                                                                                    <div>
                                                                                        {accounts_write &&
                                                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={`#deactivate_account${account.id}`}>
                                                                                            <i className="fa fa-ban m-r-5"></i>&nbsp;Deactivate
                                                                                        </a>
                                                                                        }
                                                                                </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    
                                                                        <div id={`deactivate_account${account.id}`} className="modal fade delete-modal" role="dialog">
                                                                            <div className="modal-dialog modal-dialog-centered">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-body text-center">
                                                                                    
                                                                                        <h3>Are you sure want to deactivate {account.first_name}'s account?</h3>
                                                                                        <div className="m-t-20">
                                                                                            <input hidden name="id" value={account.id} onChange={handleChange}></input>
                                                                                            <a href="#" className="btn btn-white" data-dismiss="modal">
                                                                                                Close
                                                                                            </a>
                                                                                            <button type="submit" data-dismiss="modal" className="btn btn-danger" onClick={() => handleSubmit(account.id)}>
                                                                                                Deactivate
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                </table>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="text-center">
                                                        {loading &&
                                                            <div class="spinner-border" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}
function mapStateToProps({accounts}){
    return {error:accounts.error, success:accounts.success, next_page:accounts.next_page, loading:accounts.loading};
    
  }
export default connect(mapStateToProps, { deactivate_admin,loadMoreAdmins, search_keyword})(AccountsComponent);
