import { connect } from 'react-redux';
import { fetchLanguage } from '../ducks/actions';
import LanguageComponent from './LanguagePage';

const mapStateToProps = ({ languages: { languages } }) => {
  return {
    languages,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchLanguage: () => dispatch(fetchLanguage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageComponent);
