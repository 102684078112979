import types from './types';
import axios from 'axios'
import Literals from '../../helpers/Literals'

const INITIAL_STATE = {
  web_loading: false,
  web_appointments: [],
  web_error: null,
  web_success:null,
};

function web_appointments(state = INITIAL_STATE, action) {
  const { type, payload, next_page, count, index} = action;
  switch (type) {
    case 'FETCH_WEB_APPOINTMENTS':
      return { ...state, web_loading: true, web_success:null, web_error:null};
    case 'FETCH_WEB_APPOINTMENTS_SUCCESS':
      return { ...state, web_loading: false, web_appointments: payload, web_success:null, web_error:null, web_next_page:next_page, web_count:count};
    case 'FETCH_WEB_APPOINTMENTS_FAILURE':
      return { ...state, web_loading: false, web_error: payload, web_success:null };
    
    case 'SEARCH_WEB_APPOINTMENT_SUCCESS':
      return { ...state, web_loading: false, web_appointments: payload, error:null};
    case 'SEARCH_WEB_APPOINTMENT_FAILED':
      return { ...state, web_loading: false, web_error: payload, web_success:null};

    case 'APPROVE_WEB_APPOINTMENT_SUCCESS':
      return { ...state, web_loading: false, web_appointment: payload, web_success:'Approved successfully', web_error:null};
    case 'APPROVE_WEB_APPOINTMENT_FAILED':
      return { ...state, web_loading: false, web_error: payload};

    case 'REVERT_WEB_COMPLETED':
      return { ...state, web_loading: true, web_success:null, web_error:null};
    case 'REVERT_WEB_COMPLETED_SUCCESS':
      state.web_appointments[index] = payload
      return { ...state, web_loading: false, web_appointments: state.web_appointments};
    case 'REVERT_WEB_COMPLETED_FAILED':
      return { ...state, web_loading: false, error: payload};

    case 'EXPORT_WEB_APPOINTMENT':
        return { ...state, web_loading: true, web_success:null, web_error:null};
    case 'EXPORT_WEB_APPOINTMENT_SUCCESS':
      return { ...state, web_loading: false, web_appointment: payload, web_success:payload.results.url, web_error:null};
    case 'EXPORT_WEB_APPOINTMENT_FAILED':
      return { ...state, web_loading: false, web_error: payload};

    case 'ASSIGN_WEB_APPOINTMENT_SUCCESS':
      return { ...state, web_loading: false, web_appointments: payload, web_success:'Appointment assigned successfully'};
    case 'ASSIGN_WEB_APPOINTMENT_FAILED':
      return { ...state, web_loading: false, web_error: payload};

    case 'LOAD_MORE_WEB_APPOINTMENTS_SUCCESS':
      var web_appointments = state.appointments.concat(payload)
      return { ...state, web_loading: false, web_appointments: web_appointments, web_error: null, web_success: null, web_next_page:next_page};
    case 'LOAD_MORE_WEB_APPOINTMENTS_FAILURE':
      return { ...state, web_loading: false};

    default:
      return state;
  }
}


export const export_web_appointment = data => async dispatch => {
  dispatch({ type: 'EXPORT_WEB_APPOINTMENTS', success:null, error:null });
  try {
    var keyword = data.keyword?data.keyword:'';
    var start_date = data.start_date?data.start_date:'';
    var end_date = data.end_date?data.end_date:'';
    let appointment = await axios.get(`${Literals.BASE_API}records/appointments/backend/?export&organization=true&keyword=${keyword}&start_date=${start_date}&end_date=${end_date}`);
    dispatch({ type: 'EXPORT_WEB_APPOINTMENT_SUCCESS', payload: appointment.data});
  } catch (e){
    dispatch({
      type: 'EXPORT_WEB_APPOINTMENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const search_web_keyword = data => async dispatch => {
  try {
    var keyword = data.keyword?data.keyword:'';
    var start_date = data.start_date?data.start_date:'';
    var end_date = data.end_date?data.end_date:'';
    let response = await axios.get(`${Literals.BASE_API}records/appointments/backend/?page=1&page_size=50&keyword=${keyword}&start_date=${start_date}&end_date=${end_date}&is_complete=${data.is_complete}&organization=true`);
    document.getElementById("loader").classList.add("hide");
    dispatch({ type: 'SEARCH_WEB_APPOINTMENT_SUCCESS', payload: response.data.results});
  } catch (e) {
    document.getElementById("loader").classList.add("hide");
    dispatch({
      type: 'SEARCH_WEB_APPOINTMENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const assign_web_appointment = data => async dispatch => {
  try {
    var appointment_id = data.appointment_id
    var professional_id = data.professional_id
    var start_date = data.start_date
    var payload_data = {professional_id:professional_id, start_date:start_date}
    let appointment = await axios.put(`${Literals.BASE_API}records/appointments/backend/${appointment_id}/assign/`, payload_data);

    document.getElementById("assign-loader"+appointment_id).classList.add("hide");
    document.getElementById("assign-button"+appointment_id).innerHTML = "Sucessfully re-assigned";
    let appointments = await axios.get(`${Literals.BASE_API}records/appointments/backend/?page=1&page_size=50&organization=true`);
    dispatch({ type: 'ASSIGN_WEB_APPOINTMENT_SUCCESS', payload: appointments.data.results, success:true});
  } catch (e){
    document.getElementById("assign-loader"+appointment_id).classList.add("hide");
    document.getElementById("assign-button"+appointment_id).innerHTML = "Error occured";
    dispatch({  
      type: 'ASSIGN_WEB_APPOINTMENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const loadMoreWebAppointments = api => async dispatch => {
  dispatch({ type: 'FETCH_WEB_APPOINTMENTS' });
  try {
    const { data } = await axios.get(api);

    dispatch({ type: 'LOAD_MORE_WEB_APPOINTMENTS_SUCCESS', payload: data.results, next_page:data.next});
  } catch (e) {
    dispatch({ type: 'LOAD_MORE_WEB_APPOINTMENTS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};

export const revert_web_completed = (id, index) => async dispatch =>{
  dispatch({ type: 'REVERT_WEB_COMPLETED' });
  try{
    var payload = {"is_complete":false}
    const { data } = await axios.put(`${Literals.BASE_API}records/appointments/backend/${id}/set_status/`, payload);
    document.getElementById("revert-loader"+id)?.classList?.add("hide");
    document.getElementById("revert-button"+id).innerHTML = "Reverted Successfully";
    dispatch({ type: 'REVERT_WEB_COMPLETED_SUCCESS', payload: data.results, success:true, index:index});
  }catch (e) {
    document.getElementById("revert-loader"+id)?.classList?.add("hide");
    document.getElementById("revert-button"+id).innerHTML = "Error occured try again";
    dispatch({ type: 'REVERT_WEB_COMPLETED_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });

  }
}


export default web_appointments;
