import React from 'react'
import { Link } from 'react-router-dom';

function LoadingPage() {
    const user = localStorage.getItem('user')
    if(!user){
        window.setTimeout(function(){
            window.location.replace('/login')
        }, 10000);
    }
    return (
        <div class="c-app c-legacy-theme flex-row align-items-center">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="">
                        <div class="input-prepend input-group">
                        
                        <div class="spinner-border text-theme" role="status" style={{width: "10rem", height: "10rem"}}>
                                <span class="sr-only"></span>
                            </div> 
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}


export default LoadingPage;