import React, {useEffect, useState, Fragment} from 'react';
import Menu from '../Menu/MenuComponent';
import Topnav from '../Menu/TopnavComponent';
import {Link} from 'react-router-dom';
import {search_keyword, create_facility, loadMoreFacility} from './duck/reducer';
import {connect} from 'react-redux';


function FacilityComponent({ fetchFacility, facilities = [], search_keyword, create_facility, error, success, next_page, loadMoreFacility, loading}) {
    useEffect(() => {
        fetchFacility();
      }, [fetchFacility]);

      const [state, setState] = useState({});
      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
	  }

    function handleSearch(e) {
      e.preventDefault();
        document.getElementById("loader").classList.remove("hide");
          search_keyword(state);
      } 
      
      function handleClearField(e){
        e.preventDefault();
        document.getElementById("keyword").value='';
        state.keyword = ''
        document.getElementById("loader").classList.remove("hide");
        search_keyword(state);
        }

        function handleImage({ target }) {
            state.image = target
            if (target.files && target.files[0]) {
                var reader = new FileReader();
                
                reader.onload = function(e) {
                    var avatar = document.getElementById('avatar')
                    avatar.setAttribute('src', e.target.result);
                }
    
                reader.readAsDataURL(target.files[0]); // convert to base64 string
              }
          }

          function handleSubmit(e) {
            e.preventDefault();
            create_facility(state)

          }


    //infinite scroll
    var facility_page = true
    window.onscroll = () => {
      // Checks that the page has scrolled to the bottom
          if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
              if(next_page && !loading && facility_page){
                  loadMoreFacility(next_page+'&keyword='+state.keyword);
              }
          }
    };
  
  return (
    <div className="c-app c-legacy-theme c-no-layout-transition" >
    <Menu/>
    <div className="c-wrapper">
        <Topnav/>
        <div className="c-body">
            <main className="c-main">
                <div className="container-fluid">
                    <div className="fade-in">
                        <div className="row">
                            <div className="col-sm-4 col-3">
                                {/* <h4 className="page-title">Doctors</h4> */}
                                {success &&
                                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                    {/* <span aria-hidden="true">×</span> */}
                                                </button>
                                                <a className="text-center">{success}</a>
                                            </div>
                                        }
                                        {error &&
                                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                    {/* <span aria-hidden="true">×</span> */}
                                                </button>
                                                <a className="text-center">{error}</a>
                                            </div>
                                        }

                            </div>
                            
                        </div>

                        <div className="card">
                            <div className="card-header"> 

                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Keyword</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" name="keyword" onChange={handleChange} id="keyword"/>
                                            <div className="input-group-append">
                                                <button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                                
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label><br/></label>
                                            <br/>
                                            <button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search <i className="fa fa-spinner fa-pulse hide" id="loader"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <a href="#" className="btn btn btn-primary btn-rounded float-right" type="button" data-toggle="modal" data-target="#myModal"><i className="fa fa-plus"></i> Add Facility</a>
                                        <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h4 class="modal-title">
                                                        {error &&
                                                           
                                                                <a className="text-center text-danger">{error}</a>
                                                         }
                                                        </h4>
                                                        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <form id="facility-form">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Facility Name<span className="text-danger">*</span></label>
                                                                    <input className="form-control" type="text" name="name" onChange={handleChange} required/>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Logo</label>
                                                                    <div className="profile-upload">
                                                                        <div className="upload-img">
                                                                            <img alt="" src="assets/img/user.jpg" id="avatar"/>
                                                                        </div>
                                                                        <div className="upload-input">
                                                                            <input type="file" className="form-control" name="image" onChange={handleImage}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="row">
                                                            
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label>First Name <span className="text-danger">*</span></label>
                                                                    <input className="form-control" type="text" name="first_name" onChange={handleChange} required/>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label>Last Name<span className="text-danger">*</span></label>
                                                                    <input className="form-control" type="text" name="last_name" onChange={handleChange} required/>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label>Phone number<span className="text-danger"></span></label>
                                                                    <input className="form-control" type="text" name="phone_number" onChange={handleChange} required/>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="row">
                                                            
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label>Email <span className="text-danger">*</span></label>
                                                                    <input className="form-control" type="email" name="email" onChange={handleChange} required/>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label>Password<span className="text-danger">*</span></label>
                                                                    <input className="form-control" type="password" name="password" onChange={handleChange} required/>
                                                                    {state.password_again &&
                                                                        <div>
                                                                            {state.password !== state.password_again &&
                                                                            <small><code><strong>Note!</strong>: Mismatched passwords</code></small>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label>Re-type Password<span className="text-danger">*</span></label>
                                                                    <input className="form-control" type="password" name="password_again" onChange={handleChange} required/>
                                                                    {state.password_again &&
                                                                        <div>
                                                                            {state.password !== state.password_again &&
                                                                            <small><code><strong>Note!</strong>: Mismatched passwords</code></small>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        </form>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
                                                        <button class="btn btn-primary" type="button" onClick={handleSubmit} disabled={!(state.name && state.first_name && state.last_name && state.email && (state.password == state.password_again))}>{loading?<Fragment>Loading <i className="fa fa-spinner fa-pulse"></i></Fragment>:<Fragment> Save</Fragment>}</button>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
          
                            <div className="card-body">
                                <table className="table table-responsive-sm table-hover datatable">
                                    <thead>
                                        <tr>
                                            <th>Facility Name</th>
                                            <th>Facility Address</th>
                                            <th>Facility Email</th>
                                            <th>Owner</th>
                                            <th>Phone number</th>
                                            <th>Email</th>
                                            <th>Country</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {facilities.map(facility => (
                                            <tr key={facility.id}>
                                                <td>
                                                    {facility.logo &&
                                                    <img width="40" height="40" src={facility.logo} className="rounded-circle m-r-5" alt=""/>  
                                                    }
                                                    {!facility.logo &&
                                                    <img width="40" height="40" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/>  
                                                    }
                                                    &nbsp;
                                                    {facility.name}
                                                
                                                </td>
                                                <td>{facility.address}</td>
                                                <td>{facility.email}</td>
                                                <td>
                                                <Link >
                                                
                                                {facility.owner && 
                                                    <Fragment>
                                                        {facility.owner.avatar &&
                                                        <img width="40" height="40" src={facility.owner.avatar} className="rounded-circle m-r-5" alt=""/>  
                                                        }
                                                        {!facility.owner.avatar &&
                                                        <img width="40" height="40" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/>  
                                                        }
                                                        &nbsp;
                                                        {facility.owner.first_name} {facility.owner.last_name}
                                                    </Fragment>
                                                
                                                }
                                                
                                                </Link>
                                                </td>
                                                
                                                <td>{facility.owner && <Fragment>{facility.owner.phone_number}</Fragment>}</td>
                                                <td>{facility.owner && <Fragment>{facility.owner.email}</Fragment>}</td>
                                                <td>{facility.owner && <Fragment><span class="badge badge-pill badge-primary">{facility.owner.country}</span></Fragment>}</td>
                                                
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
            
           
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="text-center">
                                    {loading &&
                                        <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        </div>
    </div>
  );
}

function mapStateToProps({facilities}){
  return {error:facilities.error, success:facilities.success, next_page:facilities.next_page, loading:facilities.loading};
  
}
export default connect(mapStateToProps, { search_keyword, create_facility, loadMoreFacility })(FacilityComponent);