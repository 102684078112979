import { connect } from 'react-redux';
import { getPatient, fetchVisits, fetchPharmacy} from './duck/actions';
import PatientProfileComponent from './PatientProfilePage';

const mapStateToProps = ({ patient_profile: { patient_profile }, visits: { visits }}) => {
  return {
    patient_profile,
    visits,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPatient: (id) => dispatch(getPatient(id)),
    fetchVisits: (id) => dispatch(fetchVisits(id)),
    fetchPharmacy: (id) => dispatch(fetchPharmacy(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientProfileComponent);

