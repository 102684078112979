import React, {useState, Fragment} from 'react';
import Menu from '../../Menu/MenuComponent'
import Topnav from '../../Menu/TopnavComponent'
import {Link} from 'react-router-dom'
import {change_password} from './duck/reducer'
import {connect} from 'react-redux'

function ProfileComponent({error, success, change_password}) {
    const user  = JSON.parse(localStorage.getItem('user'));
    
    const admin_permissions = JSON.parse(localStorage.getItem('permissions'))?JSON.parse(localStorage.getItem('permissions')):{permissions:null};
    
    const [state, setState] = useState({});
    const [state_password, setPassword] = useState({});

    function handleChange({ target }) {
        setState({ image: target })
      }
      
    function handleChangePassword({target}) {
        setPassword({ ...state_password, [target.name]: target.value })
    } 
    function submitChangePassword(e){
        e.preventDefault();
        change_password(state_password)
    }

   
    
  return (
    
    <div class="c-app c-legacy-theme c-no-layout-transition" >
    <Menu/>
    <div class="c-wrapper">
        <Topnav/>
        <div class="c-body">
            <main class="c-main">
                <div class="container-fluid">
                <div class="card">
                <div class="card-body">
                <div className="row">
                    <div className="col-sm-7 col-6">
                        {/* <h4 className="page-title">My Profile</h4> */}
                        
                    </div>

                    <div className="col-sm-5 col-6 text-right m-b-30">
                        <Link to="/edit_profile" className="btn btn-primary btn-rounded"><i className="fa fa-edit"></i> Edit Profile</Link>
                    </div>
                </div>
                <div className="card-box profile-header">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="profile-view">
                                
                                <div className="profile-basic">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="profile-info-left">
                                                <div className="profile-img-wrap">
                                                    <div className="profile-img">
                                                        {user.avatar &&
                                                        <a href="#"><img className="avatar" src={user.avatar} alt=""  /></a>
                                                        }
                                                        {!user.avatar &&
                                                        <a href="#"><img className="avatar" src="assets/images/user.jpg" alt="" /></a>
                                                        }
                                                    </div>
                                                </div>
                                                <h3 className="user-name m-t-0 mb-0">{user.first_name} {user.last_name}</h3>
                                                <small className="text-muted">&nbsp;</small>
                                                {/* <div className="staff-id">Admin Type: {user.user_type[0]}</div> */}
                                                {/* <div className="staff-msg"><a href="#" className="btn btn-primary" data-toggle="modal" data-target="#change_password">Change Password</a></div> */}
                                            </div>
                                            <div id="change_password" className="modal fade delete-modal" role="dialog">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content">
                                                            <div className="modal-body text-center">
                                                                <img src="assets/img/sent.png" alt="" width="50" height="46"/>
                                                                <h3>Are you sure want to change password ?</h3>

                                                                <input className="form-control" name="password" type="password" onChange={handleChangePassword} placeholder="Password"></input>
                                                                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Close</a>
                                                                    <button type="submit" className="btn btn-danger" data-dismiss="modal" onClick={submitChangePassword} disabled={!state_password.password}>Save Password</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-md-7">
                                            <ul className="personal-info">
                                                <li>
                                                    <span className="title">Phone:</span>
                                                    <span className="text"><a href="#">{user.phone_number}&nbsp;</a></span>
                                                </li>
                                                <li>
                                                    <span className="title">Email:</span>
                                                    <span className="text"><a href="#">{user.email}</a></span>
                                                </li>
                                                <li>
                                                    <span className="title">Birthday:</span>
                                                    <span className="text">&nbsp;</span>
                                                </li>
                                                <li>
                                                    <span className="title">Address:</span>
                                                    <span className="text">&nbsp;</span>
                                                </li>
                                                <li>
                                                    <span className="title">Admin Type:</span>
                                                    <span className="text"> {user.user_type && <Fragment>{user.user_type? user.user_type.map(user_type => (<span><span class="badge badge-primary">{user_type}</span>&nbsp;</span>)):""}</Fragment>}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                </div>
                                <hr/>
                                <br/>
                                {/* <div className="row">
                                    <div className="col-md-6">
                                        <label className="col-lg-2 col-form-label"><strong>Permissions</strong></label><br/>
                                        <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Module Permission</th>
                                                    <th className="text-center">Read</th>
                                                    <th className="text-center">Write</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Main Settings </td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.settings.read &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.settings.read &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.settings.write &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.settings.write &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Accounts </td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.accounts.read &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.accounts.read &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.accounts.write &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.accounts.write &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Doctors</td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.doctors.read &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.doctors.read &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.doctors.write &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.doctors.write &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Patients</td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.patients.read &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.patients.read &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.patients.write &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.patients.write &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Visits</td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.visits.read &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.visits.read &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.visits.write &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.visits.write &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Appointments</td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.appointments.read &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.appointments.read &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.appointments.write &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.appointments.write &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Doctors Schedules</td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.schedules.read &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.schedules.read &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.schedules.write &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.schedules.write &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Departments</td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.departments.read &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.departments.read &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.departments.write &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.departments.write &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Pharmacy</td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.pharmacy.read &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.pharmacy.read &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.pharmacy.write &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.pharmacy.write &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Insurance</td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.insurance.read &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.insurance.read &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.insurance.write &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.insurance.write &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Payments</td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.payments.read &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.payments.read &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {admin_permissions.permissions &&
                                                            <div>
                                                                {admin_permissions.permissions.payments.write &&
                                                                    <i className="fa fa-check"></i>
                                                                }
                                                                {!admin_permissions.permissions.payments.write &&
                                                                    <i className="fa fa-remove"></i>
                                                                }
                                                            </div>
                                                        }
                                                        {!admin_permissions.permissions &&
                                                            <i className="fa fa-remove"></i>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div> */}
                            </div>                        
                        </div>
                    </div>
                </div>
                </div>
				</div>
                </div>
                </main>
                </div>
        </div>
    </div>
  );
}

export default connect(null, {change_password})(ProfileComponent);

