
import axios from 'axios'
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
    loading: false,
    billing: [],
    error: null,
  };
  
  function billing(state = INITIAL_STATE, action) {
    const { type, payload, next_page, count} = action;
      switch (type) {
        case 'FETCH_BILLING':
          return { ...state, loading: true };
        case 'FETCH_BILLING_SUCCESS':
            return { ...state, loading: false, billing: payload, success:null, error:null, next_page:next_page, count:count};
        case 'FETCH_BILLING_FAILED':
          return { ...state, loading: false, error: payload };
        case 'SEARCH_BILLING_SUCCESS':
          return { ...state, loading: false, billing: payload, error:null};
        case 'SEARCH_BILLING_FAILED':
          return { ...state, loading: false, error: payload, success:null};
        case 'CREATE_BILLING_SUCCESS':
            return { ...state, loading: false, billing: payload, create_success:true, create_error:null};
        case 'CREATE_BILLING_FAILED':
            return { ...state, loading: false, create_error: payload, create_success:null};
        case 'LOAD_MORE_BILLING_SUCCESS':
            var billing = state.billing.concat(payload)
            return { ...state, loading: false, billing: billing, error: null, success: null, next_page:next_page};
        case 'LOAD_MORE_BILLING_FAILED':
            return { ...state, loading: false};

        case 'EXPORT_BILLING':
              return { ...state, loading: true, success:null, error:null};
        case 'EXPORT_BILLING_SUCCESS':
              return { ...state, loading: false, success:payload.results, error:null};
        case 'EXPORT_BILLING_FAILED':
              return { ...state, loading: false, error: payload};
        default:
          return state;
      }
    }

    export const loadMoreBilling = api => async dispatch => {
        dispatch({ type: 'FETCH_BILLING' });
        try {
          const { data } = await axios.get(api);
      
          dispatch({ type: 'LOAD_MORE_BILLING_SUCCESS', payload: data.results, next_page:data.next});
        } catch (e) {
          dispatch({ type: 'LOAD_MORE_BILLING_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
        }
      };

    
      export const search_keyword = data => async dispatch => {
        try {
          var start_date = data.start_date?data.start_date:'';
          var end_date = data.end_date?data.end_date:'';
          var keyword = data.keyword?data.keyword:'';
          let response = await axios.get(`${Literals.BASE_API}web/backend/billing/?page=1&page_size=50&start_date=${start_date}&end_date=${end_date}&organization=false&keyword=${keyword}`);
          document.getElementById("loader").classList.add("hide");
          dispatch({ type: 'SEARCH_BILLING_SUCCESS', payload: response.data.results});
        } catch (e) {
          document.getElementById("loader").classList.add("hide");
          dispatch({
            type: 'SEARCH_BILLING_FAILED',
            payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
          });
        }
      };


      export const export_billing = data => async dispatch => {
        dispatch({ type: 'EXPORT_BILLING', success:null, error:null });
        try {
          var keyword = data.keyword?data.keyword:'';
          var start_date = data.start_date?data.start_date:'';
          var end_date = data.end_date?data.end_date:'';
          let billing = await axios.get(`${Literals.BASE_API}web/backend/billing/?export&organization=false&keyword=${keyword}&start_date=${start_date}&end_date=${end_date}`);
          dispatch({ type: 'EXPORT_BILLING_SUCCESS', payload: billing.data, success:billing.data});
        } catch (e){
          dispatch({
            type: 'EXPORT_BILLING_FAILED',
            payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
          });
        }
      };
      
  
    export default billing;
  