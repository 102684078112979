import React, { useEffect, useState} from 'react';
import Menu from '../Menu/MenuComponent';
import Topnav from '../Menu/TopnavComponent';
import {connect} from 'react-redux'
import { Link } from 'react-router-dom';
import { loadMoreReferrals, mark_claimed} from './duck/reducer';
import { Fragment } from 'react';

function ReferralsComponent({ fetchReferrals, referrals, error, success, next_page, loading, loadMoreReferrals, mark_claimed}){
    useEffect(() => {
        fetchReferrals();
    }, [fetchReferrals]);

    const [state, setState] = useState({});

    function handleSubmit(id) {

        mark_claimed(id);

      } 
      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
      }

    //infinite scroll
    window.onscroll = () => {
        // Checks that the page has scrolled to the bottom
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                if(next_page && !loading){
                    loadMoreReferrals(next_page);
                }
            }
      };


    
    
    return (
        <div className="c-app c-legacy-theme c-no-layout-transition" >
            <Menu/>
            <div className="c-wrapper">
                <Topnav/>
                <div className="c-body">
                    <main className="c-main">
                        <div className="container-fluid">
                            <div className="fade-in">
                                <div className="row">
                                    <div className="col-sm-4 col-3">
                                        
                                    {error &&
                                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            <a className="text-center">{error}</a>
                                        </div>
                                    }
                                    </div>
                                    
                                </div>
                                    
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-header"> 
                                            <div className="row">
                                                <div className="col-md-3">
                                                    {/* <label>Keyword</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" name="keyword" onChange={handleChange} id="keyword"/>
                                                        <div className="input-group-append">
                                                            <button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label><br/></label>
                                                        <br/>
                                                        {/* <button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search</button> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <Link to="/agents" className="btn btn btn-info btn-rounded float-right"><i className="fa fa-address-book"></i> Agents</Link>
                                                </div>
                                            </div>
                                            </div>
                                                <div className="card-body">
                                                    <table className="table table-responsive-sm table-hover datatable">
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>User</th>
                                                                <th>Referrer</th>
                                                                <th>Points</th>
                                                                <th>Claimed</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {referrals?.rewards?.length>0?<Fragment>
                                                            {referrals.rewards.map(account => (
                                                                <tr key={account.id}>
                                                                     <td>{account.id}</td>
                                                                    <td>
                                                                        {account.user && <Fragment>
                                                                        
                                                                        <Link to={`/admin_profile?id=${account.user.id}`}>
                                                                            {account.user.avatar && 
                                                                            <img width="28" height="28" src={account.user.avatar} className="rounded-circle m-r-5" alt=""/> 
                                                                            }
                                                                            {!account.user.avatar && 
                                                                            <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
                                                                            }
                                                                            {account.user.first_name} {account.user.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {account.user.id}</span>
                                                                        </Link>
                                                                        <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                                            </a>
                                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                                <a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{account.user.email}</a>
                                                                                <a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{account.user.phone_number}</a>
                                                                                <a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{account.user.gender}</a>
                                                                                <a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{account.user.country}</a>
                                                                            </div>
                                                                        </Fragment>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {account.referrer && <Fragment>
                                                                        
                                                                        <Link to={`/admin_profile?id=${account.referrer.id}`}>
                                                                            {account.user.avatar && 
                                                                            <img width="28" height="28" src={account.referrer.avatar} className="rounded-circle m-r-5" alt=""/> 
                                                                            }
                                                                            {!account.referrer.avatar && 
                                                                            <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
                                                                            }
                                                                            {account.referrer.first_name} {account.referrer.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {account.user.id}</span>
                                                                        </Link>
                                                                        <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                                            </a>
                                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                                <a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{account.referrer.email}</a>
                                                                                <a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{account.referrer.phone_number}</a>
                                                                                <a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{account.referrer.gender}</a>
                                                                                <a className="dropdown-item" ><i className="fa fa-flag"></i>&nbsp;{account.referrer.country}</a>
                                                                            </div>
                                                                        </Fragment>
                                                                        }
                                                                    </td>
                                                                    <td><h5><span class="badge badge-success">{account.points}</span></h5></td>
                                                                    <td><h5>{account.claimed?<span class="badge badge-dark">Yes</span>:<span class="badge badge-light">No</span>}</h5></td>
                                                                                                               
                                                                    <td >
                                                                        
                                                                        <button data-toggle="modal" data-target={`#deactivate_account${account.id}`} className="btn btn-sm btn-pill btn-primary" type="button">Mark Claimed</button>
                                                                        <div id={`deactivate_account${account.id}`} className="modal fade delete-modal" role="dialog">
                                                                            <div className="modal-dialog modal-dialog-centered">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-body text-center">
                                                                                    
                                                                                        Are you sure want to mark feferral point of {account.first_name} as claimed?
                                                                                        <br/>
                                                                                        <br/>
                                                                                        <div className="m-t-20">
                                                                                            <input hidden name="id" value={account.id} onChange={handleChange}></input>
                                                                                            <a href="#" className="btn btn-white" data-dismiss="modal">
                                                                                                Close
                                                                                            </a>
                                                                                            <button type="submit" data-dismiss="modal" className="btn btn-danger" onClick={() => handleSubmit(account.id)}>
                                                                                                Claimed
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}</Fragment>:
                                                            <tr><td valign="top" colspan="7" className="text-center">{!loading && <strong>No Data Available</strong>}</td></tr>}
                                                        </tbody>
                                                </table>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="text-center">
                                                        {loading &&
                                                            <div className="spinner-border" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}
function mapStateToProps({agents}){
    return {referrals:agents.referrals, loading:agents.loading, error:agents.error, success:agents.success
        };
  }
export default connect(mapStateToProps, {loadMoreReferrals, mark_claimed})(ReferralsComponent);
