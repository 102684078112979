import axios from 'axios';
import Literals from '../../helpers/Literals'



const INITIAL_STATE = {
    loading: false,
    referrals: [],
    users:[],
    agents:[],
    error: null,
    success:null,
    users_loading:false,
    agents_loading:false,
  };

  function agents(state = INITIAL_STATE, action) {
    const { type, payload, next_page, count, index} = action;
    switch (type) {
        case 'FETCH_REFERRALS':
            return { ...state, loading:true};
        case 'FETCH_USERS':
            return { ...state, users_loading:true};
        case 'FETCH_AGENTS':
            return { ...state, agents_loading:true};
        case 'FETCH_REFERRALS_SUCCESS':
          return { ...state, loading: false, referrals: payload, error: null, success: null, next_page:next_page};
        case 'FETCH_REFERRALS_FAILURE':
            return { ...state, loading: false};
        case 'MARK_CLAIMED_SUCCESS':
            return { ...state, loading: false, error: null, success: null};
        case 'FETCH_REFERRALS_FAILURE':
            return { ...state, loading: false};
        case 'FETCH_MORE_REFERRALS_SUCCESS':
          var referrals = state.referrals.concat(payload)
          return { ...state, loading: false, referrals: referrals, error: null, success: null, next_page:next_page};
        case 'FETCH_MORE_REFERRALS_FAILURE':
            return { ...state, loading: false};

        case 'FETCH_MORE_AGENTS':
            return { ...state, agents_loading:true};
        case 'FETCH_MORE_AGENTS_SUCCESS':
            var agents = state.agents.concat(payload)
            return { ...state, agents_loading: false, agents: agents, agents_next_page:next_page};
        case 'FETCH_MORE_AGENTS_FAILURE':
            return { ...state, agents_loading: false};

        case 'FETCH_MORE_USERS':
            return { ...state, users_loading:true};
        case 'FETCH_MORE_USERS_SUCCESS':
            var users = state.users.concat(payload)
            return { ...state, users_loading: false, users: users, users_next_page:next_page};
        case 'FETCH_MORE_USERS_FAILURE':
            return { ...state, users_loading: false};

        case 'TAG_AGENT':
            return { ...state, loading:true};
        case 'TAG_AGENT_SUCCESS':
            state.users[index] = payload
            var users = state.users
            return { ...state, loading: false, error: null, success: true, users: users};
        case 'TAG_AGENT_FAILURE':
            return { ...state, loading: false, error: true, success: null};
    
        case 'FETCH_USERS_SUCCESS':
            return { ...state, users_loading: false, users: payload, users_next_page:next_page };
        case 'FETCH_USERS_FAILURE':
            return { ...state, users_loading: false, error: payload };
        case 'FETCH_AGENTS_SUCCESS':
            return { ...state, agents_loading: false, agents: payload, agents_next_page:next_page };
        case 'FETCH_AGENTS_FAILURE':
            return { ...state, agents_loading: false, error: payload };
  
      default:
        return state;
    }
  }
  
export default agents;

export const loadMoreReferrals = api => async dispatch => {
    dispatch({ type: 'FETCH_REFERRALS' });
    try {
      const { data } = await axios.get(api);
  
      dispatch({ type: 'FETCH_MORE_REFERRALS_SUCCESS', payload: data.results, next_page:data.next});
    } catch (e) {
      dispatch({ type: 'FETCH_REFERRALS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
    }
  };

  export const mark_claimed = id => async dispatch => {
    dispatch({ type: 'FETCH_REFERRALS' });
    try {
      const { data } = await axios.post(`${Literals.BASE_API}users/backend/${id}/claim_points/`);
  
      dispatch({ type: 'MARK_CLAIMED_SUCCESS', payload: data.results, next_page:data.next});
    } catch (e) {
      dispatch({ type: 'MARK_CLAIMED_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
    }
  };

  export const tag_agent = (id, index) => async dispatch => {
    dispatch({ type: 'TAG_AGENT' });
    try {
      const { data } = await axios.put(`${Literals.BASE_API}users/backend/${id}/tag/`, {"tag":"agent"});
  
      dispatch({ type: 'TAG_AGENT_SUCCESS', payload: data.results, next_page:data.next, index:index});
    } catch (e) {
      dispatch({ type: 'TAG_AGENT_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
    }
  };


  export const loadMoreAgents = api => async dispatch => {
    dispatch({ type: 'FETCH_MORE_AGENTS' });
    try {
      const { data } = await axios.get(api);
  
      dispatch({ type: 'FFETCH_MORE_AGENTS_SUCCESS', payload: data.results, next_page:data.next});
    } catch (e) {
      dispatch({ type: 'FETCH_MORE_AGENTS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
    }
  };

  export const loadMoreUsers = api => async dispatch => {
    dispatch({ type: 'FETCH_MORE_USERS' });
    try {
      const { data } = await axios.get(api);
  
      dispatch({ type: 'FETCH_MORE_USERS_SUCCESS', payload: data.results, next_page:data.next});
    } catch (e) {
      dispatch({ type: 'FETCH_MORE_USERS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
    }
  };

  export const search_agents_keyword = data => async dispatch => {
    dispatch({ type: 'FETCH_AGENTS' });
    try {
      var keyword = data.keyword?data.keyword:'';
      let response = await axios.get(`${Literals.BASE_API}users/backend/?page=1&page_size=100&all_users&tag=agent&keyword=${keyword}`);
      dispatch({ type: 'FETCH_AGENTS_SUCCESS', payload: response.data.results});
      document.getElementById("loader").classList.add("hide");
    } catch (e) {
      dispatch({
        type: 'FETCH_AGENTS_FAILURE',
        payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
      });
      document.getElementById("loader").classList.add("hide");
    }
  };


  export const search_users_keyword = data => async dispatch => {
    dispatch({ type: 'FETCH_USERS' });
    try {
      var keyword = data.keyword?data.keyword:'';
      let response = await axios.get(`${Literals.BASE_API}users/backend/?page=1&page_size=100&all_users&keyword=${keyword}`);
      dispatch({ type: 'FETCH_USERS_SUCCESS', payload: response.data.results});
      document.getElementById("loader").classList.add("hide");
    } catch (e) {
      dispatch({
        type: 'FETCH_USERS_FAILURE',
        payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
      });
      document.getElementById("loader").classList.add("hide");
    }
  };