const FETCH_DOCTORS = 'FETCH_DOCTORS';
const FETCH_DOCTORS_SUCCESS = 'FETCH_DOCTORS_SUCCESS';
const FETCH_DOCTORS_FAILURE = 'FETCH_DOCTORS_FAILURE';
const SUCCESS = 'SUCCESS'
const FAILED = 'FAILED'

export default {
  FETCH_DOCTORS,
  FETCH_DOCTORS_FAILURE,
  FETCH_DOCTORS_SUCCESS,
  SUCCESS,
  FAILED,

};
