import axios from 'axios';
import types from './types';
import Literals from '../../helpers/Literals'

export const fetchDoctors = () => async dispatch => {
  dispatch({ type: types.FETCH_DOCTORS });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}professional/backend/?page=1&page_size=50&organization=false`);

    dispatch({ type: types.FETCH_DOCTORS_SUCCESS, payload: data.results, next_page:data.next, count:data.count});
  } catch (e) {
    dispatch({ type: types.FETCH_DOCTORS_FAILURE, payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};

export const fetchWebDoctors = () => async dispatch => {
  dispatch({ type: 'FETCH_WEB_DOCTORS' });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}professional/backend/?page=1&page_size=50&organization=true`);

    dispatch({ type: 'FETCH_WEB_DOCTORS_SUCCESS', payload: data.results, web_next_page:data.next, web_count:data.count});
  } catch (e) {
    dispatch({ type: 'FETCH_WEB_DOCTORS_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};

export const getDoctor = (id) => async dispatch => {
  dispatch({ type: 'FETCH_DOCTOR_PROFILE' });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}professional/backend/${id}/`);
    dispatch({ type: 'FETCH_DOCTOR_PROFILE_SUCCESS', payload: data.results });
  } catch (e) {
    dispatch({ type: 'FETCH_DOCTOR_PROFILE_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};

export const getDoctorSchedules = (id) => async dispatch => {
  dispatch({ type: 'FETCH_DOCTOR_SCHEDULES' });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}records/appointments/?professional_id=${id}&page=1&page_size=50`);
    dispatch({ type: 'FETCH_DOCTOR_SCHEDULES_SUCCESS', payload: data.results });
  } catch (e) {
    dispatch({ type: 'FETCH_DOCTOR_SCHEDULES_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};




export const fetchSubscribedDoctors = () => async dispatch => {
  dispatch({ type: 'FETCH_SUBSCRIBED_DOCTORS' });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}users/backend/?page=1&page_size=100&tag=subscribed_professional&all_users`);

    dispatch({ type: 'FETCH_SUBSCRIBED_DOCTORS_SUCCESS', payload: data.results, next_page:data.next, web_count:data.count});
  } catch (e) {
    dispatch({ type: 'FETCH_SUBSCRIBED_DOCTORS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};