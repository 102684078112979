import { connect } from 'react-redux';
import { fetchSubscriptions } from './duck/actions';
import SubscriptionComponent from './SubscriptionPage';

const mapStateToProps = ({ subscriptions: { subscriptions } }) => {
  return {
    subscriptions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSubscriptions: () => dispatch(fetchSubscriptions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionComponent);
