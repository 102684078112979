import React, {useEffect, useState} from 'react';
import Menu from '../Menu/MenuComponent';
import Topnav from '../Menu/TopnavComponent';
import axios from 'axios';
import Literals from '../helpers/Literals'
import { formatDatetime } from '../helpers/utils';

import { useParams } from 'react-router-dom';
import "./style.css";



function Search() {
    const {search_type} = useParams();
    const {id} = useParams();
    useEffect(() => {
        search_keyword(id);

      }, [search_type, id]);

      

      console.log('search_type......',search_type)

      const [searchResults, setSearchResults] = useState([]);

      const [loading, setLoading] = useState(false);
      const [error, setError] = useState(null);
      const [success, setSuccess] = useState(null);

      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
	  }

      const [state, setState] = useState({});
      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
	  }
     
      
      function isInt(value) {
        return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
      }
    
      function handleSearch(e) {
        e.preventDefault();
        setSuccess(null)
            if(isInt(state.keyword)){
                
                setError({search:null})
                search_keyword(state.keyword);
            }else{
                setError({search:'Keyword must be an interger'})
            }
            
        } 
        
        function handleClearField(e){
          e.preventDefault();
          document.getElementById("keyword").value='';
          setState({})
          setSearchResults([])
          
        }

async function search_keyword(keyword){
    setLoading(true)
     await axios.get(`${Literals.BASE_API}records/appointments/visit/backend?page=1&page_size=100&pharmacy&keyword=${keyword}&search_type=${search_type}`).then(function (response) {
        setLoading(false)
        setSearchResults(response.data.results)
        console.log(response);
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error);
      });

}


async function handleServe (id)  {
    setLoading(true)
    await axios.put(`${Literals.BASE_API}records/appointments/visit/backend/${id}/served/`, {"served":true}).then(function (response) {
        setLoading(false)
        setError({serve:null})
        setSuccess(true)
        // searchResults[index] = response.data.results
        // setSearchResults(searchResults)
        search_keyword(state);
        console.log(response);
      })
      .catch(function (error) {
        setLoading(false)
        setSuccess(false)
        setError({serve:'Error serving...'})
        console.log(error);
      });
  
     
  };

const admin_access = JSON.parse(localStorage.getItem('permissions'))?JSON.parse(localStorage.getItem('permissions')):{permissions:null};
    if(admin_access.permissions){
        
        var pharmacy_write = admin_access.permissions.pharmacy.write
        var lab_write = admin_access.permissions.pharmacy.write
    }else{
        
        var pharmacy_write =true
        var lab_write =true
    }

    
    function formartText(string){
        return string.replace(/;/g,' ').replace(/\n/g,',  ');
    }
  
  return (
    <div className="c-app c-legacy-theme c-no-layout-transition" >
    <Menu/>
    <div className="c-wrapper">
        <Topnav/>
        <div className="c-body">
            <main className="c-main">
                <div className="container-fluid">
                    <div className="fade-in">
                       

                        <div className="card">
                            <div className="card-header"> 
                                <div className="row">
                                <div className="col-md-3">
                                    <label>Keyword</label>
                                    <div className="input-group">
                                    <input type="number" className={`form-control ${error?.search?"is-invalid":""}`} name="keyword" onChange={handleChange} id="keyword"/>
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
                                        </div>
                                        {error?.search &&
                                            <div class="invalid-feedback" id="validationServer03Feedback">{error?.search}</div>
                                        }
                                    </div>
                                    
                                </div>
                                
                                <div className="col-md-2">
                                    <div className="form-group">
                                    <label><br/></label>
                                    <br/>
                                    <button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search {loading && <i className="fa fa-spinner fa-pulse" id="loader"></i>}</button>
                                    </div>
                                </div>
                                    
                                

                                </div>
                                
                            </div>
          
                            <div className="card-body">
                                
                                {searchResults.length > 0 && 
                                    <>
                                        {searchResults[0]?.medication_served?<button className="btn btn-sm btn-pill btn-success" type="button"><strong>Served</strong></button>
                                        :((pharmacy_write || lab_write)?<button data-toggle="modal" data-target={`#pharmacy${state.keyword}`} className="btn btn-sm btn-primary" type="button"><strong>Mark as served</strong></button>:<button className="btn btn-sm btn-pill btn-danger" type="button">Not Served</button>)
                                        }
                                        <div id={`pharmacy${state.keyword}`} className="modal fade delete-modal" role="dialog">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-body text-center">
                                                    
                                                        Are you sure you want mark as served?
                                                        <br/>
                                                        <br/>
                                                        {success &&
                                                            <div class="alert alert-success" role="alert">Successfully Served.</div>
                                                        }
                                                        {error?.serve &&
                                                            <div class="alert alert-danger" role="alert">Error serving. Try again</div>
                                                        }
                                                        <br/>
                                                        <div className="m-t-20">
                                                            <a href="#" className="btn btn-white" data-dismiss="modal">
                                                                Close
                                                            </a>
                                                            <button type="submit" className="btn btn-danger" onClick={() => handleServe(state.keyword)} disabled={loading || success}>
                                                                {loading?
                                                                    <><i className="fa fa-spinner fa-pulse"></i> loading...</>:<>Mark as served</>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pull-right">
                                            {searchResults[0]?.medication_served_by &&
                                                <div > Served By:&nbsp;&nbsp;
                                                    {searchResults[0]?.medication_served_by.avatar &&
                                                        <img width="28" height="28" src={searchResults[0]?.medication_served_by.avatar} className="rounded-circle m-r-5" alt="" />
                                                    }
                                                    {!searchResults[0]?.medication_served_by.avatar &&
                                                        <img width="28" height="28" src="/assets/images/user.jpg" className="rounded-circle m-r-5" alt="" />
                                                    }
                                                    {searchResults[0]?.medication_served_by.title} {searchResults[0]?.medication_served_by.first_name} {searchResults[0]?.medication_served_by.last_name}
                                                </div>
                                            }
                                        </div>

                                    </>
                                }
                                <hr/>

                                {loading?(
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="text-center">
                                                {loading &&
                                                    <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                    </div>
                                                }
                                                </div>
                                            </div>
                                        </div>  
                                    )
                                
                                    :(searchResults.length > 0 ?(
                                        <div>
                                            <header class="clearfix">

                                                <div id="logo">
                                                    <img src="/assets/images/au_tele.png"/>
                                                </div>

                                                <div id="company" class="doctor">
                                                    {searchResults[0]?.organization && <>
                                                        <h2 class="name">{searchResults[0]?.organization?.name}</h2>
                                                        <div>{searchResults[0]?.organization?.address}</div>
                                                        <div>{searchResults[0]?.professional?.phone_number}</div>
                                                        <div><a href={`mailto:${searchResults[0]?.organization?.email}`}>{searchResults[0]?.organization?.email}</a></div>
                                                        </>
                                                    }

                                                    {searchResults[0]?.professional && <>
                                                        <h2 class="name">Health Professional:</h2>
                                                        <h2 class="name">Dr {searchResults[0]?.professional?.first_name} {searchResults[0]?.professional?.last_name}</h2>
                                                        <div>{searchResults[0]?.professional?.phone_number} </div>
                                                        <div><a href={`mailto:${searchResults[0]?.professional?.email}`}>{searchResults[0]?.professional?.email} </a>
                                                        </div>
                                                        </>
                                                    }

                                                </div>
                                            </header>
                                            <main>
                                                <hr style={{ paddingBottom:"10px"}}/>
                                                <div id="details" class="clearfix">
                                                    <div id="client">
                                                        
                                                        <div class="to">Patient Information:</div>
                                                        <h2 class="name">Last Name:  {searchResults[0]?.patient?.last_name} </h2>
                                                        <h2 class="name">First name:  {searchResults[0]?.patient?.first_name} </h2>
                                                        <h2 class="name">Date of Birth: {searchResults[0]?.patient?.date_of_birth} </h2>
                                                        <h2 class="name">Sex:  {searchResults[0]?.patient?.gender} </h2>
                                                        <div class="name">Email:<a
                                                                href={`mailto:${searchResults[0]?.patient?.email}`}> {searchResults[0]?.patient?.email} </a>
                                                        </div>
                                                    </div>
                                                    
                                                    <div id="invoice">

                                                        <div class="date">Prescription Date:  {formatDatetime(searchResults[0]?.visit_date)} </div>
                                                        <div class="date">{search_type === 'lab'?<>Lab Request </>:<>Prescription</>}ID: {state.keyword || id}</div>
                                                    </div>
                                                </div>

                                                <div><h3 style={{textAlign: "center", fontSize: "40px"}}>{search_type ==="medication"?"Prescription Form":"Lab Request Form"}</h3></div>
                                                {search_type === "lab"?
                                                    <>
                                                        
                                                        <table border="0" cellspacing="0" cellpadding="0" className="pdf-table">
                                                            <thead>
                                                            <tr>
                                                                <th class="desc">CLINICAL SUMMARY AND DIAGNOSIS</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                {searchResults[0]?.lab_request &&
                                                                    <td class="desc">{searchResults[0]?.lab_request[0]}</td>
                                                                }
                                                                    
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </>
                                                    : 
                                                    <table border="0" cellspacing="0" cellpadding="0" className="pdf-table">
                                                        <thead>
                                                        <tr>
                                                            <th class="no">#</th>
                                                            <th class="desc">Medicine/Dose</th>
                                                            <th class="unit">Frequency</th>
                                                            <th class="total">No of Days</th>
                                                            <th class="unit">Route of Administration</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {searchResults[0]?.medication &&
                                                        <>
                                                        {searchResults[0]?.medication[0]?.details?.map((medication, index) =>(
                                                            <tr>
                                                                <td class="no"> {index+1} </td>
                                                                <td class="desc"> {medication.split(';')[0]} </td>
                                                                <td class="unit text-center"> {medication.split(';')[1]} </td>
                                                                <td class="total text-center">{medication.split(';')[2]} </td>
                                                                <td class="desc text-center"> {medication.split(';')[3]} </td>
                                                            </tr>
                                                        ))}
                                                        </>
                                                        }


                                                        </tbody>

                                                    </table>
                                                }
                                            </main>
                                            <footer>
                                                Electronically signed 
                                                {/* {searchResults[0]?.professional?.first_name} {searchResults[0]?.professional?.last_name}  */}
                                            </footer>
                                        </div>):
                                        (<div><h4 className="text-center">No Record Found</h4></div>)
                                    )     
                                }

                            </div>
                            

                        </div>
                    </div>
                </div>
            </main>
        </div>
        </div>
        
    </div>
  );
}


export default Search;
