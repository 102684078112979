import { connect } from 'react-redux';
import { fetchReferrals } from './duck/actions';
import ReferralsComponent from "./ReferralsPage";

const mapStateToProps = ({ agents: { agents } }) => {
  return {
    agents
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
    fetchReferrals: () => dispatch(fetchReferrals()),
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralsComponent);
