import axios from 'axios';
import Literals from '../../helpers/Literals'
import { authenticateRequest } from '../../helpers/utils';


export const fetchBilling = () => async dispatch => {
  dispatch({ type: 'FETCH_BILLING', success:null, error:null });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}web/backend/billing/?page=1&page_size=50&organization=false`);

    dispatch({ type: 'FETCH_BILLING_SUCCESS', payload: data.results, next_page:data.next, count:data.count});
  } catch (e) {
    authenticateRequest(e);
    dispatch({ type: 'FETCH_BILLING_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, error:null});
  }
};


export const fetchWebBilling = () => async dispatch => {
  dispatch({ type: 'FETCH_WEB_BILLING', web_success:null, web_error:null });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}web/backend/billing/?page=1&page_size=50&organization=true`);

    dispatch({ type: 'FETCH_WEB_BILLING_SUCCESS', payload: data.results, web_next_page:data.next, web_count:data.count});
  } catch (e) {
    authenticateRequest(e);
    dispatch({ type: 'FETCH_BILLING_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, error:null});
  }
};