import { connect } from 'react-redux';
import { fetchAgents, fetchUsers } from './duck/actions';
import AgentsComponent from "./AgentsPage";

const mapStateToProps = ({ agents: { agents } }) => {
  return {
    agents
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAgents: () => dispatch(fetchAgents()),
    fetchUsers: () => dispatch(fetchUsers()),
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentsComponent);
