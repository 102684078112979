import { connect } from 'react-redux';
import { getDoctor, getDoctorSchedules} from './duck/actions';
import { getPermissions } from '../Accounts/duck/actions';
import DoctorProfileComponent from './DoctorProfilePage';

const mapStateToProps = ({ doctor_profile: { doctor_profile } }) => {
  return {
    doctor_profile,
   
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDoctor: (id) => dispatch(getDoctor(id)),
   
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorProfileComponent);
