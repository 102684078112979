
import axios from 'axios'
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
    loading: false,
    callLogs: [],
    error: null,
  };
  
  function web_callLogs(state = INITIAL_STATE, action) {
    const { type, payload, next_page, count} = action;
      switch (type) {
        case 'FETCH_WEB_CALLS':
          return { ...state, loading: true };
        case 'FETCH_WEB_CALLS_SUCCESS':
            return { ...state, loading: false, callLogs: payload, success:null, error:null, next_page:next_page, count:count};
        case 'FETCH_WEB_CALLS_FAILED':
          return { ...state, loading: false, error: payload };
        case 'SEARCH_WEB_CALLS_SUCCESS':
          return { ...state, loading: false, callLogs: payload, error:null};
        case 'SEARCH_WEB_CALLS_FAILED':
          return { ...state, loading: false, error: payload, success:null};
        case 'LOAD_MORE_WEB_CALLS_SUCCESS':
            var callLogs = state.callLogs.concat(payload)
            return { ...state, loading: false, callLogs: callLogs, error: null, success: null, next_page:next_page};
        case 'LOAD_MORE_WEB_CALLS_FAILED':
            return { ...state, loading: false};
        default:
          return state;
      }
    }

    export const loadMoreWebCallLogs = api => async dispatch => {
        dispatch({ type: 'FETCH_WEB_CALLS' });
        try {
          const { data } = await axios.get(api);
      
          dispatch({ type: 'LOAD_MORE_WEB_CALLS_SUCCESS', payload: data.results, next_page:data.next});
        } catch (e) {
          dispatch({ type: 'LOAD_MORE_WEB_CALLS_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
        }
      };


      export const search_web_keyword = data => async dispatch => {
        try {
          var start_date = data.start_date?data.start_date:'';
          var end_date = data.end_date?data.end_date:'';
          var keyword = data.keyword?data.keyword:'';
          let response = await axios.get(`${Literals.BASE_API}records/call/backend/?page=1&page_size=50&start_date=${start_date}&end_date=${end_date}&organization=true&keyword=${keyword}`);
          document.getElementById("loader").classList.add("hide");
          dispatch({ type: 'SEARCH_WEB_CALLS_SUCCESS', payload: response.data.results});
        } catch (e) {
          document.getElementById("loader").classList.add("hide");
          dispatch({
            type: 'SEARCH_WEB_CALLS_FAILED',
            payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
          });
        }
      };
  
    export default web_callLogs;
  