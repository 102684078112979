import axios from 'axios';
import types from './types';
import Literals from '../../helpers/Literals'


export const fetchPatients = () => async dispatch => {
  dispatch({ type: types.FETCH_PATIENTS });
  try {
    
      var { data } = await axios.get(`${Literals.BASE_API}patients/web/backend/?page=1&page_size=50&organization=false`);
    
    dispatch({ type: types.FETCH_PATIENTS_SUCCESS, payload: data.results, next_page:data.next, count:data.count});
  } catch (e) {
    dispatch({ type: types.FETCH_PATIENTS_FAILURE, payload: e.response?e.response.data.details:Literals.ERROR_OCCURED });
  }
};


export const fetchWebPatients = () => async dispatch => {
  dispatch({ type: 'FETCH_WEB_PATIENTS' });
  try {
    
      var { data } = await axios.get(`${Literals.BASE_API}patients/web/backend/?page=1&page_size=100&organization=true`);
    
    dispatch({ type: 'FETCH_WEB_PATIENTS_SUCCESS', payload: data.results, web_next_page:data.next, web_count:data.count});
  } catch (e) {
    dispatch({ type: 'FETCH_WEB_PATIENTS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED });
  }
};

export const fetchSubscribedPatients = () => async dispatch => {
  dispatch({ type: 'FETCH_SUBSCRIBED_PATIENTS' });
  try {
    
      var { data } = await axios.get(`${Literals.BASE_API}patients/web/backend/?page=1&page_size=100&organization=false&subscribed=true`);
    
    dispatch({ type: 'FETCH_SUBSCRIBED_PATIENTS_SUCCESS', payload: data.results, next_page:data.next});
  } catch (e) {
    dispatch({ type: 'FETCH_SUBSCRIBED_PATIENTS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED });
  }
};


export const getPatient = (id) => async dispatch => {
  console.log('idd....',id)
  dispatch({ type: 'FETCH_PATIENT_PROFILE' });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}patients/web/backend/${id}/`);
    dispatch({ type: 'FETCH_PATIENT_PROFILE_SUCCESS', payload: data.results });
  } catch (e) {
    dispatch({ type: 'FETCH_PATIENT_PROFILE_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED });
  }
};
export const fetchVisits = (id) => async dispatch => {
  dispatch({ type: 'FETCH_VISITS' });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}records/appointments/visit/backend/?patient_id=${id}&page=1&page_size=50`);

    dispatch({ type: 'FETCH_VISITS_SUCCESS', payload: data.results });
  } catch (e) {
    dispatch({ type: 'FETCH_VISITS_FAILURE', payload: e.response });
  }
};


export const fetchPharmacy = (id) => async dispatch => {
  dispatch({ type: 'FETCH_PHARMACY' });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}records/appointments/visit/backend/?patient_id=${id}&page=1&page_size=100&pharmacy`);

    dispatch({ type: 'FETCH_PHARMACY_SUCCESS', payload: data.results });
  } catch (e) {
    dispatch({ type: 'FETCH_PHARMACY_FAILURE', payload: e.response });
  }
};