import { connect } from 'react-redux';
import { fetchPatients } from './duck/actions';
import { fetchWebPatients, fetchSubscribedPatients} from './duck/actions';
import PatientComponent from './PatientsPage';

const mapStateToProps = ({ patients: { patients }, web_patients: { web_patients }, subscribed_patients:{subscribed_patients} }) => {
  return {
    patients,
    web_patients,
    subscribed_patients

  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPatients: () => dispatch(fetchPatients()),
    fetchWebPatients: () => dispatch(fetchWebPatients()),
    fetchSubscribedPatients:() => dispatch(fetchSubscribedPatients())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientComponent);
