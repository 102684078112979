// /* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, withRouter, Route, Redirect } from 'react-router-dom';
import { authorized, unauthorized } from './routeList';
import AuthRoute from './AuthRoute';
import { getUser } from '../auth/ducks/reducer';
import LoadingPage from '../auth/LoadingPage';
import LoginComponent from '../auth/LoginPage';


function Routes({ history, user }) {
  useEffect(() => {
    getUser();
  }, []);

  return (
    <Fragment>
        <Switch>
          {/* {authorized.map(route => (
            <AuthRoute user={user} path={route.path} key={route.path}  component={route.component} />
          ))} */}
          <Route exact path="/" render={props => !user ? <LoginComponent /> : <Redirect  to={{pathname: '/dashboard',state: {from: props.location}}}/>}/>
          <Route exact path="/login" render={props => !user ? <LoginComponent /> : <Redirect  to={{pathname: '/dashboard',state: {from: props.location}}}/>} />

          {user && (
            <Fragment>
              {authorized.map(route => (
                <AuthRoute user={user} path={route.path} key={route.path}  component={route.component} />
              ))}
            </Fragment>
          )}
          
          <Route component={LoadingPage}  />
        </Switch>
    </Fragment>

      
  );
}

Routes.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});
export default connect(mapStateToProps, { getUser })(withRouter(Routes));
