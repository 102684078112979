import React, {useState, Fragment} from 'react';
import Menu from '../Menu/MenuComponent'
import Topnav from '../Menu/TopnavComponent'
import {connect} from 'react-redux'
import {create_admin} from './duck/reducer'

function AddAdminComponent({ create_admin, error, success}) {
    


      const [state, setState] = useState({});

      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
      }
      
    
      function handleSubmit(e) {
        e.preventDefault();
        // permission
        var facilities_read = document.getElementById("facilities_read").checked;
        var facilities_write = document.getElementById("facilities_write").checked;
        var admins_read = document.getElementById("admins_read").checked;
        var admins_write = document.getElementById("admins_write").checked;
        var agents_read = document.getElementById("agents_read").checked;
        var agents_write = document.getElementById("agents_write").checked;
        var referrals_read = document.getElementById("referrals_read").checked;
        var referrals_write = document.getElementById("referrals_write").checked;
        var subscriptions_read = document.getElementById("subscriptions_read").checked;
        var subscriptions_write = document.getElementById("subscriptions_write").checked;
        var professionals_read = document.getElementById("professionals_read").checked;
        var professionals_write = document.getElementById("professionals_write").checked;
        var patients_read = document.getElementById("patients_read").checked;
        var patients_write = document.getElementById("patients_write").checked;
        var appointments_read = document.getElementById("appointments_read").checked;
        var appointments_write = document.getElementById("appointments_write").checked;
        var billing_read = document.getElementById("billing_read").checked;
        var billing_write = document.getElementById("billing_write").checked;
        var calls_read = document.getElementById("calls_read").checked;
        var calls_write = document.getElementById("calls_write").checked;
        var specialites_read = document.getElementById("specialites_read").checked;
        var specialites_write = document.getElementById("specialites_write").checked;
        var languages_read = document.getElementById("languages_read").checked;
        var languages_write = document.getElementById("languages_write").checked;
        var pharmacy_read = document.getElementById("pharmacy_read").checked;
        var pharmacy_write = document.getElementById("pharmacy_write").checked;
        var lab_read = document.getElementById("lab_read").checked;
        var lab_write = document.getElementById("lab_write").checked;


        var permission_data = {permissions:{
                                facilities:{read:facilities_read, write:facilities_write},
                                admins:{read:admins_read, write:admins_write},
                                agents:{read:agents_read, write:agents_write},
                                referrals:{read:referrals_read, write:referrals_write},
                                subscriptions:{read:subscriptions_read, write:subscriptions_write},
                                professionals:{read:professionals_read, write:professionals_write},
                                patients:{read:patients_read, write:patients_write},
                                appointments:{read:appointments_read, write:appointments_write},
                                billing:{read:billing_read, write:billing_write},
                                calls:{read:calls_read, write:calls_write},
                                specialites:{read:specialites_read, write:specialites_write},
                                languages:{read:languages_read, write:languages_write},
                                pharmacy:{read:pharmacy_read, write:pharmacy_write},
                                lab:{read:lab_read, write:lab_write}
                                }}
        
        var full_admin_data = {admin_data:state, admin_permissions:permission_data}

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        create_admin(full_admin_data);
      } 

  return (
    <div class="c-app c-legacy-theme c-no-layout-transition" >
            <Menu/>
            <div class="c-wrapper">
                <Topnav/>
                <div class="c-body">
                    <main class="c-main">
                        <div class="container-fluid">
                            <div class="fade-in">
                <div className="card">
                    <div className="card-body">
                        <div className="card-title">
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2">
                                    
                                    {error &&
                                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button> */}
                                            <a className="text-center">{error}</a>
                                        </div>
                                    }
                                    {success &&
                                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                                            {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button> */}
                                            <a className="text-center">created successfully</a>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card-subtitle">
                            {/* <h4 className="page-title">Add Admin </h4> */}

                        </div>
                        <form id="create-account-form">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                <small><code><strong>Note!</strong>: All * fields are required fields</code></small>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>First Name <span className="text-danger">*</span></label>
                                                <input className="form-control" type="text" name="first_name" onChange={handleChange} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Last Name <span className="text-danger">*</span></label>
                                                <input className="form-control" type="text" name="last_name" onChange={handleChange} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Username <span className="text-danger">*</span></label>
                                                <input className="form-control" type="text" name="username" onChange={handleChange}required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Email <span className="text-danger">*</span></label>
                                                <input className="form-control" type="email" name="email" onChange={handleChange} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Password <span className="text-danger">*</span></label>
                                                <input className="form-control" type="password" name="password" onChange={handleChange} required/>
                                                {state.password_again &&
                                                    <div>
                                                        {state.password !== state.password_again &&
                                                        <small><code><strong>Note!</strong>: Mismatched passwords</code></small>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Confirm Password <span className="text-danger">*</span></label>
                                                <input className="form-control" type="password" name="password_again" onChange={handleChange} required/>
                                                {state.password_again &&
                                                    <div>
                                                        {state.password !== state.password_again &&
                                                        <small><code><strong>Note!</strong>: Mismatched passwords</code></small>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Phone <span className="text-danger">*</span></label>
                                                <input className="form-control" type="text" name="phone_number" onChange={handleChange} required/>
                                            </div>
                                        </div>
                                        
                                       
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="table-responsive">
                                    <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Module Permission</th>
                                                    <th>Read</th>
                                                    <th >Write</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Facilities </td>
                                                    <td >
                                                        <input type="checkbox" name="facilities_read" id="facilities_read"/>
                                                    </td>
                                                    <td >
                                                        <input type="checkbox" name="facilities_write" id="facilities_write"/>
                                                    </td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>Admins </td>
                                                    <td >
                                                        <input type="checkbox" name="admins_read" id="admins_read"/>
                                                    </td>
                                                    <td >
                                                        <input type="checkbox" name="admins_write" id="admins_write"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Agents </td>
                                                    <td >
                                                        <input type="checkbox" name="agents_read" id="agents_read"/>
                                                    </td>
                                                    <td >
                                                        <input type="checkbox" name="agents_write" id="agents_write"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Referrals</td>
                                                    <td >
                                                        <input type="checkbox" name="referrals_read" id="referrals_read"/>
                                                    </td>
                                                    <td >
                                                        <input type="checkbox" name="referrals_write" id="referrals_write"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Subscriptions</td>
                                                    <td >
                                                        <input type="checkbox" name="subscriptions_read" id="subscriptions_read"/>
                                                    </td>
                                                    <td >
                                                        <input type="checkbox" name="subscriptions_write" id="subscriptions_write"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Professionals</td>
                                                    <td >
                                                        <input type="checkbox" name="professionals_read" id="professionals_read"/>
                                                    </td>
                                                    <td >
                                                        <input type="checkbox" name="professionals_write" id="professionals_write"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Patients</td>
                                                    <td >
                                                        <input type="checkbox" name="patients_read" id="patients_read"/>
                                                    </td>
                                                    <td >
                                                        <input type="checkbox" name="patients_write" id="patients_write"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Appointments</td>
                                                    <td >
                                                        <input type="checkbox" name="appointments_read" id="appointments_read"/>
                                                    </td>
                                                    <td >
                                                        <input type="checkbox" name="appointments_write" id="appointments_write"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Billing</td>
                                                    <td >
                                                        <input type="checkbox" name="billing_read" id="billing_read"/>
                                                    </td>
                                                    <td>
                                                        <input type="checkbox" name="billing_write" id="billing_write"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Call Logs</td>
                                                    <td >
                                                        <input type="checkbox" name="calls_read" id="calls_read"/>
                                                    </td>
                                                    <td >
                                                        <input type="checkbox" name="calls_write" id="calls_write"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Specialites</td>
                                                    <td >
                                                        <input type="checkbox" name="specialites_read" id="specialites_read"/>
                                                    </td>
                                                    <td >
                                                        <input type="checkbox" name="specialites_write" id="specialites_write"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Languages</td>
                                                    <td >
                                                        <input type="checkbox" name="languages_read" id="languages_read"/>
                                                    </td>
                                                    <td >
                                                        <input type="checkbox" name="languages_write" id="languages_write"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Pharmacy</td>
                                                    <td >
                                                        <input type="checkbox" name="pharmacy_read" id="pharmacy_read"/>
                                                    </td>
                                                    <td >
                                                        <input type="checkbox" name="pharmacy_write" id="pharmacy_write"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Lab</td>
                                                    <td >
                                                        <input type="checkbox" name="lab_read" id="lab_read"/>
                                                    </td>
                                                    <td >
                                                        <input type="checkbox" name="lab_write" id="lab_write"/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="m-t-20 text-center">
                            {state.email && state.username && state.first_name && state.last_name && state.phone_number && (state.password == state.password_again) &&
                                <button className="btn btn-primary submit-btn" type="submit" onClick={handleSubmit} >Create Admin</button>
                            }
                            </div>
                        </form>
                    </div>
                </div>
                </div>
            </div>
            </main>
            </div>
            
        </div>
    </div>
  );
}

function mapStateToProps({accounts}){
    return {error:accounts.error, success:accounts.success};
    
  }
  
export default connect(mapStateToProps, { create_admin })(AddAdminComponent);
