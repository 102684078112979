import { connect } from 'react-redux';
import { fetchSpeciality } from '../ducks/actions';
import SpecialityComponent from './SpecialityPage';

const mapStateToProps = ({ specialities: { specialities } }) => {
  return {
    specialities,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSpeciality: () => dispatch(fetchSpeciality()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialityComponent);
