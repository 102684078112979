import React, {useEffect, Fragment} from 'react';
import Menu from '../Menu/MenuComponent'
import Topnav from '../Menu/TopnavComponent'
import { formatTime} from '../helpers/utils';



function DoctorProfileComponent({ getDoctor, doctor_profile = [] }) {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');


    useEffect(() => {
        getDoctor(id);
    }, [getDoctor]);

  return (
    <div class="c-app c-legacy-theme c-no-layout-transition" >
            <Menu/>
            <div class="c-wrapper">
                <Topnav/>
                <div class="c-body">
                    <main class="c-main">
                        <div class="container-fluid">
                            <div class="fade-in">
                                <div class="row">
                                    <div class="col-lg-8 col-xlg-8 col-md-8 offset-md-2">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <center class="mt-4"> 
                                                            {!doctor_profile.avatar &&
                                                                <img class="rounded-circle" src="assets/images/user.jpg" style={{width:"150px"}}  alt=""/>
                                                            }
                                                            {doctor_profile.avatar &&
                                                                <img className="rounded-circle" src={doctor_profile.avatar} style={{width:"150px", height:"150px"}} alt=""/>
                                                            }
                                                            <h4 class="card-title mt-2">{doctor_profile.first_name} {doctor_profile.last_name}</h4>
                                                            <h6 class="card-subtitle"><em>{doctor_profile.is_verified && <Fragment>
                                                                    <img width="20" height="20" src="assets/images/verified.jpg" className="rounded-circle m-r-5" alt=""/>&nbsp;Verified</Fragment>
                                                                }
                                                                {!doctor_profile.is_verified && <Fragment>
                                                                    <img width="20" height="20" src="assets/images/sent.png" className="m-r-5" alt=""/>&nbsp;Unverified</Fragment>
                                                                }
                                                                
                                                                </em>
                                                            </h6>
                                                            {/* <div class="row text-center justify-content-md-center">
                                                                <div class="col-4"><a href="javascript:void(0)" class="link"><i class="icon-people"></i> <font class="font-medium">254</font></a></div>
                                                                <div class="col-4"><a href="javascript:void(0)" class="link"><i class="icon-picture"></i> <font class="font-medium">54</font></a></div>
                                                            </div> */}
                                                            
                                                             <hr/>
                                                             <h5 class="text-muted">License ID</h5>
                                                            {doctor_profile.license_file &&
                                                                <img className="img-thumbnail" src={doctor_profile.license_file} style={{width:"280px", height:"280px"}} alt=""/>
                                                            }
                                                            {!doctor_profile.license_file &&
                                                                <img className="img-thumbnail" src="assets/images/placeholder.jpg" style={{width:"280px", height:"280px"}} alt=""/>
                                                            }
                                                            {/* {doctor_profile.available_time && <Fragment>
                                                            <hr/>
                                                            <h5 class="text-muted">Available Time</h5>
                                                            <ul>
                                                                {doctor_profile.available_time.map(available_time =>(
                                                                    <li>
                                                                    <a className="dropdown-item" ><span className="badge badge-primary">{available_time.day}</span>&nbsp;<span className="badge badge-dark">{formatTime(available_time.from_time)}-{formatTime(available_time.to_time)}</span>&nbsp;<span className="badge badge-light">GHS {available_time.amount_charged}</span></a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                            </Fragment>
                                                            } */}
                                                        </center>
                                                       
                                                       
                                                    </div>
                                                    <div class="col-md-7">
                                                        
                                                        <small class="text-muted">Email address </small>
                                                        <h6>{doctor_profile.email}</h6>
                                                        <hr/>
                                                         <small class="text-muted pt-4 db">Phone number</small>
                                                        <h6>{doctor_profile.phone_number}</h6> 
                                                        <hr/>
                                                        <small class="text-muted pt-4 db">Phone number 2</small>
                                                        <h6>{doctor_profile.phone_number_2}</h6> 
                                                        <hr/>
                                                        <small class="text-muted">Date of birth </small>
                                                        <h6>{doctor_profile.date_of_birth}</h6> 
                                                        <hr/>
                                                        <small class="text-muted">Gender </small>
                                                        <h6>{doctor_profile.gender}</h6> 
                                                        <hr/>
                                                        <small class="text-muted pt-4 db">Organisation</small>
                                                        <h6>{doctor_profile.organization && <span class="badge badge-success">{doctor_profile.organization.name}</span>}</h6>
                                                        <hr/>
                                                        <small class="text-muted pt-4 db">Speciality</small>
                                                        <h6>{doctor_profile.speciality && <Fragment>
                                                                {doctor_profile.speciality.map(speciality =>(
                                                                    <Fragment>
                                                                        <span className="badge badge-pill badge-warning" key={speciality}>
                                                                        {speciality}
                                                                        </span>&nbsp;
                                                                    </Fragment>
                                                                ))}</Fragment>}
                                                        </h6>
                                                        <hr/>
                                                        <small class="text-muted">Practice number</small>
                                                        <h6>{doctor_profile.practice_number}</h6> 
                                                        <hr/>
                                                        <small class="text-muted">National ID number</small>
                                                        <h6>{doctor_profile.national_id_number}</h6> 
                                                        <hr/>
                                                        <small class="text-muted">Languages</small>
                                                        <h6>{doctor_profile.languages}</h6> 
                                                        <hr/>
                                                        <small class="text-muted">Area</small>
                                                        <h6>{doctor_profile.area}</h6> 
                                                        <hr/>
                                                        <small class="text-muted">Region</small>
                                                        <h6>{doctor_profile.region}</h6> 
                                                        

                                                    </div>
                                                </div>
                                            </div>
                                            
                                           
                                        </div>
                                    </div>
                                    </div>
                </div>
                </div>
            </main>
        </div>
        </div>
    </div>
  );
}

export default DoctorProfileComponent;
