import axios from 'axios';
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
  loading: false,
  accounts: [],
  error: null,
  success:null
};


export const create_admin = data => async dispatch => {
  try {
    // var organisation_id = window.localStorage.getItem('organisation_id');
    var admin_data = data.admin_data
    var admin_permissions = data.admin_permissions
    // admin_data['organization_id']=organisation_id;
    // admin_data['country']='Ghana';
    let admin = await axios.post(`${Literals.BASE_API}users/backend/`, admin_data);
    
    // set user permissions
    var admin_id = admin.data.results.id
    let admin_access = await axios.post(`${Literals.BASE_API}users/backend/config/?send_reminders&user_id=${admin_id}`, admin_permissions);


    dispatch({ type: 'CREATE_ACCOUNT_SUCCESS', payload: admin.data, success:'Created successfully'});
    document.getElementById("create-account-form").reset();

  } catch (e){
    dispatch({
      type: 'CREATE_ACCOUNT_FAILURE',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};
export const deactivate_admin = id => async dispatch => {
  try {
    let admin = await axios.post(`${Literals.BASE_API}users/backend/${id}/deactivate/`);
    dispatch({ type: 'DELETE_ACCOUNT_SUCCESS', payload: admin.data });
  } catch (e){
    dispatch({
      type:'DELETE_ACCOUNT_FAILURE',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const create_permission_access = data => async dispatch => {
  try {
    var user_id = data.user_id
    delete data.user_id
    let admin = await axios.post(`${Literals.BASE_API}users/backend/config/?send_reminders&user_id=${user_id}`, data);
    dispatch({ type: 'CREATE_PERMISSION_SUCCESS', payload: admin.data });
  } catch (e){
    dispatch({
      type:'CREATE_PERMISSION_FAILURE',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const loadMoreAdmins = api => async dispatch => {
  dispatch({ type: 'FETCH_ACCOUNTS' });
  try {
    const { data } = await axios.get(api);

    dispatch({ type: 'LOAD_MORE_SUCCESS', payload: data.results, next_page:data.next});
  } catch (e) {
    dispatch({ type: 'LOAD_MORE_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};

export const search_keyword = data => async dispatch => {

  try {
    var keyword = data.keyword?data.keyword:'';
    let response = await axios.get(`${Literals.BASE_API}users/backend/?page=1&page_size=50&keyword=${keyword}`);
    dispatch({ type: 'SEARCH_ACCOUNT_SUCCESS', payload: response.data.results});
  } catch (e) {
    dispatch({
      type: 'SEARCH_ACCOUNT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};









// export const admin_account_profile = id => async dispatch => {
//   try {
//     let admin = await axios.post(`https://ghhis.herokuapp.com/api/v1/users/web/${id}/`);

//     console.log('admin data', admin)
//     dispatch({ type: types.SUCCESS, payload: admin.data });
//     // window.location.href = '/admins';
//   } catch (error){
//     dispatch({
//       type: types.SUCCESS,
//       payload: error.response.data.details,
//     });
//   }
// };

function accounts(state = INITIAL_STATE, action) {
  const { type, payload, next_page, count } = action;
  switch (type) {
    case 'FETCH_ACCOUNTS':
      return { ...state, loading: true };
    case 'FETCH_ACCOUNTS_SUCCESS':
      return { ...state, loading: false, accounts: payload, error: null, success: null, next_page:next_page, count:count};
    case 'FETCH_ACCOUNTS_FAILURE':
      return { ...state, loading: false, error: payload, success: null };
    case 'CREATE_ACCOUNT_SUCCESS':
        return { ...state, loading: false, success:'Created successfully', error: null};
    case 'CREATE_ACCOUNT_FAILURE':
        return { ...state, loading: false, error: payload, success: null};
    case 'DELETE_ACCOUNT_SUCCESS':
          return { ...state, loading: false, success: 'Account deactivated successfully', error: null, };
    case 'DELETE_ACCOUNT_FAILURE':
          return { ...state, loading: false, error: payload, success: false};
    case 'CREATE_PERMISSION_SUCCESS':
          return { ...state, loading: false, success: 'Permissions saved successfully', error: null, };
    case 'CREATE_PERMISSION_FAILURE':
          return { ...state, loading: false, error: payload, success: false};
    case 'LOAD_MORE_SUCCESS':
          var accounts = state.accounts.concat(payload)
        return { ...state, loading: false, accounts: accounts, error: null, success: null, next_page:next_page};
    case 'LOAD_MORE_FAILURE':
        return { ...state, loading: false};
    case 'SEARCH_ACCOUNT_SUCCESS':
      return { ...state, loading: false, accounts: payload};
    case 'SEARCH_ACCOUNT_FAILED':
      return { ...state, loading: false, error: payload};

    default:
      return state;
  }
}



export default accounts;
