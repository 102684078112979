import types from './types';
import axios from 'axios'
import Literals from '../../helpers/Literals'

const INITIAL_STATE = {
  loading: false,
  appointments: [],
  error: null,
  success:null,
};

function appointments(state = INITIAL_STATE, action) {
  const { type, payload, next_page, count, index} = action;
  switch (type) {
    case 'FETCH_APPOINTMENTS':
      return { ...state, loading: true, success:null, error:null};
    case 'FETCH_APPOINTMENTS_SUCCESS':
      return { ...state, loading: false, appointments: payload, success:null, error:null, next_page:next_page, count:count};
    case 'FETCH_APPOINTMENTS_FAILURE':
      return { ...state, loading: false, error: payload, success:null };
    case 'CREATE_APPOINTMENT_SUCCESS':
      return { ...state, loading: false, appointment: payload, success:'Created successfully', error:null};
    case 'CREATE_APPOINTMENT_FAILED':
      return { ...state, loading: false, error: payload, success:null};
    case 'DELETE_APPOINTMENT_SUCCESS':
      return { ...state, loading: false, appointment: payload, success:'Deleted successfully', error:null};
    case 'DELETE_APPOINTMENT_FAILED':
      return { ...state, loading: false, error: payload, success:null};

    case 'SEARCH_APPOINTMENT_SUCCESS':
      return { ...state, loading: false, appointments: payload, error:null};
    case 'SEARCH_APPOINTMENT_FAILED':
      return { ...state, loading: false, error: payload, success:null};

    case 'APPROVE_APPOINTMENT_SUCCESS':
      return { ...state, loading: false, appointment: payload, success:'Approved successfully', error:null};
    case 'APPROVE_APPOINTMENT_FAILED':
      return { ...state, loading: false, error: payload};

    case 'EXPORT_APPOINTMENT':
        return { ...state, loading: true, success:null, error:null};
    case 'EXPORT_APPOINTMENT_SUCCESS':
      return { ...state, loading: false, appointment: payload, success:payload.results.url, error:null};
    case 'EXPORT_APPOINTMENT_FAILED':
      return { ...state, loading: false, error: payload};

    case 'REJECT_APPOINTMENT_SUCCESS':
      return { ...state, loading: false, appointment: payload, success:'Rejected successfully', error:null};
    case 'REJECT_APPOINTMENT_FAILED':
      return { ...state, loading: false, error: payload};
    
    case 'REVERT_COMPLETED':
      return { ...state, loading: true, success:null, error:null};
    case 'REVERT_COMPLETED_SUCCESS':
      state.appointments[index] = payload
      return { ...state, loading: false, appointments: state.appointments};
    case 'REVERT_COMPLETED_FAILED':
      return { ...state, loading: false, error: payload};
      
    case 'SEND_NOTIFICATION_SUCCESS':
      return { ...state, loading: false, appointment: payload};
    case 'SEND_NOTIFICATIONT_FAILED':
      return { ...state, loading: false, error: payload};

    case 'COMPLETE_APPOINTMENT_SUCCESS':
      return { ...state, loading: false, appointment: payload, success:'Appointment completed successfully'};
    case 'COMPLETE_APPOINTMENT_FAILED':
      return { ...state, loading: false, error: payload};

    case 'ASSIGN_APPOINTMENT_SUCCESS':
      return { ...state, loading: false, appointments: payload, success:'Appointment assigned successfully'};
    case 'ASSIGN_APPOINTMENT_FAILED':
      return { ...state, loading: false, error: payload};

    case 'LOAD_MORE_APPOINTMENTS_SUCCESS':
      var appointments = state.appointments.concat(payload)
      return { ...state, loading: false, appointments: appointments, error: null, success: null, next_page:next_page};
    case 'LOAD_MORE_APPOINTMENTS_FAILURE':
      return { ...state, loading: false};

    default:
      return state;
  }
}

export const create_appointment = data => async dispatch => {
  try {
    var date = data['date']?data['date']:'';
    var time = data['time']?data['time']:'';
    data['start_date']=date+" "+time;

    var organisation_id = localStorage.getItem('organisation_id')
    data['organization_id']=organisation_id
    

    let appointment = await axios.post(`${Literals.BASE_API}records/appointments/`, data);
    dispatch({ type: 'CREATE_APPOINTMENT_SUCCESS', payload: appointment.data, success:true});
    document.getElementById("appointment-form").reset();
  } catch (e){
    dispatch({
      type: 'CREATE_APPOINTMENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const delete_appointment = id => async dispatch => {
  try {
    let appointment = await axios.delete(`${Literals.BASE_API}records/appointments/${id}/`);
    dispatch({ type: 'DELETE_APPOINTMENT_SUCCESS', payload: appointment.data, success:'Deleted successfully'});
  } catch (e){
    dispatch({
      type: 'DELETE_APPOINTMENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const export_appointment = data => async dispatch => {
  dispatch({ type: 'EXPORT_APPOINTMENT', success:null, error:null });
  try {
    var keyword = data.keyword?data.keyword:'';
    var start_date = data.start_date?data.start_date:'';
    var end_date = data.end_date?data.end_date:'';
    let appointment = await axios.get(`${Literals.BASE_API}records/appointments/backend/?export&organization=false&keyword=${keyword}&start_date=${start_date}&end_date=${end_date}`);
    dispatch({ type: 'EXPORT_APPOINTMENT_SUCCESS', payload: appointment.data});
  } catch (e){
    dispatch({
      type: 'EXPORT_APPOINTMENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const approve_appointment = id => async dispatch => {
  try {
    var data = {value:true}
    let appointment = await axios.post(`${Literals.BASE_API}records/appointments/${id}/confirm/`, data);
    dispatch({ type: 'APPROVE_APPOINTMENT_SUCCESS', payload: appointment.data});
  } catch (e){
    dispatch({
      type: 'APPROVE_APPOINTMENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const reject_appointment = id => async dispatch => {
  try {
    var data = {value:false}
    let appointment = await axios.post(`${Literals.BASE_API}records/appointments/${id}/confirm/`, data);
    dispatch({ type: 'REJECT_APPOINTMENT_SUCCESS', payload: appointment.data});
  } catch (e){
    dispatch({
      type: 'REJECT_APPOINTMENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const search_keyword = data => async dispatch => {
  try {
    var keyword = data.keyword?data.keyword:'';
    var start_date = data.start_date?data.start_date:'';
    var end_date = data.end_date?data.end_date:'';
    let response = await axios.get(`${Literals.BASE_API}records/appointments/backend/?page=1&page_size=50&keyword=${keyword}&start_date=${start_date}&end_date=${end_date}&is_complete=${data.is_complete}`);
    document.getElementById("loader").classList.add("hide");
    dispatch({ type: 'SEARCH_APPOINTMENT_SUCCESS', payload: response.data.results});
  } catch (e) {
    document.getElementById("loader").classList.add("hide");
    dispatch({
      type: 'SEARCH_APPOINTMENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const send_call_notification = data => async dispatch => {
  try {
    let appointment = await axios.post(`${Literals.BASE_API}users/account/notification/`, data);
    dispatch({ type: 'SEND_NOTIFICATION_SUCCESS', payload: appointment.data, success:true});
  } catch (e){
    dispatch({
      type: 'SEND_NOTIFICATIONT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const complete_appointment = id => async dispatch => {
  try {
    var data = {remarks:''}
    let appointment = await axios.put(`${Literals.BASE_API}records/appointments/${id}/complete/`,data);
    dispatch({ type: 'COMPLETE_APPOINTMENT_SUCCESS', payload: appointment.data, success:true});
  } catch (e){
    dispatch({
      type: 'COMPLETE_APPOINTMENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const assign_appointment = data => async dispatch => {
  try {
    var appointment_id = data.appointment_id
    var professional_id = data.professional_id
    var start_date = data.start_date
    var payload_data = {professional_id:professional_id, start_date:start_date}
    let appointment = await axios.put(`${Literals.BASE_API}records/appointments/backend/${appointment_id}/assign/`, payload_data);
    
    document.getElementById("assign-loader"+appointment_id).classList.add("hide");
    document.getElementById("assign-button"+appointment_id).innerHTML = "Sucessfully re-assigned";
    let appointments = await axios.get(`${Literals.BASE_API}records/appointments/backend/?page=1&page_size=50&organization=false`);
    dispatch({ type: 'ASSIGN_APPOINTMENT_SUCCESS', payload: appointments.data.results, success:true});
  } catch (e){
    document.getElementById("assign-loader"+appointment_id).classList.add("hide");
    document.getElementById("assign-button"+appointment_id).innerHTML = "Error occured";
    dispatch({  
      type: 'ASSIGN_APPOINTMENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const loadMoreAppointments = api => async dispatch => {
  dispatch({ type: types.FETCH_APPOINTMENTS });
  try {
    const { data } = await axios.get(api);

    dispatch({ type: 'LOAD_MORE_APPOINTMENTS_SUCCESS', payload: data.results, next_page:data.next});
  } catch (e) {
    dispatch({ type: 'LOAD_MORE_APPOINTMENTS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};

export const revert_completed = (id, index) => async dispatch =>{
  dispatch({ type: 'REVERT_COMPLETED' });
  try{
    var payload = {"is_complete":false}
    const { data } = await axios.put(`${Literals.BASE_API}records/appointments/backend/${id}/set_status/`, payload);
    document.getElementById("revert-loader"+id)?.classList?.add("hide");
    document.getElementById("revert-button"+id).innerHTML = "Reverted Successfully";
    dispatch({ type: 'REVERT_COMPLETED_SUCCESS', payload: data.results, success:true, index:index});
  }catch (e) {
    document.getElementById("revert-loader"+id)?.classList?.add("hide");
    document.getElementById("revert-button"+id).innerHTML = "Error occured try again";
    dispatch({ type: 'REVERT_COMPLETED_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });

  }
}


export default appointments;
