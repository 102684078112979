import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Literals from '../helpers/Literals';
import {TableData, tableThemes} from '../helpers/utils';


class Topnav extends Component {

    
    
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            users: [],
            tableData: JSON.parse(localStorage.getItem('tableDataThemes')) || tableThemes.scroll,
        };
    }
    
    componentDidMount() {
        
        this.setState({ 
            user: JSON.parse(localStorage.getItem('user')),
            users: { loading: true },
        });
    }
    async handleLogout() {
        try{
            await axios.post(`${Literals.BASE_API}users/backend/logout/`);
            localStorage.clear();
            window.location.reload()
        }catch(e){
            localStorage.clear();
            window.location.reload()
        } 
    }
    
    render(){
        
        const { user } = this.state;
        var location = window.location.pathname;

        // const admin_permissions = JSON.parse(localStorage.getItem('permissions'))?JSON.parse(localStorage.getItem('permissions')):{permissions:null};
        // if(admin_permissions.permissions){
        //     var settings_read = admin_permissions.permissions.settings.read
        // }else{
        //     var settings_read = true
        // }

        this.toggleTheme = () => {
            this.state.tableData = this.state.tableData == tableThemes.scroll? tableThemes.paginate: tableThemes.scroll
           
            localStorage.setItem('tableDataThemes', JSON.stringify(this.state.tableData))

          };
        
        return (
          
        <header className="c-header c-header-light c-header-fixed">
          
            <button className="c-header-toggler c-class-toggler d-lg-none mfe-auto" type="button" data-target="#sidebar" data-class="c-sidebar-show">
                <svg className="c-icon c-icon-lg"></svg>
            </button>
            <a className="c-header-brand d-lg-none c-header-brand-sm-up-center" href="#">
                <svg width="118" height="46" alt="CoreUI Logo"></svg>
            </a>
            <button className="c-header-toggler c-class-toggler mfs-3 d-md-down-none" type="button" data-target="#sidebar" data-class="c-sidebar-lg-show" responsive="true">
                <div className="c-icon c-icon-lg"><i className="fa fa-bars"></i></div>
            </button>
            
            {/* <ul class="c-header-nav d-md-down-none">
                <li class="c-header-nav-item px-3"><a class="c-header-nav-link" href="#">Dashboard</a></li>
                <li class="c-header-nav-item px-3"><a class="c-header-nav-link" href="#">Users</a></li>
                <li class="c-header-nav-item px-3"><a class="c-header-nav-link" href="#">Settings</a></li>
            </ul> */}
            <ul className="c-header-nav mfs-auto">
                <li className="c-header-nav-item px-3 c-d-legacy-none">
                    <button className="c-class-toggler c-header-nav-btn" type="button" id="header-tooltip" data-target="body" data-class="c-dark-theme" data-toggle="c-tooltip" data-placement="bottom" title="Toggle Light/Dark Mode">
                    <div className="c-icon c-d-dark-none"><i className="fa fa-bar"></i></div>
                    <div className="c-icon c-d-default-none"><i className="fa fa-bar"></i></div>
                    </button>
                </li>
            </ul>
            <ul className="c-header-nav">
            
                <li className="c-header-nav-item dropdown" style={{marginRight: "90px"}}>
                    <a className="c-header-nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                        <div className="c-avatar">
                            <img className="c-avatar-img" src={user.avatar?user.avatar:'/assets/images/user.jpg'} alt="avatar"/>&nbsp;{user.username}
                        </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right pt-0">
                        <div className="dropdown-header bg-light py-2">
                            <strong>Account</strong>
                        </div>
                        <Link className="dropdown-item" to="profile">
                            <div className="c-icon mfe-2"></div>
                            <i className="fa fa-user"></i>&nbsp; Profile
                        </Link>
                        <Link className="dropdown-item" to="/edit_profile">
                            <div className="c-icon mfe-2"></div><i className="fa fa-cog"></i>&nbsp; Settings
                        </Link>
                        {/* <a class="dropdown-item" href="#">
                            <svg class="c-icon mfe-2"></svg> Payments<span class="badge badge-secondary mfs-auto">42</span>
                        </a> */}
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#" onClick={this.handleLogout}>
                            <svg className="c-icon mfe-2"></svg><i className="fa fa-power-off"></i>&nbsp; Logout</a>
                    </div>
                </li>
            </ul>
            
            <div className="c-subheader justify-content-between px-3">

                <ol className="breadcrumb border-0 m-0 px-0 px-md-3">
                    <li className="breadcrumb-item">Dashboard</li>
                    <li className="breadcrumb-item active"><a href="#">{location}</a></li>
                </ol>
                <div className="c-subheader-nav d-md-down-none mfe-2">
                    <a className="c-subheader-nav-link" href="#">
                        <ul className="c-header-nav mfs-auto">
                            <li className="c-header-nav-item px-3 c-d-legacy-display">
                                <button className="c-class-toggler c-header-nav-btn" type="button" id="header-tooltip" data-target="body" data-class="c-dark-theme" data-toggle="c-tooltip" data-placement="bottom" title="Toggle Light/Dark Mode">
                                <div className="c-icon c-d-dark-none"><i className="fa fa-sun-o"></i></div>
                                <div className="c-icon c-d-default-none"><i className="fa fa-moon-o"></i></div>
                                &nbsp;Darkmode
                                </button>
                            </li>
                        </ul>
                    </a>
                    {/* <a className="c-subheader-nav-link" href="#" onClick={this.toggleTheme}>
                            
                            <i className={this.state.tableData.icon}></i>
                            {this.state.tableData.icon2 && 
                                <i className="fa fa-server"></i>
                            }
                                &nbsp;{this.state.tableData.text}
                        </a> */}
                    {location === '/professionals' &&
                        <TableData.Provider value={this.state.tableData}>
                            <a className="c-subheader-nav-link" href="#" onClick={() => this.toggleTheme()}>
                                
                                <i className={this.state.tableData.icon}></i>
                                {this.state.tableData.icon2 && 
                                    <i className="fa fa-server"></i>
                                }
                                    &nbsp;{this.state.tableData.text}
                            </a>
                        </TableData.Provider>
                    }
                   
                </div>
            </div>
        </header>
        
        );
    }
}

export default Topnav;
