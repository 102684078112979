
import axios from 'axios'
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
    loading: null,
    dashboard: {},
    error: null,
  };
  
  function dashboard(state = INITIAL_STATE, action) {
    const { type, payload} = action;
      switch (type) {
        case 'FETCH_ALL_COUNT':
          return { ...state, loading: true };
        case 'FETCH_COUNT_SUCCESS':
            return { ...state, loading: false, dashboard: payload, success:null, error:null};
        case 'FETCH_COUNT_FAILURE':
          return { ...state, loading: false, error: payload };
        default:
          return state;
      }
    }

    export default dashboard;
  