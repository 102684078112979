import React, { useEffect, Fragment } from 'react';
import Menu from '../Menu/MenuComponent'
import Topnav from '../Menu/TopnavComponent'
import { create_permission_access} from './duck/reducer';
import {connect} from 'react-redux';


function AdminProfileComponent({ getAccount, admin_account = [], getPermissions, admin_permissions= [], create_permission_access, error, success}) {
    const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  
  useEffect(() => {
    getAccount(id);
    getPermissions(id);
}, [getAccount], [getPermissions]);



    
      

      function savePermission(e){
        e.preventDefault();
       
        // permission
        var facilities_read = document.getElementById("facilities_read").checked;
        var facilities_write = document.getElementById("facilities_write").checked;
        var admins_read = document.getElementById("admins_read").checked;
        var admins_write = document.getElementById("admins_write").checked;
        var agents_read = document.getElementById("agents_read").checked;
        var agents_write = document.getElementById("agents_write").checked;
        var referrals_read = document.getElementById("referrals_read").checked;
        var referrals_write = document.getElementById("referrals_write").checked;
        var subscriptions_read = document.getElementById("subscriptions_read").checked;
        var subscriptions_write = document.getElementById("subscriptions_write").checked;
        var professionals_read = document.getElementById("professionals_read").checked;
        var professionals_write = document.getElementById("professionals_write").checked;
        var patients_read = document.getElementById("patients_read").checked;
        var patients_write = document.getElementById("patients_write").checked;
        var appointments_read = document.getElementById("appointments_read").checked;
        var appointments_write = document.getElementById("appointments_write").checked;
        var billing_read = document.getElementById("billing_read").checked;
        var billing_write = document.getElementById("billing_write").checked;
        var calls_read = document.getElementById("calls_read").checked;
        var calls_write = document.getElementById("calls_write").checked;
        var specialites_read = document.getElementById("specialites_read").checked;
        var specialites_write = document.getElementById("specialites_write").checked;
        var languages_read = document.getElementById("languages_read").checked;
        var languages_write = document.getElementById("languages_write").checked;
        var pharmacy_read = document.getElementById("pharmacy_read").checked;
        var pharmacy_write = document.getElementById("pharmacy_write").checked;
        var lab_read = document.getElementById("lab_read").checked;
        var lab_write = document.getElementById("lab_write").checked;


        var permission_data = {permissions:{
                                facilities:{read:facilities_read, write:facilities_write},
                                admins:{read:admins_read, write:admins_write},
                                agents:{read:agents_read, write:agents_write},
                                referrals:{read:referrals_read, write:referrals_write},
                                subscriptions:{read:subscriptions_read, write:subscriptions_write},
                                professionals:{read:professionals_read, write:professionals_write},
                                patients:{read:patients_read, write:patients_write},
                                appointments:{read:appointments_read, write:appointments_write},
                                billing:{read:billing_read, write:billing_write},
                                calls:{read:calls_read, write:calls_write},
                                specialites:{read:specialites_read, write:specialites_write},
                                languages:{read:languages_read, write:languages_write},
                                pharmacy:{read:pharmacy_read, write:pharmacy_write},
                                lab:{read:lab_read, write:lab_write}
                                },
                                user_id:id}
        create_permission_access(permission_data)
      }
      const user = JSON.parse(window.localStorage.getItem('user'))
       //permissions
    const admin_access = JSON.parse(localStorage.getItem('permissions'))?JSON.parse(localStorage.getItem('permissions')):{permissions:null};
    if(admin_access.permissions){
        var accounts_write = admin_access.permissions.accounts.write
        
        
    }else{
        var accounts_write = true
        
    }
      

  return (
    <div class="c-app c-legacy-theme c-no-layout-transition" >
            <Menu/>
            <div class="c-wrapper">
                <Topnav/>
                <div class="c-body">
                    <main class="c-main">
                        <div class="container-fluid">
                            <div class="fade-in">
                                <div class="row">
                                    <div class="col-lg-4 col-xlg-3 col-md-5 ">
                                        <div class="card">
                                            <div class="card-body">
                                                <center class="mt-4"> 
                                                    {!admin_account.avatar &&
                                                        <img class="rounded-circle" src="assets/images/user.jpg" style={{width:"150px"}}  alt=""/>
                                                    }
                                                    {admin_account.avatar &&
                                                        <img className="rounded-circle" src={admin_account.avatar} style={{width:"150px"}} alt=""/>
                                                    }
                                                    <h4 class="card-title mt-2">{admin_account.first_name} {admin_account.last_name}</h4>
                                                    <h6 class="card-subtitle">{admin_account.user_type && <Fragment>{admin_account.user_type? admin_account.user_type.map(user_type => (<span><span class="badge badge-primary">{user_type}</span>&nbsp;</span>)):""}</Fragment>}</h6>
                                                    {/* <div class="row text-center justify-content-md-center">
                                                        <div class="col-4"><a href="javascript:void(0)" class="link"><i class="icon-people"></i> <font class="font-medium">254</font></a></div>
                                                        <div class="col-4"><a href="javascript:void(0)" class="link"><i class="icon-picture"></i> <font class="font-medium">54</font></a></div>
                                                    </div> */}
                                                </center>
                                            </div>
                                            <div>
                                                <hr/> 
                                            </div>
                                            <div class="card-body">
                                                <small class="text-muted">Username </small>
                                                <h6>{admin_account.username}</h6> 
                                                <small class="text-muted">Email address </small>
                                                <h6>{admin_account.email}</h6> <small class="text-muted pt-4 db">Phone</small>
                                                <h6>{admin_account.phone_number}</h6> <small class="text-muted pt-4 db">Organisation</small>
                                                <h6>{admin_account.organization && <span class="badge badge-success">{admin_account.organization.name}</span>}</h6>
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                    <div class="col-lg-8 col-xlg-9 col-md-7">
                        <div class="card">
                            <ul class="nav nav-pills custom-pills" id="pills-tab" role="tablist">
                                {/* <li class="nav-item">
                                    <a class="nav-link active" id="pills-timeline-tab" data-toggle="pill" href="#current-month" role="tab" aria-controls="pills-timeline" aria-selected="true">Feature Access</a>
                                </li> */}
                                <li class="nav-item">
                                    {user.id != id &&
                                        <div> 
                                            {accounts_write &&
                                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#permissions_modal"><i className="fa fa-edit"></i> Edit</button>
                                            }
                                        </div>
                                    }
                                </li>
                                
                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="current-month" role="tabpanel" aria-labelledby="pills-timeline-tab">
                                    <div class="card-body">
                                        <div className="card-box">
                                        <div className="card-title">
                                            {success &&
                                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                
                                                    <a className="text-center">{success}</a>
                                                </div>
                                            }
                                            {error &&
                                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                    
                                                    <a className="text-center">{error}</a>
                                                </div>
                                            }
                                        </div>
                                        <div className="experience-box">
                                            <div className="modal-body text-center">
                                                    <div className="table-responsive">
                                                        <table className="table mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Module Permission</th>
                                                                    <th className="text-center">Read</th>
                                                                    <th className="text-center">Write</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Facilities </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.facilities.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.facilities.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.facilities.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.facilities.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Admin </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.admins.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.admins.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.admins.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.admins.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Agents</td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.agents.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.agents.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.agents.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.agents.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Referrals</td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.referrals.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.referrals.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.referrals.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.referrals.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Subscriptions</td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.subscriptions.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.subscriptions.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.subscriptions.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.subscriptions.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Professionals</td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.professionals.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.professionals.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.professionals.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.professionals.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Patients</td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.patients.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.patients.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.patients.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.patients.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Appointments</td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.appointments.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.appointments.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.appointments.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.appointments.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                    
                                                                </tr>
                                                                <tr>
                                                                    <td>Billing</td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.billing.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.billing.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.billing.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.billing.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Call Logs</td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.calls.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.calls.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.calls.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.calls.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Specialites</td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.specialites.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.specialites.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.specialites.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.specialites.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Languages</td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.languages.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.languages.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.languages.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.languages.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Pharmacy</td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.pharmacy?.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.pharmacy?.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.pharmacy?.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.pharmacy?.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Lab</td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.lab?.read &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.lab?.read &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {admin_permissions.permissions &&
                                                                            <div>
                                                                                {admin_permissions.permissions.lab?.write &&
                                                                                    <i className="fa fa-check"></i>
                                                                                }
                                                                                {!admin_permissions.permissions.lab?.write &&
                                                                                    <i className="fa fa-remove"></i>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {!admin_permissions.permissions &&
                                                                            <i className="fa fa-remove"></i>
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div id="permissions_modal" className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title text-center" id="exampleModalLabel">Permissions</h5>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body text-center">
                                                                    <form>
                                                                        <div className="table-responsive">
                                                                        <table className="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Module Permission</th>
                                                                <th>Read</th>
                                                                <th >Write</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Facilities </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input id="facilities_read" type="checkbox" name="facilities_read" defaultChecked={admin_permissions.permissions.facilities.read} key={admin_permissions.permissions.facilities.read}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input id="facilities_read" type="checkbox" name="facilities_read"/>
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="facilities_write" name="facilities_write" defaultChecked={admin_permissions.permissions.facilities.write} key={admin_permissions.permissions.facilities.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="facilities_write" name="facilities_write" />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Admins </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input id="admins_read" type="checkbox" name="admins_read" defaultChecked={admin_permissions.permissions.admins.read} key={admin_permissions.permissions.admins.read}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input id="admins_read" type="checkbox" name="admins_read"  />
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="admins_write" name="admins_write" defaultChecked={admin_permissions.permissions.admins.write} key={admin_permissions.permissions.admins.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="admins_write" name="admins_write"  />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Agents</td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="agents_read" name="agents_read" defaultChecked={admin_permissions.permissions.agents.read} key={admin_permissions.permissions.agents.read}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="agents_read" name="agents_read"  />
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="agents_write" name="agents_write" defaultChecked={admin_permissions.permissions.agents.write} key={admin_permissions.permissions.agents.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="agents_write" name="agents_write"  />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Referrals</td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                      <input type="checkbox" id="referrals_read" name="referrals_read" defaultChecked={admin_permissions.permissions.referrals.read} key={admin_permissions.permissions.referrals.read}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="referrals_read" name="referrals_read"  />
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="referrals_write" name="referrals_write" defaultChecked={admin_permissions.permissions.referrals.write} key={admin_permissions.permissions.referrals.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="referrals_write" name="referrals_write"  />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Subscriptions</td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="subscriptions_read" name="subscriptions_read" defaultChecked={admin_permissions.permissions.subscriptions.read} key={admin_permissions.permissions.subscriptions.read} />
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="subscriptions_read" name="subscriptions_read"  />
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="subscriptions_write" name="subscriptions_write" defaultChecked={admin_permissions.permissions.subscriptions.write} key={admin_permissions.permissions.subscriptions.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="subscriptions_write" name="subscriptions_write"  />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Professionals</td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="professionals_read" name="professionals_read" defaultChecked={admin_permissions.permissions.professionals.read} key={admin_permissions.permissions.professionals.read}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="professionals_read" name="professionals_read"  />
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="professionals_write" name="professionals_write" defaultChecked={admin_permissions.permissions.professionals.write} key={admin_permissions.permissions.professionals.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="professionals_write" name="professionals_write"  />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Patients</td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="patients_read" name="patients_read" defaultChecked={admin_permissions.permissions.patients.read} key={admin_permissions.permissions.patients.read} />
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="patients_read" name="patients_read"  />
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="patients_write" name="patients_write" defaultChecked={admin_permissions.permissions.patients.write} key={admin_permissions.permissions.patients.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="patients_write" name="patients_write"  />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                                   
                                                            </tr>
                                                            <tr>
                                                                <td>Appointments</td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="appointments_read" name="appointments_read" defaultChecked={admin_permissions.permissions.appointments.read} key={admin_permissions.permissions.appointments.read}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="appointments_read" name="appointments_read"  />
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="appointments_write" name="appointments_write" defaultChecked={admin_permissions.permissions.appointments.write} key={admin_permissions.permissions.appointments.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="appointments_write" name="appointments_write"  />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Billing</td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="billing_read" name="billing_read" defaultChecked={admin_permissions.permissions.billing.read} key={admin_permissions.permissions.billing.read}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="billing_read" name="billing_read"  />
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="billing_write" name="billing_write" defaultChecked={admin_permissions.permissions.billing.write} key={admin_permissions.permissions.billing.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="billing_write" name="billing_write"  />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Call Logs</td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="calls_read" name="calls_read" defaultChecked={admin_permissions.permissions.calls.read} key={admin_permissions.permissions.calls.read} />
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="calls_read" name="calls_read"  />
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="calls_write" name="calls_write" defaultChecked={admin_permissions.permissions.calls.write} key={admin_permissions.permissions.calls.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="calls_write" name="calls_write"  />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Specialites</td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="specialites_read" name="specialites_read" defaultChecked={admin_permissions.permissions.specialites.read} key={admin_permissions.permissions.specialites.read}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="specialites_read" name="specialites_read"  />
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="specialites_write" name="specialites_write" defaultChecked={admin_permissions.permissions.specialites.write} key={admin_permissions.permissions.specialites.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="specialites_write" name="specialites_write"  />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Languages</td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="languages_read" name="languages_read" defaultChecked={admin_permissions.permissions.languages.read} key={admin_permissions.permissions.languages.read}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="languages_read" name="languages_read"  />
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="languages_write" name="languages_write" defaultChecked={admin_permissions.permissions.languages.write} key={admin_permissions.permissions.languages?.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="languages_write" name="languages_write"  />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Pharmacy</td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="pharmacy_read" name="pharmacy_read" defaultChecked={admin_permissions.permissions.pharmacy?.read} key={admin_permissions.permissions.pharmacy?.read}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="pharmacy_read" name="pharmacy_read"  />
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="pharmacy_write" name="pharmacy_write" defaultChecked={admin_permissions.permissions.pharmacy?.write} key={admin_permissions.permissions.pharmacy?.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="pharmacy_write" name="pharmacy_write"  />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lab</td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="lab_read" name="lab_read" defaultChecked={admin_permissions.permissions.lab?.read} key={admin_permissions.permissions.lab?.read}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="lab_read" name="lab_read"  />
                                                                    }
                                                                    
                                                                </td>
                                                                <td className="text-center">
                                                                    {admin_permissions.permissions &&
                                                                        <input type="checkbox" id="lab_write" name="lab_write" defaultChecked={admin_permissions.permissions.lab?.write} key={admin_permissions.permissions.lab?.write}/>
                                                                    }
                                                                    {!admin_permissions.permissions &&
                                                                        <input type="checkbox" id="lab_write" name="lab_write"  />
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                                        </div>
                    
                                                                    </form>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={savePermission} disabled={!id}>Save</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </main>
        </div>
        </div>
    </div>
  );
}

function mapStateToProps({accounts}){
    return {error:accounts.error, success:accounts.success};
    
  }
export default connect(mapStateToProps, { create_permission_access })(AdminProfileComponent);
