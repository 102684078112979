const INITIAL_STATE = {
  loading: false,
  admin_account: {},
  error: null,
};

function admin_account(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
      case 'FETCH_ADMIN_ACCOUNT':
        return { ...state, loading: true };
      case 'FETCH_ADMIN_ACCOUNT_SUCCESS':
        return { ...state, loading: false, admin_account: payload };
      case 'FETCH_ADMIN_ACCOUNT_FAILURE':
        return { ...state, loading: false, error: payload };
      default:
        return state;
    }
  }

  export default admin_account;
