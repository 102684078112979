import axios from 'axios';
import Literals from '../../helpers/Literals';
import { authenticateRequest } from '../../helpers/utils';


export const fetchAppointments = () => async dispatch => {
  dispatch({ type: 'FETCH_APPOINTMENTS', success:null, error:null });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}records/appointments/backend/?page=1&page_size=50&organization=false`);

    dispatch({ type: 'FETCH_APPOINTMENTS_SUCCESS', payload: data.results, next_page:data.next, count:data.count});
  } catch (e) {
    authenticateRequest(e);
    dispatch({ type: 'FETCH_APPOINTMENTS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, error:null});
  }
};

export const fetchWebAppointments = () => async dispatch => {
  dispatch({ type: 'FETCH_WEB_APPOINTMENTS', success:null, error:null });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}records/appointments/backend/?page=1&page_size=50&organization=true`);

    dispatch({ type: 'FETCH_WEB_APPOINTMENTS_SUCCESS', payload: data.results, next_page:data.next, count:data.count});
  } catch (e) {
    authenticateRequest(e);
    dispatch({ type: 'FETCH_WEB_APPOINTMENTS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, error:null});
  }
};


export const getAppointment = (id) => async dispatch => {
  dispatch({ type: 'GET_APPOINTMENT' });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}records/appointments/backend/${id}/`);
    dispatch({ type: 'GET_APPOINTMENT_SUCCESS', payload: data.results });
  } catch (e) {
    dispatch({ type: 'GET_APPOINTMENT_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED });
  }
};
export const fetchCallLogs = () => async dispatch => {
  dispatch({ type: 'FETCH_CALLS', success:null, error:null });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}records/call/backend/?page=1&page_size=50&organization=false`);

    dispatch({ type: 'FETCH_CALLS_SUCCESS', payload: data.results, next_page:data.next, count:data.count});
  } catch (e) {
    authenticateRequest(e);
    dispatch({ type: 'FETCH_CALLS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, error:null});
  }
};

export const fetchWebCallLogs = () => async dispatch => {
  dispatch({ type: 'FETCH_WEB_CALLS', success:null, error:null });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}records/call/backend/?page=1&page_size=50&organization=true`);

    dispatch({ type: 'FETCH_WEB_CALLS_SUCCESS', payload: data.results, next_page:data.next, count:data.count});
  } catch (e) {
    authenticateRequest(e);
    dispatch({ type: 'FETCH_WEB_CALLS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, error:null});
  }
};