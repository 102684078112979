import { connect } from 'react-redux';
import { getAccount, getPermissions} from './duck/actions';
import AdminProfileComponent from './AdminProfilePage';

const mapStateToProps = ({ admin_account: { admin_account }, admin_permissions:{admin_permissions} }) => {
  return {
    admin_account,
    admin_permissions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAccount: (id) => dispatch(getAccount(id)),
    getPermissions: (id) => dispatch(getPermissions(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfileComponent);
