import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import Menu from "../Menu/MenuComponent";
import Topnav from "../Menu/TopnavComponent";
import {connect} from 'react-redux'



function DashboardComponent({ dashboard={}, fetchDashBoardCount, fetchAccounts, fetchFacility, fetchSpeciality,
    accounts_count, facilities=[],facility_count, spciality_count}){
        useEffect(() => {
            fetchDashBoardCount();
            fetchAccounts();
            fetchFacility();
            fetchSpeciality();
            
        }, [fetchDashBoardCount, fetchAccounts, fetchFacility, fetchSpeciality]);


        const admin_permissions = JSON.parse(localStorage.getItem('permissions'))?JSON.parse(localStorage.getItem('permissions')):{permissions:null};
        if(admin_permissions.permissions){
            var facilities_read = admin_permissions.permissions.facilities.read
            var admins_read = admin_permissions.permissions.admins.read
            var agents_read = admin_permissions.permissions.agents.read
            var referrals_read = admin_permissions.permissions.referrals.read
            var subscriptions_read = admin_permissions.permissions.subscriptions.read
            var professionals_read = admin_permissions.permissions.professionals.read
            var patients_read = admin_permissions.permissions.patients.read
            var appointments_read = admin_permissions.permissions.appointments.read
            var billing_read = admin_permissions.permissions.billing.read
            var calls_read = admin_permissions.permissions.calls.read
            var specialites_read = admin_permissions.permissions.specialites.read
            var languages_read = admin_permissions.permissions.languages.read
            var pharmacy_read = admin_permissions.permissions.pharmacy.read
            
        }else{
            var facilities_read = true
            var admins_read = true
            var agents_read = true
            var referrals_read = true
            var subscriptions_read = true
            var professionals_read = true
            var patients_read =true
            var appointments_read = true
            var billing_read = true
            var calls_read = true
            var specialites_read = true
            var languages_read = true
            var pharmacy_read = true
        }
    


        var loadScript = function(src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementsByTagName('body')[0].appendChild(tag);
          }
        
          loadScript('/assets/js/js-coreui-chartjs.bundle.js')
          loadScript('/assets/js/js-coreui-utils.js')
          loadScript('/assets/js/3917-js-main.js')
          loadScript('/assets/js/multi-select.js')

 


    return (
        <div className="c-app c-legacy-theme c-no-layout-transition" >
            <Menu/>
            <div className="c-wrapper">
            <Topnav/>
            <div className="c-body">
                <main className="c-main"><div className="container-fluid">
                    <div className="fade-in">
                        <br></br><br></br>
                        <br></br><br></br>
                        
                        <div className="row">
                            {facilities_read && 
                            <div className="col-sm-6 col-lg-3">
                                <Link to="/facilities">
                                <div className="card text-white bg-gradient-primary">
                                    <div className="card-body card-body pb-0 d-flex justify-content-between align-items-start">
                                        <div>
                                            <div className="text-value-lg">{facilities.length}</div>
                                            <strong>Facilities</strong>
                                        </div>
                                        <div className="btn-group">
                                            <i className="fa fa-hospital-o"></i>
                                        </div>
                                    </div>
                                    <div className="c-chart-wrapper mt-3 mx-3" style={{height:"70px"}}>
                                        <canvas className="chart" id="card-chart1" style={{height:"70px"}}></canvas>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            }
                            
                            {professionals_read && 
                            <div className="col-sm-6 col-lg-3">
                                <Link to="/professionals">
                                <div className="card text-white bg-gradient-info">
                                    <div className="card-body card-body pb-0 d-flex justify-content-between align-items-start">
                                        <div>
                                            <div className="text-value-lg">{dashboard.professionals_count}</div>
                                            <strong>Professionals</strong>
                                        </div>
                                        <div className="btn-group">
                                            <i className="fa fa-drivers-license"></i>
                                        </div>
                                    </div>
                                    <div className="c-chart-wrapper mt-3 mx-3" style={{height:"70px"}}>
                                        <canvas className="chart" id="card-chart2" style={{height:"70px"}}></canvas>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            }
                            
                            {patients_read && 
                            <div className="col-sm-6 col-lg-3">
                            <Link to="/patients">
                                <div className="card text-white bg-gradient-warning">
                                    <div className="card-body card-body pb-0 d-flex justify-content-between align-items-start">
                                        <div>
                                            <div className="text-value-lg">{dashboard.patients_count}</div>
                                            <strong>Patients</strong>
                                        </div>
                                        <div className="btn-group">
                                            <i className="fa fa-wheelchair"></i>
                                        </div>
                                    </div>
                                    <div className="c-chart-wrapper mt-3" style={{height:"70px"}}>
                                        <canvas className="chart" id="card-chart3" style={{height:"70px"}}></canvas>
                                    </div>
                                </div>
                                </Link>
                            </div>}
                            {appointments_read &&
                            <div className="col-sm-6 col-lg-3">
                            <Link to="/appointments">
                                <div className="card text-white bg-gradient-danger">
                                    <div className="card-body card-body pb-0 d-flex justify-content-between align-items-start">
                                        <div>
                                            <div className="text-value-lg">{dashboard.appointments_count}</div>
                                            <strong>Appointments</strong>
                                        </div>
                                        <div className="btn-group">
                                            <i className="fa fa-calendar"></i>
                                        </div>
                                    </div>
                                    <div className="c-chart-wrapper mt-3 mx-3" style={{height:"70px"}}>
                                        <canvas className="chart" id="card-chart4" style={{height:"70px"}}></canvas>
                                    </div>
                                </div>
                            </Link>
                            </div>
                            }
                        </div>
                        <br></br><br></br>
                        
                        <div class="row">
                            {admins_read && 
                            <div class="col-sm-6 col-lg-3">
                            <Link to="/admins">
                            <div class="card text-white bg-gradient-primary">
                            <div class="card-body">
                            <div class="text-value-lg">{accounts_count}</div>
                            <strong>Administrators</strong>
                            <div class="progress progress-white progress-xs my-2">
                            <div class="progress-bar" role="progressbar" style={{width: "25%"}} ariaValuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div><small class="text-muted">.</small>
                            </div>
                            </div>
                            </Link>
                            </div>
                            }
                            
                            {calls_read &&
                            <div class="col-sm-6 col-lg-3">
                            <Link to="/call_logs">
                            <div class="card text-white bg-gradient-warning">
                            <div class="card-body">
                            <div class="text-value-lg">{dashboard.calls_count}</div>
                            <strong>Call logs</strong>
                            <div class="progress progress-white progress-xs my-2">
                            <div class="progress-bar" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div><small class="text-muted">.</small>
                            </div>
                            </div>
                            </Link>
                            </div>
                            }
                            

                            {specialites_read &&
                            <div class="col-sm-6 col-lg-3">
                            <Link to="/specialities">
                            <div class="card text-white bg-gradient-danger">
                            <div class="card-body">
                            <div class="text-value-lg">{spciality_count}</div>
                            <strong>Specialities</strong>
                            <div class="progress progress-white progress-xs my-2">
                            <div class="progress-bar" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div><small class="text-muted">.</small>
                            </div>
                            </div>
                            </Link>
                            </div>
                            }
                            
                            {billing_read &&
                            <div class="col-sm-6 col-lg-3">
                            <Link to="/billing">
                            <div class="card text-white bg-gradient-info">
                            <div class="card-body">
                            <div class="text-value-lg">{dashboard.billing_count}</div>
                            <strong>Billing</strong>
                            <div class="progress progress-white progress-xs my-2">
                            <div class="progress-bar" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div><small class="text-muted">.</small>
                            </div>
                            </div>
                            </Link>
                            </div>
                            }

                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</div>
    )
}
function mapStateToProps({accounts, facilities, specialities}){
    console.log('facilities--------COUnt',facilities)
    return {accounts_count:accounts.count,
        facility_count:facilities.count, spciality_count:specialities.count};
    
  }
  export default connect(mapStateToProps, {})(DashboardComponent);
