import axios from 'axios';
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
  loading: false,
  visits: [],
  pharmacy: [],
  labs: [],
  error: null,
  success:null,
  loading:null,
  download_url:null

};

function visits(state = INITIAL_STATE, action) {
  const { type, payload, index} = action;
  switch (type) {
    case 'FETCH_VISITS':
      return { ...state, loading: true };
    case 'FETCH_VISITS_SUCCESS':
      return { ...state, loading: false, visits: payload, success:null, error:null};
    case 'FETCH_VISITS_FAILURE':
      return { ...state, loading: false, error: payload };
    case 'FETCH_PHARMACY':
        return { ...state, loading: true };
    case 'FETCH_PHARMACY_SUCCESS':
      return { ...state, loading: false, pharmacy: payload, success:null, error:null};
    case 'FETCH_PHARMACY_FAILURE':
      return { ...state, loading: false, error: payload };

    
      case 'FETCH_LAB':
        return { ...state, loading: true };
    case 'FETCH_LAB_SUCCESS':
      return { ...state, loading: false, labs: payload, success:null, error:null};
    case 'FETCH_LAB_FAILURE':
      return { ...state, loading: false, error: payload };

      case 'CREATE_VISIT_SUCCESS':
        return { ...state, loading: false, visit: payload, visit_success:true, visit_error:null};
      case 'CREATE_VISIT_FAILED':
          return { ...state, loading: false, visit_success:true, visit_error:payload};
      case 'UPDATE_VISIT_SUCCESS':
        return { ...state, loading: false, visit: payload, success:true, error:null};
      case 'UPDATE_VISIT_FAILED':
          return { ...state, loading: false, error: payload, success:null};
      case 'DELETE_VISIT_SUCCESS':
        return { ...state, loading: false, visit: payload, success:"Visit deleted successfully", error:null};
      case 'DELETE_VISIT_FAILED':
          return { ...state, loading: false, error: payload, success:null};

      case 'GET_VISIT_SUCCESS':
        return { ...state, loading: false, visit: payload, success:true, error:null};
      case 'GET_VISIT_FAILED':
          return { ...state, loading: false, error: payload, success:null};

      case 'LAB_PDF_SUCCESS':
        return { ...state, loading: false, visit: payload, success:'Lab PDF successfully requested', error:null, download_url:payload.results.url};
      case 'LAB_PDF_FAILED':
          return { ...state, loading: false, error: payload, success:null};

      case 'PRESCRIPTION_PDF_SUCCESS':
        return { ...state, loading: false, visit: payload, success:'Prescription PDF successfully requested', error:null, download_url:payload.results.url};
      case 'PRESCRIPTION_PDF_FAILED':
          return { ...state, loading: false, error: payload, success:null};
      case 'RESET_DOWNLOAD_URL':
        return { ...state, loading: false, error: null, success:null, download_url:null};
      
      case 'SHARE_WHATSAPP_SUCCESS':
        return { ...state, loading: false, visit: payload, success:'Successfully shared to whatsapp', error:null};
      case 'SHARE_WHATSAPP_FAILED':
          return { ...state, loading: false, error: payload, success:null};

      case 'SERVE_MEDICATION':
          return { ...state, loading:true};
      case 'SERVE_MEDICATION_SUCCESS':
          state.pharmacy[index] = payload
          var pharmacy = state.pharmacy
          return { ...state, loading: false, error: null, success: true, pharmacy: pharmacy};
      case 'SERVE_MEDICATION_FAILED':
          return { ...state, loading: false, error: true, success: null};
    

    default:
      return state;
  }
}

export const create_visit = data => async dispatch => {
  dispatch({ type: 'FETCH_VISITS', loading:true});
  try {
    var date = data['date']?data['date']:'';
    var time = data['time']?data['time']:'';
    data['visit_date']=date+" "+time;
    if(data.diagnosis){
      if(!Array.isArray(data.diagnosis)){
      data['diagnosis'] = data.diagnosis?data.diagnosis.split(','):[];
      }
    }else{
      data['diagnosis'] =[]
    }
    
    if(data.medication){
      if(!Array.isArray(data.medication)){
      data['medication'] = data.medication?data.medication.split(','):[];
      }
    }
    let visit = await axios.post(`${Literals.BASE_API}records/appointments/visit/`, data);
    dispatch({ type: 'CREATE_VISIT_SUCCESS', payload: visit.data, visit_success:true });

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    document.getElementById("visit-form").reset();
  } catch (e) {
    dispatch({
      type: 'CREATE_VISIT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const update_visit = data => async dispatch => {
  try {
    var id = data.id
    var date = data['date']?data['date']:'';
    var time = data['time']?data['time']:'';
    data['visit_date']=date+" "+time;
    if(data.diagnosis){
      if(!Array.isArray(data.diagnosis)){
      data['diagnosis'] = data.diagnosis?data.diagnosis.split(','):[];
      }
    }else{
      data['diagnosis'] =[]
    }
    
    if(data.medication){
      if(!Array.isArray(data.medication)){
      data['medication'] = data.medication?data.medication.split(','):[];
      }
    }

    let visit = await axios.put(`${Literals.BASE_API}records/appointments/visit/${id}/`, data);
    dispatch({ type: 'UPDATE_VISIT_SUCCESS', payload: visit.data, success:true});
    document.getElementById("add-test-form").reset();
  } catch (e) {
    dispatch({
      type: 'UPDATE_VISIT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const delete_visit = id => async dispatch => {
  try {
    let visit = await axios.delete(`${Literals.BASE_API}records/appointments/visit/${id}/`);
    dispatch({ type: 'DELETE_VISIT_SUCCESS', payload: visit.data, success:true});
  } catch (e) {
    dispatch({
      type: 'DELETE_VISIT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const request_lap_pdf = data => async dispatch => {
  dispatch({type:'FETCH_VISITS'})
  try {
    var id =data.id
    let visit = await axios.post(`${Literals.BASE_API}records/appointments/visit/${id}/lab_request/`, data);
    // var win = window.open(visit.data.results.url, '_blank');
    // win.focus();
    dispatch({ type: 'LAB_PDF_SUCCESS', payload: visit.data, success:true});
  } catch (e) {
    dispatch({
      type: 'LAB_PDF_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const request_prescription_pdf = data => async dispatch => {
  dispatch({type:'FETCH_VISITS'})
  try {
    var id =data.id
    let visit = await axios.post(`${Literals.BASE_API}records/appointments/visit/${id}/prescription_request/`, data);
    // var win = window.open(visit.data.results.url, '_blank');
    // win.focus();
    dispatch({ type: 'PRESCRIPTION_PDF_SUCCESS', payload: visit.data, success:true});
  } catch (e) {
    dispatch({
      type: 'PRESCRIPTION_PDF_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const share_via_whatsapp = data => async dispatch => {
  dispatch({type:'FETCH_VISITS'})
  var id =data.id
  document.getElementById(`whatsapp_button${id}`).innerHTML = 'Sending';
  document.getElementById(`whatsapp_button_lab${id}`).innerHTML = 'Sending';
  try {
    let visit = await axios.post(`${Literals.BASE_API}records/appointments/visit/${id}/whatsapp/`, data);
    // var win = window.open(visit.data.results.url, '_blank');
    // win.focus();
    document.getElementById(`whatsapp_button${id}`).innerHTML = 'Shared Successfully';
    document.getElementById(`whatsapp_button_lab${id}`).innerHTML = 'Shared Successfully';
    dispatch({ type: 'SHARE_WHATSAPP_SUCCESS', payload: visit.data, success:true});
  } catch (e) {
    document.getElementById(`whatsapp_button${id}`).innerHTML = 'Shared Failed';
    document.getElementById(`whatsapp_button_lab${id}`).innerHTML = 'Shared Failed';
    
    dispatch({
      type: 'SHARE_WHATSAPP_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const reset_download_url = data => async dispatch => {
  try {
    
    dispatch({ type: 'RESET_DOWNLOAD_URL'});
  } catch (e) {
    dispatch({
      type: 'RESET_DOWNLOAD_URL',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const serve_medication = (id, index) => async dispatch => {
  dispatch({ type: 'SERVE_MEDICATION' });
  try {
    const { data } = await axios.put(`${Literals.BASE_API}records/appointments/visit/backend/${id}/served/`, {"served":true});

    dispatch({ type: 'SERVE_MEDICATION_SUCCESS', payload: data.results, next_page:data.next, index:index});
  } catch (e) {
    dispatch({ type: 'SERVE_MEDICATION_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};





export default visits;
