import { formatDistance,format } from 'date-fns';
import React from 'react';
import axios from 'axios';
import Literals from './Literals';

// import moment from 'moment';
// import { DatePicker } from '@y0c/react-datepicker';
// import '@y0c/react-datepicker/assets/styles/calendar.scss';
// import 'moment/locale/ko';

export function getRoleAccess(roles, user) {
  return roles.some(role => {
    return user.roles.includes(role);
  });
}

export function readLocalStorage(key) {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (error) {
    throw error;
  }
}

export const images = {
  logo: 'https://res.cloudinary.com/gen-technology/image/upload/v1543750137/skillnav-resources/full-logo-skillnav.png',
  smeLogo: 'https://res.cloudinary.com/gen-technology/image/upload/v1516483654/skillnav-avatars/default-sme-logo.png',
  heroImage: 'https://res.cloudinary.com/gen-technology/image/upload/v1516555569/skillnav-avatars/skills_zc5gr9.svg'
};

export function formatDate(date) {
  return date && new Date(date).toISOString().split('T')[0];
}

export function cleanDate(data) {
  return formatDistance(new Date(data), new Date()) + ' ago';
}

export function get_organisation_id() {
  var user_data = JSON.parse(localStorage.getItem('user'))
  return user_data.organisation.id;
}

export function formatDatetime(data) {
  return format(new Date(data), 'dd MMMM, yyyy hh:mm a');
  
}
export function formatTime(data) {
  return format(new Date("2020-01-01 " + data), 'hh:mm a');
  
}

// export function formatTimeDiff(startTime, endTime){
//   var duration = moment.duration(endTime.diff(startTime));
//   var hours = duration.asHours();
//   return hours
// }


// 
export function allDoctorsList() {  
          axios.get(`${Literals.BASE_API}professional/backend/?page=1&page_size=1000`).then(response => response)
          .then (response => {
            // console.log('response', response.data.results)
            localStorage.setItem('doctors', JSON.stringify(response.data.results))
            return response;
          })
      return JSON.parse(localStorage.getItem('doctors')) || []
    }

export function allPatientList() { 
    //permissions
    const user = JSON.parse(window.localStorage.getItem('user'))
    const admin_access = JSON.parse(localStorage.getItem('permissions'))?JSON.parse(localStorage.getItem('permissions')):{permissions:null};

    if(admin_access.permissions){
        
      var schedules_assigned = admin_access.permissions.schedules.only_assigned
    }else{
        
      schedules_assigned = false
    }
    
    if(schedules_assigned){
    var apiurl  = `https://ghhis.herokuapp.com/api/v1/patients/web/?professional_id=${user.id}&page=1&page_size=1000`;
    }else{
      var apiurl  = `https://ghhis.herokuapp.com/api/v1/patients/web/?page=1&page_size=1000`;
    }
    
    axios.get(`${apiurl}`).then(response => response)
    .then (response => {
      localStorage.setItem('patients', JSON.stringify(response.data.results))
      return response;
    })
  return JSON.parse(localStorage.getItem('patients')) || []
}

export function authenticateRequest(e) {  
  if(e.response){
    if(e.response.status == 401){
      localStorage.clear();
      window.location.reload()
    }

  }
}

export const tableThemes = {
  scroll: {
    text: 'Paginate Data',
    icon: 'fa fa-sort-numeric-asc',
  },
  paginate: {
    text: 'Scrolled Data',
    icon: 'fa fa-arrows-v',
    icon2: 'fa fa-server',
  },
};

export const TableData = React.createContext(
  tableThemes.scroll // default value
);

const TopNavLoader = ({ loading })=>{
  if(loading){
    var i = 1;
    var elem = document.getElementById("progressBar");
      if(elem){
      var width = 1;
      var id = setInterval(frame, 10);
        function frame() {
          if (width >= 100) {
            clearInterval(id);
            i = 0;
          } else {
            width++;
            elem.style.width = width + "%";
          }
        }
    }
  }

  return(
  <div class="progress mb-3" style={{height: "3px"}}>
<div className="progress-bar" id="progressBar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
</div>
  )

}
export default TopNavLoader;
  


// class DatePickerNow extends Component {

//   onChange = (date) => {
//     console.log(date);
//   }
  
//   render() {
//     return (
//       <DatePicker locale="ko" onChange={this.onChange}/>
//     )
//   }
// }
// export default DatePickerNow;
