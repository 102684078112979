import React, { useState, Fragment} from 'react';
import { connect } from 'react-redux';
import { login, forgot_password } from './ducks/reducer';

function LoginComponent({ login, forgot_password, error, success, loading }) {
  const [state, setState] = useState({});
  function handleChange({ target }) {
    setState({ ...state, [target.name]: target.value, submitted: false});
  }

  function handleSubmit(e) {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value, submitted: true});
        if (!(state.email && state.password)) {
            return;
        }
    login(state)
  }

  function handleForgotSubmit(e) {
    e.preventDefault();
    
    state.email = state.reset_email
    forgot_password(state);
  }

  
  
  
    function forgetTab(e){
      e.preventDefault();
      document.getElementById("loginform").classList.remove('show')
      document.getElementById("loginform").classList.add('hide')
      document.getElementById("recoverform").classList.add('show')
   
  }
  function loginTab(e){
    e.preventDefault();
    document.getElementById("recoverform").classList.remove('show')
    document.getElementById("recoverform").classList.add('hide')
    document.getElementById("loginform").classList.add('show')
    
}
  

  
   
  return (
    <div className="c-app c-legacy-theme flex-row align-items-center">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-md-4">
                <div className="card-group">
                    <div className="card p-4" id="loginform">
                        {!(state.password && state.email) && state.submitted &&
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <a className="text-center">Enter required filed</a>
                        </div>
                        }
                        {error &&
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <a className="text-center">{error}</a>
                        </div>
                        }
                        <div className="card-body">
                            <h1>Login</h1>
                            <p className="text-muted">Sign In to your account</p>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa fa-user"></i>
                                    </span>
                                </div>
                                <input className="form-control" type="text" placeholder="Email" name="email" onChange={handleChange}/>
                            </div>
                            <div className="input-group mb-4">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa fa-lock"></i>
                                    </span>
                                </div>
                                <input className="form-control" type="password" placeholder="Password" name="password" onChange={handleChange}/>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <button className="btn btn-primary px-4" type="button" onClick={handleSubmit}  disabled={!state.email || !state.password || loading}>
                                        {loading &&
                                          <Fragment>
                                             Loading <i className="fa fa-spinner fa-pulse"></i>
                                          </Fragment>
                                          }
                                          {!loading &&
                                          <Fragment>
                                            Login
                                          </Fragment>
                                          }
                                        
                                        </button>
                                </div>
                                <div className="col-6 text-right">
                                    <button className="btn btn-link px-0" type="button" onClick={forgetTab}>Forgot password?</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card text-white bg-primary py-5 d-md-down-none hide" style={{width:"44%"}} id="recoverform">
                        <div className="card-body text-center">
                            <div>
                                <h2>Forgot Password</h2>
                                <p>
                                    {error &&
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        <a className="text-center">{error}</a>
                                    </div>
                                    }
                                    {success &&
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        <a className="text-center">Instructions to reset password has been sent to {state.reset_email} </a>
                                    </div>
                                    }
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fa fa-envelope"></i>
                                            </span>
                                        </div>
                                        <input className="form-control" type="text" placeholder="Email" name="reset_email" onChange={handleChange}/>
                                    </div>
                                </p>
                                <button className="btn btn-lg btn-outline-light mt-3" type="button" name="action" onClick={handleForgotSubmit} disabled={!state.reset_email || loading}>
                                    {loading &&
                                        <Fragment>
                                            Loading <i className="fa fa-spinner fa-pulse"></i> 
                                        </Fragment>
                                        }
                                        {!loading &&
                                        <Fragment>
                                        Reset 
                                        {/* <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> */}
                                        </Fragment>
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="col-6 text-left">
                            <button className="btn btn-link px-0" style={{color:"white"}} type="button" onClick={loginTab}>Login</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  );
}

function mapStateToProps({auth}){
  return {error:auth.error, loading:auth.loading, success:auth.success};
  
}
export default connect(mapStateToProps, { login, forgot_password })(LoginComponent);
