import React, {useEffect, useState, Fragment} from 'react';
import Menu from '../../Menu/MenuComponent';
import Topnav from '../../Menu/TopnavComponent';
import {loadMoreSpeciality, create_speciality, update_speciality, delete_speciality, search_keyword} from '../ducks/speciality_reducer';
import {connect} from 'react-redux';



function SpecialityComponent({ fetchSpeciality, specialities = [], error, success, next_page, loadMoreSpeciality, loading, create_speciality, update_speciality, delete_speciality, search_keyword}) {
    useEffect(() => {
        fetchSpeciality();
      }, [fetchSpeciality]);

      const [state, setState] = useState({});
      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
	  }
      function handleSubmit(e) {
        e.preventDefault();
        create_speciality(state)

      }
      
      
      function handleUpdate(id) {
        var name = document.getElementById("name"+ id +"").value
        var description = document.getElementById("description"+ id +"").value
        var threshold_amount = document.getElementById("threshold_amount"+ id +"").value
        update_speciality({id:id, name:name, description:description, threshold_amount:threshold_amount})

      }

      function handleDelete(id) {
        delete_speciality(id)
      }

      function handleSearch(e) {
        e.preventDefault();
            document.getElementById("loader").classList.remove("hide");
  
           
            search_keyword(state);
            
        } 
        
        function handleClearField(e){
          e.preventDefault();
          document.getElementById("keyword").value='';
          state.keyword = ''
          document.getElementById("loader").classList.remove("hide");
         
            search_keyword(state);
          
          }


    //infinite scroll
    var facility_page = true
    window.onscroll = () => {
      // Checks that the page has scrolled to the bottom
          if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
              if(next_page && !loading && facility_page){
                loadMoreSpeciality(next_page);
              }
          }
    };
  
  return (
    <div className="c-app c-legacy-theme c-no-layout-transition" >
    <Menu/>
    <div className="c-wrapper">
        <Topnav/>
        <div className="c-body">
            <main className="c-main">
                <div className="container-fluid">
                    <div className="fade-in">
                       

                        <div className="card">
                            <div className="card-header"> 
                                <div className="row">
                                <div className="col-md-3">
                                    <label>Keyword</label>
                                    <div className="input-group">
                                    <input type="text" className="form-control" name="keyword" onChange={handleChange} id="keyword"/>
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-2">
                                    <div className="form-group">
                                    <label><br/></label>
                                    <br/>
                                    <button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search <i className="fa fa-spinner fa-pulse hide" id="loader"></i></button>
                                    </div>
                                </div>
                                    
                                <div className="col-md-7">
                                        <div className="form-group">
                                        <label><br/></label>
                                        <br/>
                                           
                                        <a href="#" className="btn btn btn-primary btn-rounded float-right" type="button" data-toggle="modal" data-target="#add_speciality"><i className="fa fa-plus"></i> Add Spciality</a>
                                            <div className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="add_speciality">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            Add Speciality
                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form id="speciality-form">
                                                                <div className="row">
                                                                    
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Name <span className="text-danger">*</span></label>
                                                                            <input className="form-control" type="text" name="name" onChange={handleChange} required/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Description<span className="text-danger">*</span></label>
                                                                            <textarea className="form-control" name="description" onChange={handleChange}></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Threshold Amount<span className="text-danger">*</span></label>
                                                                            <input className="form-control" type="text" name="threshold_amount" onChange={handleChange} required/>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </form>

                                                        </div>
                                                        <div class="modal-footer">
                                                            <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
                                                            <button class="btn btn-primary" type="button" onClick={handleSubmit} disabled={!(state.name && state.threshold_amount && state.description)}>{loading?<Fragment>Loading <i className="fa fa-spinner fa-pulse"></i></Fragment>:<Fragment> Save</Fragment>}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
          
                            <div className="card-body">
                                <table className="table table-responsive-sm table-hover datatable">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Threshold Amount</th>
                                            <th style={{width: "158px"}}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {specialities.map(speciality => (
                                            <tr key={speciality.id}>
                                                <td>{speciality.name}</td>
                                                <td>{speciality.description}</td>
                                                <td>
                                                   {speciality.threshold_amount}
                                                
                                                </td>
                                                <td>
                                                    <span>
                                                        <a  href="#" data-toggle="modal" data-target={`#update${speciality.id}`}><i className="fa fa-edit m-r-5"></i>&nbsp;Update</a>
                                                    </span>&nbsp;|&nbsp;
                                                    <span>
                                                        <a  href="#" data-toggle="modal" data-target={`#delete${speciality.id}`} style={{color:"red"}}><i className="fa fa-trash-o m-r-5"></i>&nbsp;Delete</a>
                                                    </span>
                                                
                                                    <div id={`delete${speciality.id}`} className="modal fade delete-modal" role="dialog">
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="modal-body text-center">
                                                                
                                                                    <h3>Are you sure want to delete {speciality.name} ?</h3>
                                                                    <div className="m-t-20">
                                                                        <input hidden name="id" value={speciality.id}></input>
                                                                        <a href="#" className="btn btn-white" data-dismiss="modal">
                                                                            Close
                                                                        </a>
                                                                        <button type="submit" data-dismiss="modal" className="btn btn-danger" onClick={() => handleDelete(speciality.id)}>
                                                                            Delete
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id={`update${speciality.id}`}>
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    Update Speciality
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <form id="speciality-form">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Name <span className="text-danger">*</span></label>
                                                                                    <input className="form-control" type="text" id={`name${speciality.id}`} name={`name${speciality.id}`}  required defaultValue={speciality.name}/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Description<span className="text-danger">*</span></label>
                                                                                    <textarea className="form-control" id={`description${speciality.id}`} name={`description${speciality.id}`}>{speciality.description}</textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Threshold Amount<span className="text-danger">*</span></label>
                                                                                    <input className="form-control" type="text" id={`threshold_amount${speciality.id}`} name={`threshold_amount${speciality.id}`}  defaultValue={speciality.threshold_amount} required/>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </form>

                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
                                                                    <button class="btn btn-primary" type="button" onClick={() => handleUpdate(speciality.id)}>{loading?<Fragment>Loading <i className="fa fa-spinner fa-pulse"></i></Fragment>:<Fragment> Update</Fragment>}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                     </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                               
            
           
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="text-center">
                                    {loading &&
                                        <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        </div>
    </div>
  );
}

function mapStateToProps({specialities}){
    return {error:specialities.error, success:specialities.success, next_page:specialities.next_page, loading:specialities.loading};
    
  }
export default connect(mapStateToProps, {loadMoreSpeciality, create_speciality, update_speciality, delete_speciality, search_keyword})(SpecialityComponent);
