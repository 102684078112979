import types from './types';
import axios from 'axios';
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
  web_loading: false,
  web_patients: [],
  web_error: null,
  web_success:null

};

function web_patients(state = INITIAL_STATE, action) {
  const { type, payload, next_page, count} = action;
  switch (type) {
    case 'FETCH_WEB_PATIENTS':
      return { ...state, web_loading: true };
    case 'FETCH_WEB_PATIENTS_SUCCESS':
      return { ...state, web_loading: false, web_patients: payload, web_success:null, web_error:null, web_next_page:next_page, web_count:count};
    case 'FETCH_WEB_PATIENTS_FAILURE':
      return { ...state, web_loading: false, error: payload };

      case 'SEARCH_WEB_PATIENT_SUCCESS':
        return { ...state, web_loading: false, web_patients: payload, web_error:null};
      case 'SEARCH_WEB_PATIENT_FAILED':
          return { ...state, web_loading: false, web_error: payload};

      case 'LOAD_MORE_WEB_PATIENTS_SUCCESS':
          var web_patients = state.web_patients.concat(payload)
          return { ...state, loading: false, web_patients: web_patients, web_error: null, web_success: null, web_next_page:next_page};
      case 'LOAD_MORE_WEB_PATIENTS_FAILURE':
          return { ...state, web_loading: false};
    default:
      return state;
  }
}



export const search_web_keyword = data => async dispatch => {
  try {
    var keyword = data.keyword?data.keyword:'';

   
      var patient = await axios.get(`${Literals.BASE_API}patients/web/backend/?page=1&page_size=100&keyword=${keyword}&organization=true`);
      document.getElementById("loader").classList.remove("hide");

    dispatch({ type: 'SEARCH_WEB_PATIENT_SUCCESS', payload: patient.data.results});
  } catch (e) {
    document.getElementById("loader").classList.remove("hide");
    dispatch({
      type: 'SEARCH_WEB_PATIENT_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const loadMoreWebPatients = api => async dispatch => {
  dispatch({ type: 'FETCH_WEB_PATIENTS' });
  try {
   
    const { data } = await axios.get(api);

    dispatch({ type: 'LOAD_MORE_WEB_PATIENTS_SUCCESS', payload: data.results, web_next_page:data.next});
  } catch (e) {
    dispatch({ type: 'LOAD_MORE_WEB_PATIENTS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};



export default web_patients;
