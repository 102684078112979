
import axios from 'axios'
import Literals from '../../helpers/Literals'
import billing from './reducer';
const INITIAL_STATE = {
    web_loading: false,
    web_billing: [],
    web_error: null,
  };
  
  function web_billing(state = INITIAL_STATE, action) {
    const { type, payload, next_page, count} = action;
      switch (type) {
        case 'FETCH_WEB_BILLING':
          return { ...state, web_loading: true,web_success:null,web_error:null };
        case 'FETCH_WEB_BILLING_SUCCESS':
            return { ...state, web_loading: false, web_billing: payload, web_success:null, web_error:null, web_next_page:next_page, web_count:count};
        case 'FETCH_WEB_BILLING_FAILED':
          return { ...state, web_loading: false, web_error: payload };
        case 'SEARCH_WEB_BILLING_SUCCESS':
          return { ...state, web_loading: false, web_billing: payload, web_error:null};
        case 'SEARCH_WEB_BILLING_FAILED':
          return { ...state, web_loading: false, web_error: payload, web_success:null};
        case 'LOAD_MORE_WEB_BILLING_SUCCESS':
            var web_billing = state.web_billing.concat(payload)
            return { ...state, web_loading: false, web_billing: web_billing, web_error: null, web_success: null, web_next_page:next_page};
        case 'LOAD_MORE_WEB_BILLING_FAILED':
            return { ...state, web_loading: false};

        case 'EXPORT_WEB_BILLING':
            return { ...state, web_loading: true, web_success:null, web_error:null};
        case 'EXPORT_WEB_BILLING_SUCCESS':
            return { ...state, web_loading: false, web_appointment: payload, web_success:payload.results, web_error:null};
        case 'EXPORT_WEB_BILLING_FAILED':
            return { ...state, web_loading: false, web_error: payload};
        default:
          return state;
      }
    }

    export const loadMoreWebBilling = api => async dispatch => {
        dispatch({ type: 'FETCH_WEB_BILLING' });
        try {
          const { data } = await axios.get(api);
      
          dispatch({ type: 'LOAD_MORE_WEB_BILLING_SUCCESS', payload: data.results, web_next_page:data.next});
        } catch (e) {
          dispatch({ type: 'LOAD_MORE_WEB_BILLING_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
        }
      };
    
      export const search_web_keyword = data => async dispatch => {
        try {
          var start_date = data.start_date?data.start_date:'';
          var end_date = data.end_date?data.end_date:'';
          var keyword = data.keyword?data.keyword:'';
          let response = await axios.get(`${Literals.BASE_API}web/backend/billing/?page=1&page_size=50&start_date=${start_date}&end_date=${end_date}&organization=true&keyword=${keyword}`);
          document.getElementById("loader").classList.add("hide");
          dispatch({ type: 'SEARCH_WEB_BILLING_SUCCESS', payload: response.data.results});
        } catch (e) {
          document.getElementById("loader").classList.add("hide");
          dispatch({
            type: 'SEARCH_WEB_BILLING_FAILED',
            payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
          });
        }
      };

      export const export_web_billing = data => async dispatch => {
        dispatch({ type: 'EXPORT_WEB_BILLING', web_success:null, web_error:null });
        try {
          var start_date = data.start_date?data.start_date:'';
          var end_date = data.end_date?data.end_date:'';
          var keyword = data.keyword?data.keyword:'';
          let billing = await axios.get(`${Literals.BASE_API}web/backend/billing/?export&organization=true&keyword=${keyword}&start_date=${start_date}&end_date=${end_date}`);
          dispatch({ type: 'EXPORT_WEB_BILLING_SUCCESS', payload: billing.data, web_success: billing.data});
        } catch (e){
          dispatch({
            type: 'EXPORT_WEB_BILLING_FAILED',
            payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
          });
        }
      };
      
  
    export default web_billing;
  