import React, {useEffect, useState, Fragment} from 'react';
import Menu from '../Menu/MenuComponent';
import Topnav from '../Menu/TopnavComponent';
import {Link} from 'react-router-dom';
import {search_keyword, create_subscription, delete_subscription, loadMoreSubscriptions} from './duck/reducer';
import {connect} from 'react-redux';


function SubscriptionComponent({ fetchSubscriptions, subscriptions = [], search_keyword, create_subscription, delete_subscription, error, success, next_page, loadMoreSubscriptions, 
    loading, delete_error, delete_success}) {
    useEffect(() => {
        fetchSubscriptions();
      }, [fetchSubscriptions]);

      const [state, setState] = useState({});
      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
	  }

    // function handleSearch(e) {
    //   e.preventDefault();
    //     document.getElementById("loader").classList.remove("hide");
    //       search_keyword(state);
    //   } 
      
    //   function handleClearField(e){
    //     e.preventDefault();
    //     document.getElementById("keyword").value='';
    //     state.keyword = ''
    //     document.getElementById("loader").classList.remove("hide");
    //     search_keyword(state);
    //     }


          function handleSubmit(e) {
            e.preventDefault();
            create_subscription(state)

          }

          function handleDelete(id, index) {
            delete_subscription(id, index)

          }
    
          function clearState(){
            setState({});
          }

    //infinite scroll
    var subscription_page = true
    window.onscroll = () => {
      // Checks that the page has scrolled to the bottom
          if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
              if(next_page && !loading && subscription_page){
                loadMoreSubscriptions(next_page);
              }
          }
    };
  
  return (
    <div className="c-app c-legacy-theme c-no-layout-transition" >
    <Menu/>
    <div className="c-wrapper">
        <Topnav/>
        <div className="c-body">
            <main className="c-main">
                <div className="container-fluid">
                    <div className="fade-in">
                        <div className="row">
                            <div className="col-sm-4 col-3">
                                {/* <h4 className="page-title">Doctors</h4> */}
                               
                                        {error &&
                                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    {/* <span aria-hidden="true">×</span> */}
                                                </button>
                                                <a className="text-center">{error}</a>
                                            </div>
                                        }

                            </div>
                            
                        </div>

                        <div className="card">
                            <div className="card-header"> 

                                <div className="row">
                                    <div className="col-md-3">
                                        {/* <label>Keyword</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" name="keyword" onChange={handleChange} id="keyword"/>
                                            <div className="input-group-append">
                                                <button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
                                            </div>
                                        </div> */}
                                    </div>
                                                
                                    <div className="col-md-2">
                                        {/* <div className="form-group">
                                            <label><br/></label>
                                            <br/>
                                            <button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search <i className="fa fa-spinner fa-pulse hide" id="loader"></i></button>
                                        </div> */}
                                    </div>
                                    <div className="col-md-7">
                                        <a href="#" className="btn btn btn-primary btn-rounded float-right" type="button" data-toggle="modal" data-target="#myModal"><i className="fa fa-plus"></i> Add Subscription</a>
                                        <div className="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">
                                                        {success &&
                                                           
                                                                <a className="text-center text-success">{success}</a>
                                                         }
                                                         {error &&
                                                           
                                                           <a className="text-center text-danger">{error}</a>
                                                        }
                                                        </h4>
                                                        <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <form id="subscription-form">
                                                        
                                                        <div className="row">
                                                            
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Name <span className="text-danger">*</span></label>
                                                                    <input className="form-control" type="text" name="name" onChange={handleChange} required/>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Frequency<span className="text-danger">*</span></label>
                                                                    <input className="form-control" type="text" name="frequency" onChange={handleChange} required/>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label>Amount<span className="text-danger">*</span></label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">GHC</span>
                                                                    </div>
                                                                    <input className="form-control" type="text" name="amount" onChange={handleChange} required/>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-sm-6">
                                                                <label>Duration<span className="text-danger">*</span></label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">Months</span>
                                                                    </div>
                                                                    <input className="form-control" type="number" name="duration" onChange={handleChange} required/>
                                                                </div>
                                                            </div>
                                                           

                                                            <div className="col-sm-12">
                                                            <br/>
                                                                <label>Colour Code<span className="text-danger"></span></label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">Colour</span>
                                                                    </div>
                                                                    <input className="form-control" type="color" name="colour_code" onChange={handleChange} required/>
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                        <br/>
                                                        <div className="row">
                                                            
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <label>Description <span className="text-danger">*</span></label>
                                                                    <textarea className="form-control" type="text" name="description" onChange={handleChange} required />
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button className="btn btn-secondary" type="button" data-dismiss="modal" onChange={() => clearState()}>Close</button>
                                                        <button className="btn btn-primary" type="button" onClick={handleSubmit} disabled={!(state.name && state.frequency && state.amount && state.duration && state.description) || loading}>{loading?<Fragment>Loading <i className="fa fa-spinner fa-pulse"></i></Fragment>:<Fragment> Save</Fragment>}</button>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
          
                            <div className="card-body">
                                <table className="table table-responsive-sm table-hover datatable">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Colour code</th>
                                            <th>Frequency</th>
                                            <th>Amount</th>
                                            <th>Duration</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subscriptions.map((subscription, index) => (
                                            <tr key={index}>
                                                <td>{subscription.name}</td>
                                                <td>{subscription.description}</td>
                                                <td>{subscription.colour_code}</td>
                                                <td>{subscription.frequency}</td>
                                                
                                                
                                                <td>{subscription.amount}</td>
                                                <td>{subscription.duration}</td>
                                                <td><Link herf="#" data-toggle="modal" data-target={`#delete_sub${subscription.id}`} className="btn btn-sm btn-pill btn-danger" type="button">Delete</Link>
                                                    <div className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" id={`delete_sub${subscription.id}`}>
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="modal-body text-center">
                                                                
                                                                    Are you sure delete {subscription.name} Subscription?
                                                                    <br/>
                                                                    <br/>
                                                                    {delete_success &&
                                                                        <div className="alert alert-success" role="alert"><strong>{subscription.name}</strong> successfully deleted</div>
                                                                    }
                                                                    {delete_error &&
                                                                        <div className="alert alert-danger" role="alert">Error deleting <strong>{subscription.name}</strong> subscription</div>
                                                                    }
                                                                    <br/>
                                                                    <div className="m-t-20">
                                                                        <button className="btn btn-secondary" type="button" data-dismiss="modal" onChange={() => clearState()}>Close</button>
                                                                        <button type="submit" className="btn btn-danger" onClick={() => handleDelete(subscription.id, index)} disabled={loading}>
                                                                            {loading?
                                                                                <Fragment><i className="fa fa-spinner fa-pulse"></i> loading...</Fragment>:<Fragment>Delete</Fragment>}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
            
           
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="text-center">
                                    {loading &&
                                        <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        </div>
    </div>
  );
}

function mapStateToProps({subscriptions}){
  return {error:subscriptions.error, success:subscriptions.success, next_page:subscriptions.next_page, loading:subscriptions.loading, 
    delete_success:subscriptions.delete_success, delete_error:subscriptions.delete_error};
  
}
export default connect(mapStateToProps, { search_keyword, create_subscription, delete_subscription, loadMoreSubscriptions })(SubscriptionComponent);