import React, {useEffect, useState, Fragment} from 'react';
import Menu from '../Menu/MenuComponent';
import Topnav from '../Menu/TopnavComponent';
import {Link} from 'react-router-dom';
import {loadMoreCallLogs, search_keyword} from './duck/get_callLogs_reducer';
import {loadMoreWebCallLogs, search_web_keyword} from './duck/web_callLogs_reducer';
import {connect} from 'react-redux';
import { formatDatetime} from '../helpers/utils';
import Moment from 'react-moment';



function CallLogsComponent({ fetchCallLogs, callLogs = [], error, success, next_page, loadMoreCallLogs, loading, search_keyword,
    fetchWebCallLogs, web_callLogs = [], web_error, web_success, web_next_page, loadMoreWebCallLogs, web_loading, search_web_keyword}) {
    useEffect(() => {
        fetchCallLogs(); fetchWebCallLogs();
      }, [fetchCallLogs,fetchWebCallLogs]);
      
      const [state, setState] = useState({is_complete:'', keyword:'', start_date:'', end_date:''});

      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
	  }

	  function handleSearch(e) {
		e.preventDefault();
		document.getElementById("loader").classList.remove("hide");
        if(tabState.tab=='app'){
        search_keyword(state);
        }else{
            search_web_keyword(state);
        }
	  } 

	  function handleClearField(e){
		e.preventDefault();
		document.getElementById("start_date").value='';
		document.getElementById("end_date").value='';
        document.getElementById("keyword").value='';
		state.start_date = ''
		state.end_date = ''
        state.keyword = ''
		document.getElementById("loader").classList.remove("hide");
        if(tabState.tab=='app'){
		search_keyword(state);
        }else{
            search_web_keyword(state)
        }
	}
	
     
    //infinite scroll
    var facility_page = true
    window.onscroll = () => {
      // Checks that the page has scrolled to the bottom
          if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            if(tabState.tab=='app'){
              if(next_page && !loading && facility_page){
                loadMoreCallLogs(next_page+'&start_date='+state.start_date+'&end_date='+state.end_date+'organization=false&keyword='+state.keyword);
              }
            }else{
                if(web_next_page && !web_loading && facility_page){
                    loadMoreWebCallLogs(web_next_page+'&start_date='+state.start_date+'&end_date='+state.end_date+'organization=true&keyword='+state.keyword);
                  }
            }
          }
    };

    const [tabState, setTabState] = useState({tab:'app'});
	function handleTabChange(tab) {
		setTabState({ tab:tab });
	}
  
  return (
    <div className="c-app c-legacy-theme c-no-layout-transition" >
    <Menu/>
    <div className="c-wrapper">
        <Topnav/>
        <div className="c-body">
            <main className="c-main">
                <div className="container-fluid">
                    <div className="fade-in">
                       

                        <div className="card">
                            <div className="card-header">
                            <div className="row">
                                    
                                    <div className="col-md-5">
                                    <label>Start Date</label>
                                    <label className="offset-md-4"> End Date</label>
                                        <div className="input-group">
                                            <input type="date" className="form-control" id="start_date" name="start_date" onChange={handleChange} placeholder="YYYY-MM-DD" required pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"/>
                                            <input type="date" className="form-control" id="end_date" name="end_date" onChange={handleChange} placeholder="YYYY-MM-DD" required pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"/>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Keyword</label>
                                        <div className="input-group">
                                        <input type="text" className="form-control" name="keyword" onChange={handleChange} id="keyword"/>
                                            <div className="input-group-append">
                                                <button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label><br/></label>
                                            <br/>
                                            <button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search <i className="fa fa-spinner fa-pulse hide" id="loader"></i></button>
                                        </div>
                                    </div>
													
                                </div>

                                
                            </div>
                            <div class="row">
                                <div class="col-md-12 mb-4">
                                    <div class="nav-tabs-boxed">
                                    <ul class="nav nav-tabs" role="tablist">
                                        <li class="nav-item" onClick={() => handleTabChange('app')}><a class="nav-link active" data-toggle="tab" href="#home-1" role="tab" aria-controls="home"><strong><i className="fa fa-mobile-phone"></i> App</strong></a></li>
                                        <li class="nav-item" onClick={() => handleTabChange('web')}><a class="nav-link" data-toggle="tab" href="#profile-1" role="tab" aria-controls="profile"><strong><i className="fa fa-desktop"></i> Web</strong></a></li>
                                    </ul>
                                    <div class="tab-content">
                                        <div class="tab-pane active" id="home-1" role="tabpanel">
                                            <div className="card-body">
                                                <table className="table table-responsive-sm table-hover datatable">
                                                    <thead>
                                                        <tr>
                                                            <th>Call Date</th>
                                                            <th>Call type</th>
                                                            <th style={{width:"300px"}}>Professional</th>
                                                            <th style={{width:"300px"}}>Patient</th>
                                                            <th>Call Ended</th>
                                                            <th>Call Duration</th>
                                                            <th>Call status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {callLogs.map(call => (
                                                            <tr key={call.id}>
                                                                <td>{formatDatetime(call.start_date)}</td>
                                                                <td><span className="badge badge-primary">{call.call_type=='audio'?<i className="fa fa-volume-control-phone"></i>:<i className="fa fa-video-camera"></i>} {call.call_type}</span></td>
                                                                <td>
                                                                {call.professional &&<Fragment>
                                                            <Link to={`/professional_profile?id=${call.professional.id}`}>
                                                                {call.professional.avatar && 
                                                                <img width="28" height="28" src={call.professional.avatar} className="rounded-circle m-r-5" alt=""/> 
                                                                }
                                                                {!call.professional.avatar && 
                                                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
                                                                }
                                                                {call.professional.first_name} {call.professional.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {call.professional.id}</span>
                                                            </Link>
                                                            <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{call.professional.email}</a>
                                                                    <a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{call.professional.phone_number}</a>
                                                                    <a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{call.professional.gender}</a>
                                                                    <a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{call.professional.date_of_birth}</a>
                                                                </div>
                                                                </Fragment>}
                                                                
                                                                </td>
                                                                <td>
                                                                {call.patient &&
                                                                <Fragment>
                                                                <Link to={`/patient_profile?id=${call.patient.id}`}>
                                                                    {call.patient.avatar && 
                                                                    <img width="28" height="28" src={call.patient.avatar} className="rounded-circle m-r-5" alt=""/> 
                                                                    }
                                                                    {!call.patient.avatar && 
                                                                    <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
                                                                    }
                                                                    {call.patient.first_name} {call.patient.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {call.patient.id}</span>
                                                                </Link>
                                                                <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                                    </a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{call.patient.email}</a>
                                                                        <a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{call.patient.phone_number}</a>
                                                                        <a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{call.patient.gender}</a>
                                                                        <a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{call.patient.date_of_birth}</a>
                                                                    </div>
                                                                    </Fragment>
                                                                }
                                                                
                                                                </td>
                                                                
                                                                <td>{formatDatetime(call.end_date)}</td>
                                                                <td><span className="badge badge-dark"><i className="fa fa-clock-o"></i> {call.call_status=='successful'?<Moment duration={call.start_date} date={call.end_date}/>:'0:00'}</span></td>
                                                                <td>{call.call_status=='successful'?<span className="badge badge-success"><i className="fa fa-check"></i> {call.call_status}</span>:<span className="badge badge-danger"><i className="fa fa-ban"></i> {call.call_status}</span>}</td>
                                                            
                                                                
                                                            
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                            
                        
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="text-center">
                                                    {loading &&
                                                        <div class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane" id="profile-1" role="tabpanel">
                                        <div className="card-body">
                                                <table className="table table-responsive-sm table-hover datatable">
                                                    <thead>
                                                        <tr>
                                                            <th>Call Date</th>
                                                            <th>Call type</th>
                                                            <th style={{width:"300px"}}>Professional</th>
                                                            <th style={{width:"300px"}}>Patient</th>
                                                            <th>Call Ended</th>
                                                            <th>Call Duration</th>
                                                            <th>Call status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {web_callLogs.map(call => (
                                                            <tr key={call.id}>
                                                                <td>{formatDatetime(call.start_date)}</td>
                                                                <td><span className="badge badge-primary">{call.call_type=='audio'?<i className="fa fa-volume-control-phone"></i>:<i className="fa fa-video-camera"></i>} {call.call_type}</span></td>
                                                                <td>
                                                                {call.professional &&<Fragment>
                                                            <Link to={`/professional_profile?id=${call.professional.id}`}>
                                                                {call.professional.avatar && 
                                                                <img width="28" height="28" src={call.professional.avatar} className="rounded-circle m-r-5" alt=""/> 
                                                                }
                                                                {!call.professional.avatar && 
                                                                <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
                                                                }
                                                                {call.professional.first_name} {call.professional.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {call.professional.id}</span>
                                                            </Link>
                                                            <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{call.professional.email}</a>
                                                                    <a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{call.professional.phone_number}</a>
                                                                    <a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{call.professional.gender}</a>
                                                                    <a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{call.professional.date_of_birth}</a>
                                                                </div>
                                                                </Fragment>}
                                                                
                                                                </td>
                                                                <td>
                                                                {call.patient &&
                                                                <Fragment>
                                                                <Link to={`/patient_profile?id=${call.patient.id}`}>
                                                                    {call.patient.avatar && 
                                                                    <img width="28" height="28" src={call.patient.avatar} className="rounded-circle m-r-5" alt=""/> 
                                                                    }
                                                                    {!call.patient.avatar && 
                                                                    <img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
                                                                    }
                                                                    {call.patient.first_name} {call.patient.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {call.patient.id}</span>
                                                                </Link>
                                                                <a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                                    </a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{call.patient.email}</a>
                                                                        <a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{call.patient.phone_number}</a>
                                                                        <a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{call.patient.gender}</a>
                                                                        <a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{call.patient.date_of_birth}</a>
                                                                    </div>
                                                                    </Fragment>
                                                                }
                                                                
                                                                </td>
                                                                
                                                                <td>{formatDatetime(call.end_date)}</td>
                                                                <td><span className="badge badge-dark"><i className="fa fa-clock-o"></i> {call.call_status=='successful'?<Moment duration={call.start_date} date={call.end_date}/>:'0:00'}</span></td>
                                                                <td>{call.call_status=='successful'?<span className="badge badge-success"><i className="fa fa-check"></i> {call.call_status}</span>:<span className="badge badge-danger"><i className="fa fa-ban"></i> {call.call_status}</span>}</td>
                                                            
                                                                
                                                            
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                            
                        
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="text-center">
                                                    {web_loading &&
                                                        <div class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        </div>
    </div>
  );
}

function mapStateToProps({callLogs, web_callLogs}){
    return {error:callLogs.error, success:callLogs.success, next_page:callLogs.next_page, loading:callLogs.loading,
        web_error:web_callLogs.error, web_success:web_callLogs.web_success, web_next_page:web_callLogs.web_next_page, web_loading:web_callLogs.web_loading};
    
  }
export default connect(mapStateToProps, {loadMoreCallLogs, search_keyword, loadMoreWebCallLogs, search_web_keyword})(CallLogsComponent);
