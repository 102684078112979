import axios from 'axios';
import Literals from '../../helpers/Literals'
const INITIAL_STATE = {
  web_loading: false,
  web_doctors: [],
  web_error: null,
  web_success:null,
 
};

function web_doctors(state = INITIAL_STATE, action) {
  const { type, payload, next_page, prev_page, count, index} = action;
  switch (type) {
    case 'FETCH_WEB_DOCTORS':
      return { ...state, web_loading: true };
    case 'FETCH_WEB_DOCTORS_SUCCESS':
      return { ...state, web_loading: false, web_doctors: payload, web_success:null, web_error:null, web_next_page:next_page, prev_page:prev_page, web_count:count};
    case 'FETCH_WEB_DOCTORS_FAILED':
      return { ...state, web_loading: false, web_error: payload, web_success:null};

    case 'VERIFY_WEB_DOCTOR_SUCCESS':
      state.web_doctors[index] = payload
      var web_doctors = state.web_doctors
      return { ...state, web_loading: false, web_doctors: web_doctors};
    case 'VERIFY_WEB_DOCTOR_FAILED':
      return { ...state, web_loading: false, web_error: payload};
    case 'SEARCH_WEB_DOCTOR_SUCCESS':
      return { ...state, web_loading: false, web_doctors: payload};
    case 'SEARCH_WEB_DOCTOR_FAILED':
      return { ...state, web_loading: false, web_error: payload};
    case 'LOAD_MORE_WEB_DOCTORS_SUCCESS':
        var web_doctors = state.web_doctors.concat(payload)
      return { ...state, web_loading: false, web_doctors: web_doctors, web_error: null, web_success: null, web_next_page:next_page};
    case 'LOAD_MORE_WEB_DOCTORS_FAILURE':
      return { ...state, loading: false};

    case 'LOAD_PAGE_WEB_DOCTORS_SUCCESS':
      return { ...state, web_loading: false, web_doctors: payload, web_success:null, web_error:null, web_next_page:next_page, web_prev_page:prev_page, count:count};
    case 'LOAD_PAGE_WEB_DOCTORS_FAILED':
      return { ...state, loading: false, error: payload, success:null};

    default:
      return state;
  }
}


export const verifyWebProfessional = data => async dispatch => {
  dispatch({ type: 'FETCH_WEB_DOCTORS',  loading:true, error:false, success:false});
  try {
    await axios.put(`${Literals.BASE_API}professional/backend/${data.id}/set_status/`, data);
    document.getElementById("loader"+ data.id +"").classList.add("hide");
    var response  = await axios.get(`${Literals.BASE_API}professional/backend/?page=1&page_size=50&organization=true`);

    dispatch({ type: 'VERIFY_WEB_DOCTOR_SUCCESS', payload: response.data.results, web_success:true, index:data.index});
  } catch (e){
    document.getElementById("loader"+ data.id +"").classList.add("hide");
    dispatch({
      type:'VERIFY_WEB_DOCTOR_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};


export const search_web_keyword = data => async dispatch => {

  try {
    var keyword = data.keyword?data.keyword:'';
    let response = await axios.get(`${Literals.BASE_API}professional/backend/?page=1&page_size=100&keyword=${keyword}`);
    document.getElementById("loader").classList.add("hide");
    dispatch({ type: 'SEARCH_WEB_DOCTOR_SUCCESS', payload: response.data.results});
  } catch (e) {
    document.getElementById("loader").classList.add("hide");
    dispatch({
      type: 'SEARCH_WEB_DOCTOR_FAILED',
      payload: e.response?e.response.data.details:Literals.ERROR_OCCURED,
    });
  }
};

export const loadMoreWebDoctors = api => async dispatch => {
  dispatch({ type: 'FETCH_WEB_DOCTORS' });
  try {
    const { data } = await axios.get(api);

    dispatch({ type: 'LOAD_MORE_WEB_DOCTORS_SUCCESS', payload: data.results, next_page:data.next});
  } catch (e) {
    dispatch({ type: 'LOAD_MORE_WEB_DOCTORS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};

export const loadPageWebDoctors = api => async dispatch => {
  dispatch({ type: 'FETCH_WEB_DOCTORS' });
  try {
    const { data } = await axios.get(api);

    dispatch({ type: 'LOAD_PAGE_WEB_DOCTORS_SUCCESS', payload: data.results, next_page:data.next, prev_page:data.previous, count:data.count});
  } catch (e) {
    dispatch({ type: 'LOAD_PAGE_WEB_DOCTORS_FAILED', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};

export default web_doctors;
