import React, {Component} from 'react';
import { Link } from 'react-router-dom';


class Menu extends Component {
    
    render(){
        var location = window.location.pathname;

        const admin_permissions = JSON.parse(localStorage.getItem('permissions'))?JSON.parse(localStorage.getItem('permissions')):{permissions:null};
        if(admin_permissions.permissions){
            var facilities_read = admin_permissions.permissions?.facilities.read
            var admins_read = admin_permissions.permissions?.admins.read
            var agents_read = admin_permissions.permissions?.agents.read
            var referrals_read = admin_permissions.permissions?.referrals.read
            var subscriptions_read = admin_permissions.permissions?.subscriptions.read
            var professionals_read = admin_permissions.permissions?.professionals.read
            var patients_read = admin_permissions.permissions?.patients.read
            var appointments_read = admin_permissions.permissions?.appointments.read
            var billing_read = admin_permissions.permissions?.billing.read
            var calls_read = admin_permissions.permissions?.calls.read
            var specialites_read = admin_permissions.permissions?.specialites.read
            var languages_read = admin_permissions.permissions?.languages.read
            var pharmacy_read = admin_permissions.permissions?.pharmacy.read
            var lab_read = admin_permissions.permissions?.lab?.read
            
        }else{
            var facilities_read = true
            var admins_read = true
            var agents_read = true
            var referrals_read = true
            var subscriptions_read = true
            var professionals_read = true
            var patients_read =true
            var appointments_read = true
            var billing_read = true
            var calls_read = true
            var specialites_read = true
            var languages_read = true
            var pharmacy_read = true
            var lab_read =true
        }

        var patient_links = ["/patients", "/add_patients"];
        var appointment_links = ["/appointments"];
        var facility_links = ["/facilities", ];
        var admins_links = ["/add_admin", "/admins"];
        var doctors_links = ["/add_professionals", "/professionals", "/edit_professional"];


        var settings_menu = document.getElementById('settings-menu')
        if(settings_menu){
            var settings_menu = settings_menu.classList.contains('c-show');
        }
       function openMenu(e){
        e.preventDefault();
            if(settings_menu){
                document.getElementById('settings-menu').classList.remove('c-show');

            }
            if(!settings_menu){
                document.getElementById('settings-menu').classList.add('c-show');

            }
            
           

       }
        
        return (
            <div className="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show" id="sidebar">
                <div className="c-sidebar-brand d-md-down-none">
                    <svg className="c-sidebar-brand-full" style={{width:"118", height:"46"}} alt="CoreUI Logo"></svg>
                </div>
                <ul className="c-sidebar-nav">
                    <li className="c-sidebar-nav-item">
                        <Link to="/dashboard" className={location === '/dashboard'?"c-sidebar-nav-link c-active":"c-sidebar-nav-link"}>
                            <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-dashboard"></i>&nbsp;Dashboard
                                {/* <span className="badge badge-info">NEW</span> */}
                        </Link>
                    </li>
                    {facilities_read &&
                        <li className="c-sidebar-nav-item">
                            <Link className={location === '/facilities'?"c-sidebar-nav-link c-active":"c-sidebar-nav-link"} to="/facilities">
                                <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-hospital-o"></i>&nbsp;Facilities
                            </Link>
                        </li>
                     }
                     {admins_read &&
                    <li className="c-sidebar-nav-item">
                        <Link className={location === '/admins'?"c-sidebar-nav-link c-active":"c-sidebar-nav-link"} to="/admins">
                            <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-users"></i>&nbsp;Admins
                        </Link>
                    </li>
                     }

                     {agents_read &&
                    <li className="c-sidebar-nav-item">
                        <Link className={location === '/agents'?"c-sidebar-nav-link c-active":"c-sidebar-nav-link"} to="/agents">
                            <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-address-book"></i>&nbsp;Agents
                        </Link>
                    </li>
                    }
                    {referrals_read &&
                    <li className="c-sidebar-nav-item">
                        <Link className={location === '/referrals'?"c-sidebar-nav-link c-active":"c-sidebar-nav-link"} to="/referrals">
                            <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-handshake-o"></i>&nbsp;Referrals
                        </Link>
                    </li>
                    }

                    {subscriptions_read &&
                    <li className="c-sidebar-nav-item">
                        <Link className={location === '/subscriptions'?"c-sidebar-nav-link c-active":"c-sidebar-nav-link"} to="/subscriptions">
                            <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-ticket"></i>&nbsp;Subscriptions
                        </Link>
                    </li>
                    }

                    {professionals_read && 
                    <li className="c-sidebar-nav-item">
                        <Link className={location === '/professionals'?"c-sidebar-nav-link c-active":"c-sidebar-nav-link"} to="/professionals">
                            <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-drivers-license"></i>&nbsp;Professionals
                        </Link>
                    </li>
                    }

                    {patients_read &&
                    <li className="c-sidebar-nav-item">
                        <Link className={location === '/patients'?"c-sidebar-nav-link c-active":"c-sidebar-nav-link"} to="/patients">
                            <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-wheelchair"></i>&nbsp;Patients
                        </Link>
                    </li>
                    }
                    {appointments_read &&
                    <li className="c-sidebar-nav-item">
                        <Link className={location === '/appointments'?"c-sidebar-nav-link c-active":"c-sidebar-nav-link"} to="/appointments">
                            <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-calendar"></i>&nbsp;Appointments
                        </Link>
                    </li>
                    }
                    {billing_read &&
                    <li className="c-sidebar-nav-item">
                        <Link className={location === '/billing'?"c-sidebar-nav-link c-active":"c-sidebar-nav-link"} to="/billing">
                            <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-money"></i>&nbsp;Billing
                        </Link>
                    </li>
                    }
                    {calls_read &&
                    <li className="c-sidebar-nav-item">
                        <Link className={location === '/call_logs'?"c-sidebar-nav-link c-active":"c-sidebar-nav-link"} to="/call_logs">
                            <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-phone"></i>&nbsp;Call logs
                        </Link>
                    </li>
                    }

                    {lab_read &&
                        <li className="c-sidebar-nav-item">
                            <Link className={location.includes('/lab')?"c-sidebar-nav-link c-active":"c-sidebar-nav-link"} to="/search/0/lab">
                                <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-flask"></i>&nbsp;Lab
                            </Link>
                        </li>
                    }
                    {pharmacy_read &&
                        <li className="c-sidebar-nav-item">
                            <Link className={location.includes('/medication')?"c-sidebar-nav-link c-active":"c-sidebar-nav-link"} to="/search/0/medication">
                                <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-pencil-square-o"></i>&nbsp;Prescription
                            </Link>
                        </li>
                    }

                    {(specialites_read || languages_read) &&
                    <li className="c-sidebar-nav-dropdown" id="settings-menu" onClick={openMenu}>
                        <a className="c-sidebar-nav-dropdown-toggle" href="#">
                        <svg className="c-sidebar-nav-icon"></svg><i className="fa fa-cog"></i>&nbsp;Settings</a>
                        <ul className="c-sidebar-nav-dropdown-items">

                            {specialites_read && <li className="c-sidebar-nav-item"><Link className="c-sidebar-nav-link" to="/specialities"> Specialities</Link></li>}
                            {languages_read && <li className="c-sidebar-nav-item"><Link className="c-sidebar-nav-link" to="/languages"> Languages</Link></li>}
        
                        </ul>
                    </li>
                    }
                </ul>
            
            </div>
        );
    }
}

export default Menu;
