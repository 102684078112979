import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { getUser } from './app/auth/ducks/reducer';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './app/routes';
import store from './app/store';



const user = store.getState().auth.user;

function App() {
  useEffect(() => {
    !user && store.dispatch(getUser());
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Routes />
      </Router>
    </Provider>
  );
}


export default App;
