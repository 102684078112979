const FETCH_PATIENTS = 'FETCH_PATIENTS';
const FETCH_PATIENTS_SUCCESS = 'FETCH_PATIENTS_SUCCESS';
const FETCH_PATIENTS_FAILURE = 'FETCH_PATIENTS_FAILURE';
const SUCCESS = 'SUCCESS'
const FAILED = 'FAILED'

export default {
  FETCH_PATIENTS,
  FETCH_PATIENTS_FAILURE,
  FETCH_PATIENTS_SUCCESS,
  SUCCESS,
  FAILED,

};
