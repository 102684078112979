import React, {useEffect, useState, Fragment} from 'react';
import Menu from '../Menu/MenuComponent'
import Topnav from '../Menu/TopnavComponent'
import {Link} from 'react-router-dom'
import { formatDatetime } from '../helpers/utils';
import { search_keyword, export_appointment, loadMoreAppointments, assign_appointment, revert_completed} from './duck/reducer';
import { search_web_keyword, export_web_appointment, loadMoreWebAppointments, assign_web_appointment, revert_web_completed} from './duck/web_reducer';
import {connect} from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead';
import { allDoctorsList} from '../helpers/utils';


function AppointmentsComponent({ fetchAppointments, fetchWebAppointments, appointments = [], web_appointments = [], search_keyword, search_web_keyword, export_appointment, export_web_appointment, error,web_error, success,web_success, loadMoreAppointments,loadMoreWebAppointments, next_page,web_next_page, loading, web_loading, assign_appointment, assign_web_appointment, revert_completed, revert_web_completed}) {
	useEffect(() => {
        fetchAppointments();
		fetchWebAppointments();
	}, [fetchAppointments, fetchWebAppointments]);
	
	const [state, setState] = useState({is_complete:'', keyword:'', start_date:'', end_date:''});


    function handleExport() {
		document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
		if(tabState.tab=='app'){
        	export_appointment(state);
		}else{
			export_web_appointment(state);
		}

	  } 
	
	 
      function handleChange({ target }) {
        setState({ ...state, [target.name]: target.value });
	  }

	  function handleSearch(e) {
		e.preventDefault();
		document.getElementById("loader").classList.remove("hide");
		if(tabState.tab=='app'){
        search_keyword(state);
		}else{
			search_web_keyword(state);
		}
	  } 

	  function handleClearField(e){
		e.preventDefault();
		document.getElementById("start_date").value='';
		document.getElementById("end_date").value='';
		document.getElementById("keyword").value='';
		state.keyword = ''
		state.start_date = ''
		state.end_date = ''
		state.is_complete = ''
		document.getElementById("loader").classList.remove("hide");
		if(tabState.tab=='app'){
		search_keyword(state);
		}else{
			search_web_keyword(state);
		}
	}

	const [stateDoctor, setDoctor] = useState({});
	function handleAssgin(id) {
		var start_date = document.getElementById("start_date"+ id +"").value
		var start_time = document.getElementById("start_time"+ id +"").value
		stateDoctor.appointment_id = id;
		stateDoctor.start_date = start_date+' '+start_time;
		if(stateDoctor.appointment_id && stateDoctor.start_date && start_time){
			document.getElementById("assign-loader"+id).classList.remove("hide");
			if(tabState.tab=='app'){
        	assign_appointment(stateDoctor);
			}else{
				assign_web_appointment(stateDoctor);
			}
		}

      } 
	const handleSelectDoctor = (event) => {
        setDoctor({professional_id: event[0]?event[0].id:'' });
    }
	var doctors = allDoctorsList();
	
	


	//permissions
	const admin_access = JSON.parse(localStorage.getItem('permissions'))?JSON.parse(localStorage.getItem('permissions')):{permissions:null};
	if(admin_access.permissions){
	   
		var appointments_write = admin_access.permissions.appointments.write
		var appointments_write = admin_access.permissions.appointments.write
	}else{
	   
		var appointments_write = true
		var appointments_write = true
	}

	//infinite scroll
	window.onscroll = () => {
		// Checks that the page has scrolled to the bottom
			if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
				
					if(tabState.tab=='app'){
						if(next_page && !loading){
							loadMoreAppointments(next_page+'&keyword='+state.keyword+'&start_date='+state.start_date+'&end_date='+state.end_date+'&is_complete='+state.is_complete+'&organization=false');
						}
					}else{
						if(web_next_page && !web_loading){
							loadMoreWebAppointments(web_next_page+'&keyword='+state.keyword+'&start_date='+state.start_date+'&end_date='+state.end_date+'&is_complete='+state.is_complete+'&organization=true');
						}
					}
				
			}
		};
	const [tabState, setTabState] = useState({tab:'app'});
	function handleTabChange(tab) {
		setTabState({ tab:tab });
	}

	function handleRevert(id, index){
		console.log('id--',id)
		console.log('index--',index)
		document.getElementById("revert-loader"+id)?.classList?.remove("hide");
		revert_completed(id, index)

	}
  return (
    <div className="c-app c-legacy-theme c-no-layout-transition" >
            <Menu/>
            <div className="c-wrapper">
                <Topnav/>
                <div className="c-body">
                    <main className="c-main">
                        <div className="container-fluid">
                            <div className="fade-in">
                                <div className="row">
                                    <div className="col-sm-4 col-3">
                                        {success &&
                                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                {/* <span aria-hidden="true">×</span> */}
                                            </button>
                                            <a href={success} className="text-center"><i className="fa fa-download"></i> Click to Download</a>
                                        </div>
                                    }
                                    {error &&
                                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                {/* <span aria-hidden="true">×</span> */}
                                            </button>
                                            <a className="text-center">{error}</a>
                                        </div>
                                    }
                                    </div>
                                    
                                </div>
                                    
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-header"> 
                                            	<div className="row">
													<div className="col-md-3">
														<div className="form-group">
															<label>Keyword</label>
															<input type="text" className="form-control" name="keyword" onChange={handleChange} id="keyword"/>
														</div>
													</div>
													<div className="col-md-5">
													<label>Start Date</label>
													<label className="offset-md-4"> End Date</label>
														<div className="input-group">
															<input type="date" className="form-control" id="start_date" name="start_date" onChange={handleChange} placeholder="YYYY-MM-DD" required pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"/>
															<input type="date" className="form-control" id="end_date" name="end_date" onChange={handleChange} placeholder="YYYY-MM-DD" required pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"/>
															<div className="input-group-append">
																<button className="btn btn-secondary" type="button" onClick={handleClearField}>Clear</button>
															</div>
														</div>
													</div>
													<div class="col-md-2">
														<label>Status</label>
														<select class="c-multi-select" id="select-10" name="is_complete" onChange={handleChange}>
														<option value="">Select</option>
														<option value="true">Completed</option>
														<option value="false">Not completed</option>
														</select>
													</div>
													
													<div className="col-md-2">
														<div className="form-group">
															<label><br/></label>
															<br/>
															<button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa fa-search"></i> Search <i className="fa fa-spinner fa-pulse hide" id="loader"></i></button>
														</div>
													</div>
													
												</div>
												<div className="row">
													<div className="col-md-12">
														<button className="btn btn btn-primary btn-rounded float-right" onClick={handleExport} disabled={loading}>{loading?<Fragment>Loading <i className="fa fa-spinner fa-pulse"></i></Fragment>:<Fragment><i className="fa fa-file-excel-o"></i> Export</Fragment>}</button>
                                                	</div>
												</div>
											</div>
											<div class="row">
												<div class="col-md-12 mb-4">
													<div class="nav-tabs-boxed">
													<ul class="nav nav-tabs" role="tablist">
														<li class="nav-item" onClick={() => handleTabChange('app')}><a class="nav-link active" data-toggle="tab" href="#home-1" role="tab" aria-controls="home"><strong><i className="fa fa-mobile-phone"></i> App</strong></a></li>
														<li class="nav-item" onClick={() => handleTabChange('web')}><a class="nav-link" data-toggle="tab" href="#profile-1" role="tab" aria-controls="profile"><strong><i className="fa fa-desktop"></i> Web</strong></a></li>
													</ul>
													<div class="tab-content">
														<div class="tab-pane active" id="home-1" role="tabpanel">
                                                <div className="card-body">
												<table className="table table-responsive-sm table-hover datatable">
													<thead>
														<tr>
															<th>Date</th>
															<th>Patient</th>
															<th>Professional</th>
															<th>Bill paid</th>
															<th className="text-center">Status</th>
															<th className="text-center">completed</th>
															{/* <th className="text-center">Facility</th> */}
															<th className="text-center" width="110px">Action</th>
														</tr>
													</thead>
													<tbody>
													{appointments.map((appointment, index) => (
														<tr key={appointment.id}>
															<td>{formatDatetime(appointment.start_date)}</td>
															
															<td>
																{appointment.patient && <Fragment>
																	
																<Link to={`/patient_profile?id=${appointment.patient.id}`}>
																	{appointment.patient.avatar && 
																	<img width="28" height="28" src={appointment.patient.avatar} className="rounded-circle m-r-5" alt=""/> 
																	}
																	{!appointment.patient.avatar && 
																	<img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
																	}
																	{appointment.patient.first_name} {appointment.patient.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {appointment.patient.id}</span>
																</Link>
																<a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
																	</a>
																	<div className="dropdown-menu dropdown-menu-right">
																		<a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{appointment.patient.email}</a>
																		<a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{appointment.patient.phone_number}</a>
																		<a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{appointment.patient.gender}</a>
																		<a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{appointment.patient.date_of_birth}</a>
																	</div>
																</Fragment>
																}
																
															</td>
															<td>
																{appointment.professional && <Fragment>
																	
																<Link to={`/professional_profile?id=${appointment.professional.id}`}>
																	{appointment.professional.avatar && 
																	<img width="28" height="28" src={appointment.professional.avatar} className="rounded-circle m-r-5" alt=""/> 
																	}
																	{!appointment.professional.avatar && 
																	<img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
																	}
																	{appointment.professional.first_name} {appointment.professional.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {appointment.professional.id}</span>
																</Link>
																<a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
																	</a>
																	<div className="dropdown-menu dropdown-menu-right">
																		<a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{appointment.professional.email}</a>
																		<a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{appointment.professional.phone_number}</a>
																		<a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{appointment.professional.gender}</a>
																		<a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{appointment.professional.date_of_birth}</a>
																	</div>
																</Fragment>
																}
																
															</td>
															<td>
																{appointment.bill_paid==false &&
																<div className="dropdown action-label">
																	<a className="custom-badge status-red dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
																	Not Paid
																	</a>
																	<div className="dropdown-menu dropdown-menu-right">
																		<a className="dropdown-item" href={`mailto:${appointment.patient.email}?subject=Payment%20link%20to%20make%20payment&body=${appointment.checkout_url}`}>
																		<kbd>{appointment.checkout_url}</kbd> 
																		<button className="custom-badge status-green">Email payment link</button></a>
																		<a className="dropdown-item" onClick={() =>  navigator.clipboard.writeText(''+ `${appointment.checkout_url}` +'')}><button className="custom-badge status-green">Copy link</button></a>
																	</div>
																</div>
																}
																{appointment.bill_paid==true && <span className="badge badge-info">Paid</span>}
															</td>
															<td className="text-center">
																{appointment.payment_option=='nhis' && appointment.bill_paid==false &&
																	<div>
																		{appointments_write &&
																		
																		<span className="badge badge-warning"><i className="fa fa-clock-o"></i> Pending Approval</span>
																		}
																	</div>
																}
																{['nhis', 'card', 'mobile_money'].includes(appointment.payment_option) && appointment.bill_paid==true &&
																	<Fragment>
																		{appointment.professional &&
																			<Fragment>
																				
																				<a className="custom-badge status-red dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
																					<div className="text-center"><span className="badge badge-success"><i className="fa fa-random"></i> Assigned</span>	</div>
																				</a>
																				<div className="dropdown-menu dropdown-menu-right">
																					<a className="dropdown-item" ><i className="fa fa-drivers-license"></i>&nbsp;<span className="badge badge-dark">ID: {appointment.professional.id}</span> {appointment.professional.first_name} {appointment.professional.last_name}</a>
																					<a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{appointment.professional.email}</a>
																					<a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{appointment.professional.phone_number}</a>
																					<a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{appointment.professional.gender}</a>
																					<a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{appointment.professional.date_of_birth}</a>
																				</div>
																			</Fragment>
																		
																		}
																		{!appointment.professional &&
																			<div className="text-center"><span className="badge badge-success"><i className="fa fa-check"></i> Approved</span></div>
																		}
																	</Fragment>
																	// <span className="custom-badge status-grey"><i className="fa fa-remove"></i> Not Approved</span>
																}
																{['card', 'mobile_money'].includes(appointment.payment_option) && appointment.bill_paid==false &&
																	<div className="text-center"><span className="badge badge-danger"><i className="fa fa-warning"></i> Payment not completed</span></div>
																}
															</td>
															<td className="text-center">{appointment.is_complete && <span className="badge badge-dark">Yes</span>}
															    {!appointment.is_complete && <span className="badge badge-light">No</span>}
															
															</td>
															<td className="text-center">
																
																<div className="dropdown dropdown-action">
																	<a href="#"  data-toggle="dropdown"
																		aria-expanded="false">
																		<i className="fa fa-ellipsis-v"></i>
																	</a>
																	<div className="dropdown-menu dropdown-menu-right">
																		{appointment.professional &&
																			<a className="dropdown-item" href="#" data-toggle="modal" data-target={`#assign_appointment${appointment.id}`} ><i className="fa fa-random"></i>&nbsp;Re-assign</a>
																		}
				
																		{appointment.is_complete &&
																			<a className="dropdown-item" href="#" data-toggle="modal" data-target={`#revert${appointment.id}`}>
																				<i className="fa fa-retweet m-r-5"></i>&nbsp;Revert Completed
																			</a>
																		}
																		
																	</div>
																</div>
																<div className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id={`revert${appointment.id}`}>
																	<div className="modal-dialog modal-dialog-centered">
																		<div className="modal-content">
																			<div className="modal-header">
																				Revert Completed Appointment
																				<button type="button" className="close" data-dismiss="modal" aria-label="Close">
																					<span aria-hidden="true">&times;</span>
																				</button>
																			</div>
																			<div className="modal-body">
																				<form >
																					<div className="row">
																						
																						<div className="col-sm-12">
																							<div className="form-group">
																								<label>Are you sure you want to revert {appointment.patient.first_name}'s appointment as not completed.<span className="text-danger"></span></label>
																								
																							</div>
																						</div>
																						
																					</div>
																				</form>

																			</div>
																			<div class="modal-footer">
																				<button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
																				<button id={`revert-button${appointment.id}`} data-dismiss="modal" type="button" className="btn btn-primary" onClick={() => handleRevert(appointment.id, index)} disabled={!appointment.is_complete}>Revert <i className="fa fa-spinner fa-pulse hide" id={`revert-loader${appointment.id}`}></i></button>
																			</div>
																		</div>
																	</div>
																</div>
																
																<div className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id={`assign_appointment${appointment.id}`}>
																	<div className="modal-dialog modal-dialog-centered">
																		<div className="modal-content">
																			<div className="modal-header">
																				Re-assign Appointment
																				<button type="button" className="close" data-dismiss="modal" aria-label="Close">
																					<span aria-hidden="true">&times;</span>
																				</button>
																			</div>
																			<div className="modal-body">
																				<form id="reassign-form">
																					<div className="row">
																						
																						<div className="col-sm-12">
																							<div className="form-group">
																								<label>Re-assign {appointment.patient.first_name}'s appointment to <span className="text-danger">*</span></label>
																								<Typeahead name="professional_id" labelKey={option => `ID:${option.id} ${option.first_name} ${option.last_name}`} 
																								id="basic-example" onChange={handleSelectDoctor} options={doctors} placeholder="Search Doctor's name..." />
																							</div>
																						</div>
																						<div className="col-sm-6">
																							<div className="form-group">
																								<label>Date <span className="text-danger">*</span></label>
																								<input type="date" className="form-control" id={`start_date${appointment.id}`} name={`start_date${appointment.id}`} onChange={handleChange} placeholder="YYYY-MM-DD" required pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"/>
																							</div>
																						</div>
																						<div className="col-sm-6">
																							<div className="form-group">
																								<label>Time <span className="text-danger">*</span></label>
																								<input type="time" className="form-control" id={`start_time${appointment.id}`} name={`start_time${appointment.id}`} onChange={handleChange} />
																							</div>
																						</div>
																					</div>
																				</form>

																			</div>
																			<div class="modal-footer">
																				<button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
																				<button id={`assign-button${appointment.id}`} type="button" className="btn btn-primary" onClick={() => handleAssgin(appointment.id)} disabled={!stateDoctor.professional_id}>Assign <i className="fa fa-spinner fa-pulse hide" id={`assign-loader${appointment.id}`}></i></button>
																			</div>
																		</div>
																	</div>
																</div>
															</td>
															{/* <td className="text-center">
																{appointment.institution && <span className="badge badge-primary">{appointment.institution.name}</span>}
															</td> */}
														</tr>
														
													))}
													
													</tbody>
												</table>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="text-center">
                                                        {loading &&
                                                            <div className="spinner-border" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
											</div>
											<div class="tab-pane" id="profile-1" role="tabpanel">
              									<div className="card-body">
              										<div className="table-responsive">
													  <table className="table table-responsive-sm table-hover datatable">
													<thead>
														<tr>
															<th>Date</th>
															<th>Patient</th>
															<th>Professional</th>
															<th>Bill paid</th>
															<th className="text-center">Status</th>
															<th className="text-center">completed</th>
															<th className="text-center">Facility</th>
															<th className="text-center" width="110px">Action</th>
														</tr>
													</thead>
													<tbody>
													{web_appointments.map((appointment, index)=> (
														<tr key={appointment.id}>
															<td>{formatDatetime(appointment.start_date)}</td>
															
															<td>
																{appointment.patient && <Fragment>
																	
																<Link to={`/patient_profile?id=${appointment.patient.id}`}>
																	{appointment.patient.avatar && 
																	<img width="28" height="28" src={appointment.patient.avatar} className="rounded-circle m-r-5" alt=""/> 
																	}
																	{!appointment.patient.avatar && 
																	<img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
																	}
																	{appointment.patient.first_name} {appointment.patient.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {appointment.patient.id}</span>
																</Link>
																<a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
																	</a>
																	<div className="dropdown-menu dropdown-menu-right">
																		<a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{appointment.patient.email}</a>
																		<a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{appointment.patient.phone_number}</a>
																		<a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{appointment.patient.gender}</a>
																		<a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{appointment.patient.date_of_birth}</a>
																	</div>
																</Fragment>
																}
																
															</td>
															<td>
																{appointment.professional && <Fragment>
																	
																<Link to={`/professional_profile?id=${appointment.professional.id}`}>
																	{appointment.professional.avatar && 
																	<img width="28" height="28" src={appointment.professional.avatar} className="rounded-circle m-r-5" alt=""/> 
																	}
																	{!appointment.professional.avatar && 
																	<img width="28" height="28" src="assets/images/user.jpg" className="rounded-circle m-r-5" alt=""/> 
																	}
																	{appointment.professional.first_name} {appointment.professional.last_name}&nbsp;&nbsp;<span className="badge badge-dark">ID: {appointment.professional.id}</span>
																</Link>
																<a className="dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
																	</a>
																	<div className="dropdown-menu dropdown-menu-right">
																		<a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{appointment.professional.email}</a>
																		<a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{appointment.professional.phone_number}</a>
																		<a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{appointment.professional.gender}</a>
																		<a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{appointment.professional.date_of_birth}</a>
																	</div>
																</Fragment>
																}
																
															</td>
															<td>
																{appointment.bill_paid==false &&
																<div className="dropdown action-label">
																	<a className="custom-badge status-red dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
																	Not Paid
																	</a>
																	<div className="dropdown-menu dropdown-menu-right">
																		<a className="dropdown-item" href={`mailto:${appointment.patient.email}?subject=Payment%20link%20to%20make%20payment&body=${appointment.checkout_url}`}>
																		<kbd>{appointment.checkout_url}</kbd> 
																		<button className="custom-badge status-green">Email payment link</button></a>
																		<a className="dropdown-item" onClick={() =>  navigator.clipboard.writeText(''+ `${appointment.checkout_url}` +'')}><button className="custom-badge status-green">Copy link</button></a>
																	</div>
																</div>
																}
																{appointment.bill_paid==true && <span className="badge badge-info">Paid</span>}
															</td>
															<td className="text-center">
																{appointment.payment_option=='nhis' && appointment.bill_paid==false &&
																	<div>
																		{appointments_write &&
																		
																		<span className="badge badge-warning"><i className="fa fa-clock-o"></i> Pending Approval</span>
																		}
																	</div>
																}
																{['nhis', 'card', 'mobile_money'].includes(appointment.payment_option) && appointment.bill_paid==true &&
																	<Fragment>
																		{appointment.professional &&
																			<Fragment>
																				
																				<a className="custom-badge status-red dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
																					<div className="text-center"><span className="badge badge-success"><i className="fa fa-random"></i> Assigned</span>	</div>
																				</a>
																				<div className="dropdown-menu dropdown-menu-right">
																					<a className="dropdown-item" ><i className="fa fa-drivers-license"></i>&nbsp;<span className="badge badge-dark">ID: {appointment.professional.id}</span> {appointment.professional.first_name} {appointment.professional.last_name}</a>
																					<a className="dropdown-item" ><i className="fa fa-envelope"></i>&nbsp;{appointment.professional.email}</a>
																					<a className="dropdown-item" ><i className="fa fa-phone"></i>&nbsp;{appointment.professional.phone_number}</a>
																					<a className="dropdown-item" ><i className="fa fa-user"></i>&nbsp;{appointment.professional.gender}</a>
																					<a className="dropdown-item" ><i className="fa fa-birthday-cake"></i>&nbsp;{appointment.professional.date_of_birth}</a>
																				</div>
																			</Fragment>
																		
																		}
																		{!appointment.professional &&
																			<div className="text-center"><span className="badge badge-success"><i className="fa fa-check"></i> Approved</span></div>
																		}
																	</Fragment>
																	// <span className="custom-badge status-grey"><i className="fa fa-remove"></i> Not Approved</span>
																}
																{['card', 'mobile_money'].includes(appointment.payment_option) && appointment.bill_paid==false &&
																	<div className="text-center"><span className="badge badge-danger"><i className="fa fa-warning"></i> Payment not completed</span></div>
																}
															</td>
															<td className="text-center">{appointment.is_complete && <span className="badge badge-dark">Yes</span>}
															    {!appointment.is_complete && <span className="badge badge-light">No</span>}
															
															</td>
															<td className="text-center">
																{appointment.institution && <span className="badge badge-primary">{appointment.institution.name}</span>}
															</td>
															<td className="text-center">
																<div className="dropdown dropdown-action">
																	<a href="#"  data-toggle="dropdown"
																		aria-expanded="false">
																		<i className="fa fa-ellipsis-v"></i>
																	</a>
																	<div className="dropdown-menu dropdown-menu-right">
																		{appointment.professional &&
																			<a className="dropdown-item" href="#" data-toggle="modal" data-target={`#assign_appointment${appointment.id}`} ><i className="fa fa-random"></i>&nbsp;Re-assign</a>
																		}
				
																		{appointment.is_complete &&
																			<a className="dropdown-item" href="#" data-toggle="modal" data-target={`#revert${appointment.id}`}>
																				<i className="fa fa-retweet m-r-5"></i>&nbsp;Revert Completed
																			</a>
																		}
																		
																	</div>
																</div>
															    

																<div className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id={`revert${appointment.id}`}>
																	<div className="modal-dialog modal-dialog-centered">
																		<div className="modal-content">
																			<div className="modal-header">
																				Revert Completed Appointment
																				<button type="button" className="close" data-dismiss="modal" aria-label="Close">
																					<span aria-hidden="true">&times;</span>
																				</button>
																			</div>
																			<div className="modal-body">
																				<form >
																					<div className="row">
																						
																						<div className="col-sm-12">
																							<div className="form-group">
																								<label>Are you sure you want to revert {appointment.patient.first_name}'s appointment as not completed.<span className="text-danger"></span></label>
																								
																							</div>
																						</div>
																						
																					</div>
																				</form>

																			</div>
																			<div class="modal-footer">
																				<button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
																				<button id={`revert-button${appointment.id}`} data-dismiss="modal" type="button" className="btn btn-primary" onClick={() => handleRevert(appointment.id, index)} disabled={!appointment.is_complete}>Revert <i className="fa fa-spinner fa-pulse hide" id={`revert-loader${appointment.id}`}></i></button>
																			</div>
																		</div>
																	</div>
																</div>
																
																<div className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id={`assign_appointment${appointment.id}`}>
																<div className="modal-dialog modal-dialog-centered">
																	<div className="modal-content">
																		<div className="modal-header">
																			Re-assign Appointment
																			<button type="button" className="close" data-dismiss="modal" aria-label="Close">
																				<span aria-hidden="true">&times;</span>
																			</button>
																		</div>
																		<div className="modal-body">
																			<form id="reassign-form">
																				<div className="row">
																					
																				<div className="col-sm-12">
																						<div className="form-group">
																							<label>Re-assign {appointment.patient.first_name}'s appointment to <span className="text-danger">*</span></label>
																							<Typeahead name="professional_id" labelKey={option => `ID:${option.id} ${option.first_name} ${option.last_name}`} 
																							id="basic-example" onChange={handleSelectDoctor} options={doctors} placeholder="Search Doctor's name..." />
																						</div>
																					</div>
																					<div className="col-sm-6">
																						<div className="form-group">
																							<label>Date <span className="text-danger">*</span></label>
																							<input type="date" className="form-control" id={`start_date${appointment.id}`} name={`start_date${appointment.id}`} onChange={handleChange} placeholder="YYYY-MM-DD" required pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"/>
																						</div>
																					</div>
																					<div className="col-sm-6">
																						<div className="form-group">
																							<label>Time <span className="text-danger">*</span></label>
																							<input type="time" className="form-control" id={`start_time${appointment.id}`} name={`start_time${appointment.id}`} onChange={handleChange} />
																						</div>
																					</div>
																				</div>
																			</form>

																		</div>
																		<div class="modal-footer">
																			<button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
																			<button id={`assign-button${appointment.id}`} type="button" className="btn btn-primary" onClick={() => handleAssgin(appointment.id)} disabled={!stateDoctor.professional_id}>Assign <i className="fa fa-spinner fa-pulse hide" id={`assign-loader${appointment.id}`}></i></button>
																		</div>
																	</div>
																</div>
															</div>
															</td>
														</tr>
													))}
													</tbody>
												</table>

													</div>
													<div className="row">
                                                <div className="col-sm-12">
                                                    <div className="text-center">
                                                        {web_loading &&
                                                            <div className="spinner-border" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
												</div>
											</div>
											</div>
											</div>
											</div>
											</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
  );
}
function mapStateToProps({appointments, web_appointments}){
    return {error:appointments.error, success:appointments.success, next_page:appointments.next_page, loading:appointments.loading,
		web_error:web_appointments.web_error, web_success:web_appointments.web_success, web_next_page:web_appointments.web_next_page, web_loading:web_appointments.web_loading};
    
  }
export default connect(mapStateToProps, { search_keyword, export_appointment, loadMoreAppointments, search_web_keyword, export_web_appointment, loadMoreWebAppointments, assign_appointment, assign_web_appointment, revert_completed, revert_web_completed})(AppointmentsComponent);
