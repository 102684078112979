import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const AuthRoute = ({
  roles,
  logout,
  component: Component,
  updateNotification,
  notifications,
  user,
  history,
  ...rest
}) => {
  let roleExists = true;

  return (
    <Route
      {...rest}
      render={props =>
        roleExists ? (
          <div style={{ marginLeft: '3%', marginRight: '3%' }}>
            <Component {...props} />
          </div>
        ) : (
          <p>Sorry you are not authorized to be here</p>
        )
      }
    />
  );
};

AuthRoute.propTypes = {
  history: PropTypes.object,
  component: PropTypes.any,
  user: PropTypes.object,
  //roles: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired
};

export default AuthRoute;
