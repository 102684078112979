import { connect } from 'react-redux';
import { fetchCallLogs, fetchWebCallLogs} from './duck/actions';
import CallLogsComponent from './CallLogsPage';

const mapStateToProps = ({ callLogs: { callLogs }, web_callLogs: { web_callLogs } }) => {
  return {
    callLogs,web_callLogs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCallLogs: () => dispatch(fetchCallLogs()),
    fetchWebCallLogs: () => dispatch(fetchWebCallLogs()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallLogsComponent);
