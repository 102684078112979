import axios from 'axios';
import Literals from '../../helpers/Literals'
import { authenticateRequest } from '../../helpers/utils';


export const fetchSubscriptions = () => async dispatch => {
  dispatch({ type: 'FETCH_SUBSCRIPTIONS' });
  try {
    const { data } = await axios.get(`${Literals.BASE_API}web/backend/subscription_packages/?page=1&page_size=100`);

    dispatch({ type: 'FETCH_SUBSCRIPTIONS_SUCCESS', payload: data.results, next_page:data.next, count:data.count});
  } catch (e) {
    authenticateRequest(e)
    dispatch({ type: 'FETCH_SUBSCRIPTIONS_FAILURE', payload: e.response?e.response.data.details:Literals.ERROR_OCCURED, });
  }
};
